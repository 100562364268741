import React from "react";
import {
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "@/components/Accordion";
import { ContentBlockShape } from "@/models";
import { cn } from "@/util";
import RichTextBlock from "../../rich-text/RichTextBlock";
type Props = {
    contentBlock: ContentBlockShape;
    isInAdminContext: boolean;
    children: React.ReactNode | React.ReactNode[];
};

export default function AccordionItemBlock({
    contentBlock,
    children,
    isInAdminContext,
}: Props) {

    return (
        <AccordionItem
            value={contentBlock.id}
            className={`px-2 ${cn(
                (contentBlock.theme?.tailwindClasses || []).filter(
                    (className) =>
                        className.includes("p-") ||
                        className.includes("px-") ||
                        className.includes("py-"),
                ),
            )}`}
        >
            <AccordionTrigger className="w-full">
                <RichTextBlock
                    content={
                        contentBlock.rich_text || {
                            type: "doc",
                            content: [
                                {
                                    type: "paragraph",
                                    content: [
                                        {
                                            type: "text",
                                            text: contentBlock.content,
                                        },
                                    ],
                                },
                            ],
                        }
                    }
                    editable={isInAdminContext}
                    contentBlock={contentBlock}
                />
            </AccordionTrigger>
            <AccordionContent
                className={`pb-2 ${cn(contentBlock.theme?.tailwindClasses)}`}
                style={{ borderColor: contentBlock.theme?.backgroundColor }}
                data-parent-content-block-id={
                    contentBlock.parent_content_block_id
                }
                data-content-block-id={contentBlock.id}
            >
                {children}
            </AccordionContent>
        </AccordionItem>
    );
}
