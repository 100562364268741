import { useGetAllElementsAsync } from "@/hooks/useGetElementAsync";
import React, { useCallback } from "react";
import { createPortal } from "react-dom";
import { useSaveContentBlock } from "../../content-block-management/useSaveContentBlock";
import {
    useContentBlockState,
    useGetContentBlockWithChildren,
} from "../../useContentBlockState";
import ImageModal from "../modals/ImageModal";
import { ContentBlockShape } from "@/models";

export const OptionCardImageHandler = ({
    contentBlock,
}: {
    contentBlock: ContentBlockShape;
    update: (contentBlock: ContentBlockShape) => any;
}) => {
    const { setContentBlock } = useContentBlockState();

    const { mutateAsync, isPending } = useSaveContentBlock();

    const handleSave = useCallback(
        async (image_url: string) => {
            const { savedContentBlock } = await mutateAsync({
                ...contentBlock,
                image_url,
            });
            setContentBlock(savedContentBlock);
        },
        [contentBlock],
    );

    return (
        <div
            className="flex items-center justify-center gap-2 rounded-md bg-blue-700 px-4 py-2
                text-white transition-all duration-300 hover:bg-blue-800"
        >
            <ImageModal imageUrl={""} save={handleSave} saving={isPending}>
                <span>Set image for card</span>
            </ImageModal>
        </div>
    );
};
