import { useCallback, useEffect, useMemo } from "react";
import { atom, useAtom } from "jotai";
import { Course, Lesson } from "../../types";
import { debounce } from "@/hooks";
import { useScorm } from "@/hooks/scorm-export/useScormApi";

const viewedContentBlocksAtom = atom<{
    [index: string]: { [index: string]: boolean };
}>({});

export function useSetupViewedContentBlockState(
    lessons: Lesson[],
    viewedContentBlocks?: {
        content_block_id: string;
        round_id?: string;
    }[],
) {
    const [_, setViewedContentByLesson] = useAtom(viewedContentBlocksAtom);

    useEffect(() => {
        if (viewedContentBlocks) {
            setViewedContentByLesson((prev) => {
                return lessons.reduce((carry, lesson) => {
                    return {
                        ...carry,
                        [lesson.id]: viewedContentBlocks
                            .filter(
                                (viewedContentBlock) =>
                                    viewedContentBlock.round_id === lesson.id,
                            )
                            .reduce((carry, viewedContentBlock) => {
                                return {
                                    ...carry,
                                    [viewedContentBlock.content_block_id]: true,
                                };
                            }, prev[lesson.id] || {}),
                    };
                }, {});
            });
        }
    }, []);
}

export function useViewedContentBlockState(
    lesson: Lesson,
    callback?: (contentBlockIds: string[]) => void,
) {
    const debouncedCallback = debounce(callback, 1000);

    const [viewedContentBlocksByLesson, setViewedContentByLesson] = useAtom(
        viewedContentBlocksAtom,
    );

    const viewContentBlock = useCallback(
        (contentBlockId: string) => {
            setViewedContentByLesson((prev) => {
                return {
                    ...prev,
                    [lesson.id]: {
                        ...prev[lesson.id],
                        [contentBlockId]: true,
                    },
                };
            });
        },
        [viewedContentBlocksByLesson, lesson],
    );

    const viewedContentBlockPercentage = useMemo(() => {
        const totalContentBlocks = lesson.parent_content_blocks_count || 1;
        const viewedContentBlocks = Object.keys(
            viewedContentBlocksByLesson[lesson.id] || {},
        );
        if ("function" === typeof callback) {
            debouncedCallback(viewedContentBlocks);
        }
        return Math.round(
            (viewedContentBlocks.length / totalContentBlocks) * 100,
        );
    }, [lesson, viewedContentBlocksByLesson]);

    return {
        viewContentBlock,
        viewedContentBlockPercentage,
    };
}

export function useCourseViewedContentBlockPercentage(course: Course, lessonId:string) {
    const [viewedContentBlocksByLesson] = useAtom(viewedContentBlocksAtom);
    const { scorm } = useScorm();

    const completedLessons = useMemo(() => {
        return course.lessons.filter((lesson) => {
            const totalContentBlocks = lesson.parent_content_blocks_count || 1;
            const viewedContentBlocks = Object.keys(
                viewedContentBlocksByLesson[lesson.id] || {},
            );
            return viewedContentBlocks.length >= totalContentBlocks;
        });
     }, [viewedContentBlocksByLesson, course]);



    const percentage = useMemo(() => {
        return Math.round((completedLessons.length / course.lessons.length) * 100);
    }, [completedLessons.length, course]);

    useEffect(() => {
        scorm.setStatus(percentage === 100 ? "completed" : "incomplete");
    },[completedLessons.length, lessonId])

    return { percentage, completedCount:completedLessons.length };
}
