import { ContentBlockShape } from "@/models";
import { cn } from "@/util";
import React, { useState } from "react";
import LessonRichText from "../rich-text/RichTextBlock";

type Props = { contentBlock: ContentBlockShape; isInAdminContext: boolean; isPending?:boolean };

export default function ButtonBlock({
    contentBlock,
    isInAdminContext,
    isPending = false
}: Props) {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <button
            type={isInAdminContext ? "button" : "submit"}
            data-content-block-id={contentBlock.id}
            disabled={isPending}
            className={cn(
                `flex w-fit items-center justify-center rounded-md bg-sapien-blue px-4 py-2
                text-white transition hover:bg-white hover:text-sapien-blue ${isPending ? "animate-pulse" : ""}
                ${cn(contentBlock.theme.tailwindClasses || [])}`,
            )}
            style={{
               
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <LessonRichText
                contentBlock={contentBlock}
                editable={isInAdminContext}
                content={contentBlock.rich_text}
            />

        </button>
    );
}
