import React, { useCallback, useMemo, useState } from "react";
import {
    ModelVariable,
    ModelVariableType,
    VariableRelationshipOperation,
} from "@/models";
import { useModelVariableMap, useRelationshipsByTargetId } from "../atoms";
import {
    displayModelVariableAggregateOperation,
    displayVariableRelationshipOperation,
} from "../utils";

const VariableTreeDisplayComponent = ({
    modelVariable,
    variableRelationshipOperation,
    isCollapsible = true,
    degree = 0,
}: {
    modelVariable: ModelVariable;
    variableRelationshipOperation?: string;
    isCollapsible?: boolean;
    degree?: number;
}) => {
    const modelVariables = useModelVariableMap();
    const relationshipsByTargetId = useRelationshipsByTargetId();

    const [isExpanded, setIsExpanded] = useState(degree !== 2);

    const childRelationships = useMemo(() => {
        return relationshipsByTargetId?.[modelVariable.id] || [];
    }, [relationshipsByTargetId, modelVariable.id]);

    const handleToggleExpand = useCallback(() => {
        if (isCollapsible) setIsExpanded((prev) => !prev);
    }, [isCollapsible]);

    return (
        <div className="flex flex-col">
            {/* Tree Node */}
            <div
                className="flex w-full items-center justify-between"
                style={{
                    background:
                        isExpanded || !isCollapsible
                            ? "rgba(28,78,216,0.05)"
                            : "rgba(28,78,216,0.1)",
                    cursor: isCollapsible ? "pointer" : "default",
                    border: "1px solid rgba(28,78,216,0.25)",
                }}
                onClick={handleToggleExpand}
            >
                <div
                    className="flex w-full items-stretch"
                    style={{
                        borderRight: "1px solid rgba(28,78,216,0.25)",
                    }}
                >
                    {variableRelationshipOperation !== undefined && (
                        <div
                            className="flex min-w-[34px] items-center justify-center py-2 text-sm text-blue-900"
                            style={{
                                borderRight: "1px solid rgba(28,78,216,0.25)",
                            }}
                        >
                            {variableRelationshipOperation}
                        </div>
                    )}
                    <div className="flex items-center p-2">
                        {modelVariable.label}
                    </div>
                </div>
                <div
                    className={`whitespace-nowrap p-2 ${
                        modelVariable.variable_type ===
                        ModelVariableType["Selection Data"]
                            ? "font-bold"
                            : ""
                    }`}
                >
                    {modelVariable.variable_type ===
                    ModelVariableType["Selection Data"]
                        ? "SELECTION DATA"
                        : modelVariable.variable_type}
                </div>
            </div>

            {/* Children */}
            {isExpanded && childRelationships.length > 0 && (
                <div className="ml-8 border-l-2 border-dotted border-l-[rgba(28,78,216,0.2)]">
                    {childRelationships.map((sourceRelationship, i) => {
                        const childVariable =
                            modelVariables[
                                sourceRelationship.source_variable_id
                            ];
                        return childVariable ? (
                            <VariableTreeDisplay
                                key={sourceRelationship.id}
                                modelVariable={childVariable}
                                variableRelationshipOperation={
                                    displayVariableRelationshipOperation(
                                        sourceRelationship.operation_type as VariableRelationshipOperation,
                                    ) ||
                                    (modelVariable.variable_type ===
                                        ModelVariableType.Conditional &&
                                        ["if", "then"][i % 2]) ||
                                    (modelVariable.variable_type ===
                                        ModelVariableType.Aggregate &&
                                        displayModelVariableAggregateOperation(
                                            modelVariable.aggregate_operation,
                                        )) ||
                                    ""
                                }
                                isCollapsible={Boolean(
                                    relationshipsByTargetId?.[childVariable.id],
                                )}
                                degree={degree + 1}
                            />
                        ) : null;
                    })}
                </div>
            )}
        </div>
    );
};

export const VariableTreeDisplay = React.memo(VariableTreeDisplayComponent);
