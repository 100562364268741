import React, { useCallback } from "react";
import { ContentBlockShape, ContentBlockType } from "@/models";
import ColorSelector from "@/components/rich-text/ColorSelector";
import { useSaveContentBlock } from "../../content-block-management/useSaveContentBlock";
import { useContentBlockState } from "../../useContentBlockState";
import { debounce } from "@/hooks";
import ButtonGroup from "../ButtonGroup";

export function FormSpacingStyling({
    contentBlock,
}: {
    contentBlock: ContentBlockShape;
}) {
    const form = contentBlock.contentBlocks?.find(
        (child) => child.content_block_type === ContentBlockType.Form,
    );

    const { mutate } = useSaveContentBlock();
    const { setContentBlock } = useContentBlockState();

    const save = useCallback(debounce(mutate, 2000), [contentBlock]);
    const update = useCallback(
        debounce((updatedBlock: ContentBlockShape) => {
            console.log("deb");
            setContentBlock(updatedBlock);
            updatedBlock?.contentBlocks?.forEach((child) => {
                setContentBlock(child);
            });
            save(updatedBlock);
        }, 100),
        [contentBlock],
    );

    if (!form) return null;
    return (
        <div className="mt-4 flex flex-col gap-4">
            <div className="flex flex-col gap-2 shadow-sm">
                <label htmlFor="accordionSpacing" className="text-xs">
                    Form Spacing
                </label>
                <ButtonGroup
                    selectedValue={(form.theme?.tailwindClasses as string[])
                        ?.filter((className) => className.includes("gap-"))
                        .join(" ")}
                    values={[
                        { label: "S", value: "gap-2" },
                        { label: "M", value: "gap-4" },
                        { label: "L", value: "gap-8" },
                    ]}
                    handleChange={(value) => {
                        update({
                            ...form,
                            theme: {
                                ...form.theme,
                                tailwindClasses: [
                                    ...(
                                        form.theme?.tailwindClasses || []
                                    ).filter(
                                        (className) =>
                                            !className.includes("gap-"),
                                    ),
                                    value,
                                ],
                            },
                        });
                    }}
                />
            </div>
        </div>
    );
}

export function FormColorStyling({
    contentBlock,
}: {
    contentBlock: ContentBlockShape;
}) {
    const form = contentBlock.contentBlocks?.find(
        (child) => child.content_block_type === ContentBlockType.Form,
    );

    const { mutate } = useSaveContentBlock();
    const { setContentBlock } = useContentBlockState();

    const save = useCallback(debounce(mutate, 2000), [contentBlock]);
    const update = useCallback(
        debounce((updatedBlock: ContentBlockShape) => {
            setContentBlock(updatedBlock);
            updatedBlock?.contentBlocks?.forEach((child) => {
                setContentBlock(child);
            });
            save(updatedBlock);
        }, 100),
        [contentBlock],
    );

    if (!form) return null;

    return (
        <div className="mt-4 flex flex-col gap-4">
            <div className="flex flex-col gap-2 shadow-sm">
                <label htmlFor="accordionSpacing" className="text-xs">
                    Form Background Color
                </label>
                <ColorSelector
                    selectedColor={form?.theme?.backgroundColor || "white"}
                    selectColor={(backgroundColor, color) => {
                        console.log({
                            ...form,
                            theme: {
                                ...form.theme,
                                backgroundColor,
                                backgroundColorId: color.id,
                            },
                        });
                        update({
                            ...form,
                            theme: {
                                ...form.theme,
                                backgroundColor,
                                backgroundColorId: color.id,
                            },
                        });
                    }}
                />
            </div>
        </div>
    );
}
