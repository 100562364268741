import React, { useState, useEffect } from "react";
import { useForm } from "@inertiajs/react";
import { sapienAxios, SapienInertia } from "@/inertia-utils/hooks";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { ModalContainer } from "@/modules/shared";
import { useQuery } from "@tanstack/react-query";
import { SimulationShape, TeamShape } from "@/models";
import { Course } from "../../Pages/ELearning/types";
import { Accordion, AccordionItem } from "@/components/Accordion";
import SimSelector from "../../Pages/UserManagement/SimSelector";
import CourseSelector from "../../Pages/UserManagement/CourseSelector";
import { useRoles } from "./useRoles";

type Props = {
    role?: { id?: number; name: string };
    children?: React.ReactNode;
    team?: TeamShape;
};

export const UserInvitationForm = ({ role, children, team }: Props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const { roles, isPending: isRolesPending } = useRoles();
    const team_ids = !!team ? [team.id] : [];

    const { data, setData } = useForm({
        email: "",
        role_id: "-1",
        name: "",
        team_ids,
        cohort_ids: [],
        simulation_ids: [],
        course_ids: [],
    });

    useEffect(() => {
        if (role && !isRolesPending && !!roles.length) {
            setData(
                "role_id",
                roles
                    .find((loopedRole) => loopedRole.name === role.name)
                    ?.id.toString(),
            );
        }
    }, [role, isRolesPending, roles.length]);

    const { data: simsAndCourses, isPending } = useQuery({
        queryKey: ["teams-for-invitation", role?.id],
        queryFn: async () => {
            const { data } = await sapienAxios.get<{
                simulations: SimulationShape[];
                courses: Course[];
            }>("facilitation.teams-for-invitation");
            return data;
        },
    });

    const role_id =
        !!role && !!roles?.length
            ? roles.find((role) => role.name === role.name)?.id.toString()
            : "-1";

    const selectedRole = roles?.find(
        (role) => role.id.toString() === data.role_id,
    );

    return (
        <>
            <button
                className="inline-flex items-center gap-2 rounded-md bg-indigo-600 px-4 py-2 text-sm
                    font-semibold text-white shadow-sm hover:bg-indigo-700 focus-visible:outline
                    focus-visible:outline-2 focus-visible:outline-offset-2
                    focus-visible:outline-indigo-600"
                onClick={() => setIsModalOpen(true)}
            >
                <EnvelopeIcon className="h-6 w-6" />
                Invite User to Register
            </button>
            <ModalContainer
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                size="l"
            >
                <div className="rounded-md border border-slate-100/50 bg-white/75 p-3">
                    <div className="flex flex-col gap-4 ">
                        <div className="flex min-w-60 items-center space-x-3">
                            <EnvelopeIcon className="h-6 w-6" />
                            <h2 className="text-nowrap text-base font-semibold leading-6 text-slate-900">
                                Invite user to register on {team?.name}
                            </h2>
                        </div>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                SapienInertia.post(
                                    "admin.invitation.store",
                                    data,
                                    {},
                                    {
                                        onSuccess: () => {
                                            setData({
                                                email: "",
                                                role_id: !!team
                                                    ? roles
                                                          .find(
                                                              (role) =>
                                                                  role.name.toLocaleLowerCase() ===
                                                                  "participant",
                                                          )
                                                          ?.id.toString() ||
                                                      "-1"
                                                    : "-1",
                                                name: "",
                                                team_ids: !!team
                                                    ? [team.id]
                                                    : [],
                                                cohort_ids: [],
                                                simulation_ids: [],
                                                course_ids: [],
                                            });
                                            setIsModalOpen(false);
                                        },
                                    },
                                );
                            }}
                            className="flex w-full flex-col gap-4"
                        >
                            <div>
                                <label htmlFor="user-name" className="sr-only">
                                    Name
                                </label>
                                <input
                                    type="text"
                                    name="user-name"
                                    id="user-name"
                                    className="block w-full rounded-md border-0 px-2 py-1.5 text-sm leading-6 text-slate-900
                                        ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2
                                        focus:ring-inset focus:ring-indigo-600"
                                    placeholder={`Name`}
                                    value={data.name}
                                    onChange={(e) => {
                                        setData("name", e.target.value);
                                    }}
                                />
                                <label className="text-xs text-slate-500">
                                    (Optional)
                                </label>
                            </div>
                            <div>
                                <label htmlFor="user-email" className="sr-only">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    name="user-email"
                                    id="user-email"
                                    className="block w-full rounded-md border-0 px-2 py-1.5 text-sm leading-6 text-slate-900
                                        ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2
                                        focus:ring-inset focus:ring-indigo-600"
                                    placeholder={`Email`}
                                    value={data.email}
                                    onChange={(e) => {
                                        setData("email", e.target.value);
                                    }}
                                />
                            </div>
                            <div>
                                <label htmlFor="user-role" className="sr-only">
                                    Role
                                </label>
                                <select
                                    id="user-role"
                                    name="user-role"
                                    className="block w-full rounded-md border-0 px-2 py-1.5 text-sm leading-6 text-slate-700
                                        ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2
                                        focus:ring-inset focus:ring-indigo-600"
                                    disabled={!!role}
                                    value={
                                        role_id !== "-1"
                                            ? role_id
                                            : data.role_id
                                    }
                                    onChange={(e) => {
                                        setData("role_id", e.target.value);
                                    }}
                                >
                                    {!!role ? (
                                        <option key={role.name} value={role.id}>
                                            {role.name}
                                        </option>
                                    ) : (
                                        <>
                                            <option>Role</option>
                                            {roles.map((role) => (
                                                <option
                                                    key={role.name}
                                                    value={role.id}
                                                >
                                                    {role.name}
                                                </option>
                                            ))}
                                        </>
                                    )}
                                </select>
                            </div>
                            {!team && selectedRole?.name === "Participant" && (
                                <>
                                    <div>
                                        <h3>Enroll in Simulations</h3>

                                        <Accordion
                                            type={"single"}
                                            collapsible
                                            className={
                                                "overflow-hidden rounded-md border"
                                            }
                                        >
                                            {simsAndCourses?.simulations.map(
                                                (sim) => (
                                                    <AccordionItem
                                                        key={sim.id}
                                                        title={sim.title}
                                                        value={sim.id}
                                                    >
                                                        <SimSelector
                                                            sim={sim}
                                                            teamIds={
                                                                data.team_ids
                                                            }
                                                            setTeamIds={(
                                                                teamIds,
                                                            ) => {
                                                                setData(
                                                                    "team_ids",
                                                                    teamIds,
                                                                );
                                                            }}
                                                            setCohortIds={(
                                                                cohortIds,
                                                            ) =>
                                                                setData(
                                                                    "cohort_ids",
                                                                    cohortIds,
                                                                )
                                                            }
                                                            cohortIds={
                                                                data.cohort_ids
                                                            }
                                                            setSimulationIds={(
                                                                simulationIds,
                                                            ) =>
                                                                setData(
                                                                    "simulation_ids",
                                                                    simulationIds,
                                                                )
                                                            }
                                                            simulationIds={
                                                                data.simulation_ids
                                                            }
                                                        />
                                                    </AccordionItem>
                                                ),
                                            )}
                                        </Accordion>
                                    </div>
                                    <div>
                                        <h3>Enroll in Courses</h3>

                                        <Accordion
                                            type={"single"}
                                            collapsible
                                            className={
                                                "overflow-hidden rounded-md border"
                                            }
                                        >
                                            {simsAndCourses?.courses.map(
                                                (course) => (
                                                    <AccordionItem
                                                        key={course.id}
                                                        title={course.title}
                                                        value={course.id}
                                                    >
                                                        <CourseSelector
                                                            course={course}
                                                            setCohortIds={(
                                                                cohortIds,
                                                            ) =>
                                                                setData(
                                                                    "cohort_ids",
                                                                    cohortIds,
                                                                )
                                                            }
                                                            cohortIds={
                                                                data.cohort_ids
                                                            }
                                                            setCourseIds={(
                                                                course_ids,
                                                            ) =>
                                                                setData(
                                                                    "course_ids",
                                                                    course_ids,
                                                                )
                                                            }
                                                            courseIds={
                                                                data.course_ids
                                                            }
                                                        />
                                                    </AccordionItem>
                                                ),
                                            )}
                                        </Accordion>
                                    </div>
                                </>
                            )}
                            <div className="flex justify-end gap-2 border-t border-slate-100/50 pt-4">
                                <button
                                    className="rounded-md bg-red-600 px-4 py-2 text-sm font-semibold text-white shadow-sm
                                        hover:bg-red-700 focus-visible:outline focus-visible:outline-2
                                        focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setData({
                                            email: "",
                                            role_id: "-1",
                                            name: "",
                                            team_ids: [],
                                            cohort_ids: [],
                                            simulation_ids: [],
                                            course_ids: [],
                                        });
                                        setIsModalOpen(false);
                                    }}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm
                                        data-[is-loading=true]:animate-pulse hover:bg-indigo-700 focus-visible:outline
                                        focus-visible:outline-2 focus-visible:outline-offset-2
                                        focus-visible:outline-indigo-600"
                                    data-is-loading={isPending}
                                >
                                    <span>Send Invitation</span>
                                </button>
                            </div>
                        </form>
                    </div>
                    {!!children && <div>{children}</div>}
                </div>
            </ModalContainer>
        </>
    );
};
