import React, { useCallback, useEffect } from "react";
import { ModalContainer } from "@/modules/shared/";
import {
    useFindContentBlockMethods,
    useSelectedRoundId,
    useSelectedSimulation,
} from "@/hooks";
import { useForm } from "@inertiajs/react";
import { useModalQueryParams } from "@/hooks/useModalQueryParams/useModalQueryParams";
import { SapienInertia, sapienRoute } from "@/inertia-utils/hooks";
import { ModelDataSelectionForm } from "@/components/admin-components/model-variable-selection/ModelDataSelectionForm";
import { useModelVariableStore, useTimeHorizonStore } from "@/hooks/store";
import { ContentBlockShape, ModelVariableDataType } from "@/models";
import { useQuery } from "@tanstack/react-query";
import { useGetContentBlockById } from "../../modals/shared-queries/useGetContentBlockById";

function DynamicModalForm({
    contentBlock,
    closeModal,
}: {
    contentBlock: ContentBlockShape;
    closeModal: () => void;
}) {

    const { data: activeContentBlock } = useGetContentBlockById(contentBlock.id);

    const { timeHorizons } = useTimeHorizonStore();
    const { modelVariables } = useModelVariableStore();

    const { data, setData } = useForm({
        content_block_id: contentBlock.id,
        model_variable_id: contentBlock?.displayRule?.model_variable_id || "",
        time_horizon_id: contentBlock?.displayRule?.time_horizon_id || "",
        id: contentBlock?.displayRule?.id || null,
    });

    useEffect(() => {
        if (activeContentBlock) {
            setData({
                content_block_id: activeContentBlock.id,
                model_variable_id: activeContentBlock.displayRule?.model_variable_id || "",
                time_horizon_id: activeContentBlock.displayRule?.time_horizon_id || "",
                id: activeContentBlock.displayRule?.id || null,
            });
        }
    }, [activeContentBlock]);

    const update = useCallback(
        (key: keyof typeof data, value: string) => {
            setData((oldData) => ({ ...oldData, [key]: value }));
        },
        [data, setData]
    );

    const { selectedRoundId } = useSelectedRoundId();
    const { selectedSimulation } = useSelectedSimulation();

    const submit = useCallback(() => {
        if (contentBlock?.displayRule) {
            setData(() => ({ ...data, id: contentBlock.displayRule.id }));
            SapienInertia.put("creator.design.display-rules.update", data, {
                simulationSlug: selectedSimulation.slug,
                roundId: selectedRoundId,
                displayRuleId: contentBlock.displayRule.id,
            });
        } else {
            SapienInertia.post("creator.design.display-rules.store", data, {
                simulationSlug: selectedSimulation.slug,
                roundId: selectedRoundId,
            });
        }
    }, [contentBlock, data, selectedSimulation, selectedRoundId]);

    const deleteDisplayRule = (displayRuleId: string) => {
        SapienInertia.delete(
            sapienRoute("creator.design.display-rules.destroy", {
                id: displayRuleId,
            }),
            {
                onError(e) {
                    console.log("ERROR IS", e);
                },
            }
        );
    };

    return (
        <form
            data-testid="dynamic-content-modal-form"
            className="flex flex-col py-4 px-6 space-y-4 text-white"
            onSubmit={(e) => {
                e.preventDefault();
                submit();
            }}
        >
            <p className="text-xl">Select Model Variable</p>
            <div className="flex flex-col border-b border-t border-gray-300 w-full">
                <ModelDataSelectionForm
                    modelVariables={Object.values(modelVariables).filter(
                        (modelVariable) =>
                            modelVariable.data_type ===
                            ModelVariableDataType.Boolean
                    )}
                    timeHorizons={timeHorizons}
                    selectedModelVariables={Object.values(
                        modelVariables
                    ).filter(
                        (variable) => variable.id === data?.model_variable_id
                    )}
                    setSelectedModelVariables={(newModelVariables) => {
                        if (newModelVariables?.length) {
                            update(
                                "model_variable_id",
                                newModelVariables[0].id
                            );
                        } else {
                            update("model_variable_id", null);
                        }
                    }}
                    selectedTimeHorizons={Object.values(timeHorizons).filter(
                        (timeHorizon) =>
                            timeHorizon.id === data?.time_horizon_id
                    )}
                    setSelectedTimeHorizons={(newTimeHorizons) => {
                        if (newTimeHorizons?.length) {
                            update("time_horizon_id", newTimeHorizons[0].id);
                        } else {
                            update("time_horizon_id", null);
                        }
                    }}
                    isSingleModelVariableSelection={true}
                    isSingleTimeHorizonSelection={true}
                />
            </div>
            <div className="flex justify-between">
                <div>
                    {!!contentBlock?.displayRule?.id && (
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                deleteDisplayRule(contentBlock.displayRule.id);
                            }}
                            className="inline-flex items-center rounded-md border border-red-500 bg-white px-4 py-2 text-sm font-medium text-red-600 shadow-sm hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                        >
                            Remove display rule
                        </button>
                    )}
                </div>
                <div className="flex justify-end space-x-2">
                    <button className="inline-flex items-center  bg-blue-600 px-4 py-2 rounded text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none 2bluefocus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                        Save
                    </button>
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            closeModal();
                        }}
                        className="inline-flex items-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </form>
    );
}

export default function DynamicContentModal() {
    const {
        isAtModalUrl: isOpen,
        closeModal,
        queryParams,
    } = useModalQueryParams<"DynamicContentModal">("DynamicContentModal");

    const { selectedSimulation } = useSelectedSimulation();
    const { getContentBlockWithChildren } = useFindContentBlockMethods();
    const contentBlock = getContentBlockWithChildren(
        queryParams?.contentBlockId
    );
    if (!selectedSimulation || !contentBlock) return null;

    return (
        <ModalContainer
            isModalOpen={isOpen}
            setIsModalOpen={() => {
                closeModal();
            }}
            size="l"
            backgroundColor="#111928"
        >
            {isOpen && (
                <DynamicModalForm
                    contentBlock={contentBlock}
                    closeModal={closeModal}
                />
            )}
        </ModalContainer>
    );
}
