import {
    ContentBlockShape,
    OptionShape,
    PromptShape,
    PromptType,
    RichText,
} from "@/models";
import React, { ChangeEvent, useState } from "react";
import LessonRichText from "../rich-text/RichTextBlock";
import { PlusIcon, Settings2Icon } from "lucide-react";

import { SliderMinAndMax } from "../../Design/content-block-editing/questions/QuestionForms";
import { Submission, useSelections } from "../../shared-state/useSelections";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@radix-ui/react-popover";
import Input from "@/components/Input";
import { debounce } from "@/hooks";
import {
    useSaveOption,
    useSaveOptionScoring,
} from "../../Design/content-block-management/useSaveOption";
import { cn } from "@/util";
import { QuestionTheme } from "../../Design/content-block-editing/questions/QuestionStyle";
import { useGetContentBlockWithChildren } from "../../Design/useContentBlockState";
import { themeToStyleVariables } from "@/tailwind-colors/themePropertyToStyleVariable";

type Props = {
    editable: boolean;
    content: RichText;
    contentBlock: ContentBlockShape;
};

type OptionProps = Props & {
    selections: Submission[];
};

function OptionScoring({
    option,
    promptType,
    variant = "default",
}: {
    option: OptionShape;
    promptType: PromptType;
    variant?: QuestionTheme;
}) {
    const { mutateAsync } = useSaveOptionScoring();

    const save = debounce(mutateAsync, 500);
    return (
        <Popover>
            <PopoverTrigger>
                <button
                    data-variant={variant}
                    className="rounded-md bg-slate-200 p-2 data-[variant=cards]:absolute
                        data-[variant=cards]:right-3 data-[variant=cards]:top-3 hover:bg-white
                        hover:shadow"
                >
                    <Settings2Icon className="h-4 w-4" />
                </button>
            </PopoverTrigger>
            <PopoverContent
                className="rounded-md border bg-white p-4 shadow"
                align="center"
                side="left"
                sideOffset={10}
            >
                <div className="flex flex-col space-y-2">
                    <div className="flex items-center gap-x-3">
                        <Input
                            handleChange={(
                                e: ChangeEvent<HTMLInputElement>,
                            ) => {
                                save({
                                    ...option,
                                    selected_numerical_value: parseInt(
                                        e.target.value,
                                    ),
                                });
                            }}
                            name={option.id}
                            type="number"
                            className="w-20 px-2"
                            isFocused={false}
                            defaultValue={option.selected_numerical_value}
                        />
                        <label
                            htmlFor={option.id}
                            className="block text-sm font-medium leading-6 text-gray-900"
                        >
                            Score if selected
                        </label>
                    </div>
                    {promptType === PromptType["Multiple Select"] && (
                        <div className="flex items-center gap-x-3">
                            <Input
                                handleChange={(
                                    e: ChangeEvent<HTMLInputElement>,
                                ) => {
                                    save({
                                        ...option,
                                        unselected_numerical_value: parseInt(
                                            e.target.value,
                                        ),
                                    });
                                }}
                                name={option.id}
                                type="number"
                                className="w-20 px-2"
                                defaultValue={option.unselected_numerical_value}
                                isFocused={false}
                            />
                            <label
                                htmlFor={option.id}
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                Score if not selected
                            </label>
                        </div>
                    )}
                </div>
            </PopoverContent>
        </Popover>
    );
}

function MultipleChoice({ contentBlock, editable, selections }: OptionProps) {
    const getClasses = (contentBlock: ContentBlockShape) => {
        return contentBlock.theme?.tailwindClasses?.length &&
            contentBlock.theme?.variant !== "default"
            ? cn([
                  ...contentBlock.theme?.tailwindClasses,
                  "relative",
                  "group/OPTION",
              ])
            : "flex items-center justify-between py-2 group/OPTION";
    };

    return (
        <>
            {contentBlock.prompt.options
                .toSorted((a, b) => a.weight - b.weight)
                .map((option, index) => (
                    <OptionWithContentBlock
                        contentBlockId={option.contentBlock.id}
                        key={option.id}
                    >
                        {(optionBlock) => {
                            if (!optionBlock) return null;
                            return (
                                <label
                                    style={themeToStyleVariables(
                                        optionBlock.theme,
                                    )}
                                    className={getClasses(optionBlock)}
                                    key={option.id}
                                    data-selected={
                                        !!selections?.find(
                                            (selection) =>
                                                selection.option_id ===
                                                    option.id &&
                                                selection.is_selected,
                                        )
                                    }
                                    htmlFor={editable ? "" : option.id}
                                    data-parent-content-block-id={
                                        contentBlock.id
                                    }
                                    data-content-block-id={optionBlock.id}
                                >
                                    <div
                                        className="flex w-full items-center gap-x-3 data-[is-cards=true]:flex-col"
                                        data-is-cards={
                                            contentBlock.theme?.variant ===
                                            "cards"
                                        }
                                    >
                                        <input
                                            id={option.id}
                                            name={
                                                contentBlock.prompt
                                                    .prompt_type ===
                                                PromptType["Multiple Choice"]
                                                    ? contentBlock.prompt.id
                                                    : option.id
                                            }
                                            value={option.id}
                                            defaultChecked={
                                                !!selections?.find(
                                                    (selection) =>
                                                        selection.option_id ===
                                                            option.id &&
                                                        selection.is_selected,
                                                )
                                            }
                                            data-variant={
                                                contentBlock.theme?.variant
                                            }
                                            type={
                                                contentBlock.prompt
                                                    .prompt_type ===
                                                PromptType["Multiple Choice"]
                                                    ? "radio"
                                                    : "checkbox"
                                            }
                                            className="h-4 w-4 border-gray-300 text-indigo-600 data-[variant=cards]:hidden
                                                data-[variant=cards]:appearance-none focus:ring-indigo-600"
                                        />
                                        <LessonRichText
                                            editable={editable}
                                            content={optionBlock.rich_text}
                                            contentBlock={optionBlock}
                                        />
                                    </div>
                                    <div className="flex items-center">
                                        {editable && (
                                            <OptionScoring
                                                option={option}
                                                promptType={
                                                    PromptType[
                                                        "Multiple Choice"
                                                    ]
                                                }
                                                variant={
                                                    contentBlock.theme?.variant
                                                }
                                            />
                                        )}
                                    </div>
                                </label>
                            );
                        }}
                    </OptionWithContentBlock>
                ))}
        </>
    );
}

// function Text({ contentBlock, editable, selections }: OptionProps) {
//     <div className="flex flex-col space-y-2"></div>;
// }

function Slider({ contentBlock, editable, selections }: OptionProps) {
    const min = contentBlock?.prompt?.min || 0;
    const max = contentBlock?.prompt?.max || 100;
    const option = contentBlock.prompt.options[0];

    const selection = selections.find((s) => s.option_id === option?.id);

    const [value, setValue] = useState(
        selection?.numerical_value || Math.floor((max - min) / 2),
    );
    const leftPercent = ((value - min) / (max - min)) * 100;

    return (
        <>
            <SliderMinAndMax contentBlock={contentBlock} />
            <div className="flex flex-col space-y-2 py-6">
                <div
                    className="group-OPTION relative flex items-center justify-center gap-x-3"
                    key={option.id}
                >
                    {editable && (
                        <div
                            data-min-input-wrapper={contentBlock.prompt.id}
                        ></div>
                    )}
                    <div className="max-w-2x relative w-full pt-8 text-sapien-blue">
                        <div
                            className="absolute -top-[2px] flex h-8 min-w-8 items-center justify-center rounded-full
                                rounded-bl-none border-2 border-sapien-blue bg-white px-2 shadow-lg
                                translate-x-[5px]"
                            style={{
                                left: `calc(${leftPercent}% + (${10 - leftPercent * 0.2}px))`,
                            }}
                        >
                            {value}
                        </div>
                        <input
                            onChange={(e) => setValue(parseInt(e.target.value))}
                            defaultValue={value}
                            id={option.id}
                            name={option.id}
                            max={max}
                            min={min}
                            type="range"
                            className="h-4 w-full border-gray-300 text-sapien-blue focus:ring-indigo-600 "
                        />
                    </div>
                    {editable && (
                        <div
                            data-max-input-wrapper={contentBlock.prompt.id}
                        ></div>
                    )}
                </div>
            </div>
        </>
    );
}

function OptionDisplay(props: OptionProps) {
    switch (props.contentBlock.prompt.prompt_type) {
        case PromptType["Multiple Choice"]:
        case PromptType["Multiple Select"]:
            return <MultipleChoice {...props} />;
        case PromptType["Numerical Slider"]:
            return <Slider {...props} />;
        default:
            return null;
    }
}

function AddOption({ prompt }: { prompt: PromptShape }) {
    const { mutateAsync } = useSaveOption();

    return (
        <button
            className="border-bottom-2 my-2 flex items-center justify-center space-x-2 self-start
                rounded-md border border-sapien-blue bg-sapien-blue px-4 py-1 text-white"
            onClick={() => {
                mutateAsync(prompt);
            }}
        >
            <span>Add Option</span>
            <PlusIcon />
        </button>
    );
}

export default function QuestionBlock(props: Props) {
    if (!props?.contentBlock?.prompt) return null;

    const selections = useSelections(props.contentBlock.prompt.id);

    const classes =
        props.contentBlock.theme?.tailwindClasses?.length &&
        props.contentBlock.theme?.variant !== "default"
            ? cn([...props.contentBlock.theme?.tailwindClasses, "relative"])
            : "relative flex flex-col justify-center flex flex-col divide-y";

    return (
        <div
            className={
                "group/QUESTION relative flex flex-col space-y-4"
            }
            data-question-id={props.contentBlock.id}
            data-parent-content-block-id={
                props.contentBlock.parent_content_block_id
            }
        >
            <LessonRichText {...props} />
            <div className={classes}>
                <OptionDisplay {...props} selections={selections} />
            </div>
            {props.editable && <AddOption prompt={props.contentBlock.prompt} />}
        </div>
    );
}

function OptionWithContentBlock({
    children,
    contentBlockId,
}: {
    contentBlockId: string;
    children: (contentBlock: ContentBlockShape) => React.ReactNode;
}) {
    const contentBlock = useGetContentBlockWithChildren(contentBlockId);

    const render = (contentBlock: ContentBlockShape) => {
        if (contentBlock) {
            return <>{children(contentBlock)}</>;
        }

        return children(null);
    };

    return render(contentBlock);
}
