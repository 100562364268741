import { useCallback, useEffect, useMemo, useState } from "react";
import { Dictionary } from "lodash";
import {
    ModelVariable,
    ModelVariableAggregateOperation,
    ModelVariableDataType,
    ModelVariableType,
    TimeHorizon,
    VariableRelationship,
    VariableRelationshipOperation,
    VariableValue,
} from "@/models";
import {
    getNewVariableValue,
    getNewVariableRelationship,
    getDownstreamVariableIds,
    getUpstreamVariableIds,
} from "./utils";
import { useModelBuilderVariableDepths } from "./atoms";

export function useVariableValueForm(
    modelVariable: ModelVariable,
    timeHorizons: TimeHorizon[],
) {
    const [variableValue, setVariableValue] = useState<VariableValue>();
    const [variableValueMap, setVariableValueMap] = useState<{
        [index: string]: VariableValue;
    }>();

    useEffect(() => {
        if (
            !!modelVariable &&
            !!modelVariable.variable_type &&
            modelVariable.variable_type === ModelVariableType.Independent
        ) {
            if (!modelVariable.uses_time) {
                if (!variableValue) {
                    if (!!modelVariable.id) {
                        let value = !!modelVariable.baseVariableValues?.length
                            ? modelVariable.baseVariableValues[0]
                            : getNewVariableValue(modelVariable.id);
                        setVariableValue(value);
                    } else {
                        let value = getNewVariableValue(modelVariable.id);
                        setVariableValue(value);
                        setVariableValueMap(undefined);
                    }
                }
            } else {
                if (!variableValueMap) {
                    if (!!modelVariable.id) {
                        let map = timeHorizons?.reduce(
                            (valueMap, timeHorizon) => {
                                const existing =
                                    modelVariable.baseVariableValues?.find(
                                        (variableValue) =>
                                            variableValue.time_horizon_id ===
                                            timeHorizon.id,
                                    );
                                return {
                                    ...valueMap,
                                    [timeHorizon.id]: !!existing
                                        ? {
                                              ...existing,
                                              time_index:
                                                  timeHorizon.time_index,
                                          }
                                        : getNewVariableValue(
                                              modelVariable.id,
                                              timeHorizon.id,
                                              timeHorizon.time_index,
                                          ),
                                };
                            },
                            {},
                        );
                        setVariableValueMap(map);
                    } else {
                        let map = timeHorizons?.reduce(
                            (valueMap, timeHorizon) => ({
                                ...valueMap,
                                [timeHorizon.id]: getNewVariableValue(
                                    modelVariable.id,
                                    timeHorizon.id,
                                    timeHorizon.time_index,
                                ),
                            }),
                            {},
                        );
                        setVariableValueMap(map);
                        setVariableValue(undefined);
                    }
                }
            }
        } else {
            setVariableValue(undefined);
            setVariableValueMap(undefined);
        }
    }, [modelVariable]);

    const getBaseValuesToSave = useCallback(() => {
        let baseVariableValues: VariableValue[];
        if (!!variableValue) {
            baseVariableValues = [variableValue];
        } else if (!!variableValueMap) {
            baseVariableValues = Object.values(variableValueMap);
        } else if (
            // manage values for variables that don't use the value form
            modelVariable.variable_type !== ModelVariableType.Independent
        ) {
            if (!modelVariable.id) {
                // create new values
                if (modelVariable.uses_time) {
                    baseVariableValues = timeHorizons?.map((timeHorizon) =>
                        getNewVariableValue(
                            modelVariable.id,
                            timeHorizon.id,
                            timeHorizon.time_index,
                            modelVariable.default_numerical_value,
                            modelVariable.default_boolean_value,
                        ),
                    );
                } else {
                    baseVariableValues = [
                        getNewVariableValue(
                            modelVariable.id,
                            null,
                            null,
                            modelVariable.default_numerical_value,
                            modelVariable.default_boolean_value,
                        ),
                    ];
                }
            } else {
                // update existing values
                if (modelVariable.uses_time) {
                    baseVariableValues = timeHorizons?.map((timeHorizon) => {
                        const existing = modelVariable.baseVariableValues?.find(
                            (variableValue) =>
                                variableValue.time_horizon_id ===
                                timeHorizon.id,
                        );
                        return !!existing
                            ? {
                                  ...existing,
                                  numerical_value:
                                      modelVariable.default_numerical_value,
                                  boolean_value:
                                      modelVariable.default_boolean_value,
                                  time_index: timeHorizon.time_index,
                              }
                            : getNewVariableValue(
                                  modelVariable.id,
                                  timeHorizon.id,
                                  timeHorizon.time_index,
                                  modelVariable.default_numerical_value,
                                  modelVariable.default_boolean_value,
                              );
                    });
                } else {
                    let value = !!modelVariable.baseVariableValues?.length
                        ? {
                              ...modelVariable.baseVariableValues[0],
                              numerical_value:
                                  modelVariable.default_numerical_value,
                              boolean_value:
                                  modelVariable.default_boolean_value,
                          }
                        : getNewVariableValue(
                              modelVariable.id,
                              null,
                              null,
                              modelVariable.default_numerical_value,
                              modelVariable.default_boolean_value,
                          );
                    baseVariableValues = [value];
                }
            }
        }
        return baseVariableValues;
    }, [modelVariable, timeHorizons, variableValue, variableValueMap]);

    return {
        variableValue,
        setVariableValue,
        variableValueMap,
        setVariableValueMap,
        getBaseValuesToSave,
    };
}

export function useModelBlockAccordionState() {
    const [activeModelBlockAccordionKeys, setActiveModelBlockAccordionKeys] =
        useState({});

    const toggleModelBlockAccordionKey = useCallback(
        (key: string) => {
            const newKeys = !!activeModelBlockAccordionKeys[key]
                ? Object.keys(activeModelBlockAccordionKeys).reduce(
                      (map, accordionKey) => {
                          return accordionKey === key
                              ? map
                              : { ...map, [accordionKey]: true };
                      },
                      {},
                  )
                : { ...activeModelBlockAccordionKeys, [key]: true };
            setActiveModelBlockAccordionKeys(newKeys);
        },
        [activeModelBlockAccordionKeys],
    );

    const expandMultipleModelBlockAccordionKeys = useCallback(
        (keys: string[]) => {
            const newKeys = keys.reduce(
                (map, accordionKey) => {
                    return { ...map, [accordionKey]: true };
                },
                { ...activeModelBlockAccordionKeys },
            );
            setActiveModelBlockAccordionKeys(newKeys);
        },
        [activeModelBlockAccordionKeys],
    );

    const collapseMultipleModelBlockAccordionKeys = useCallback(
        (keys?: string[]) => {
            if (!!keys?.length) {
                // if we have a set of keys, collapse just those
                const newKeys = Object.keys(
                    activeModelBlockAccordionKeys,
                ).reduce((map, accordionKey) => {
                    return keys.includes(accordionKey)
                        ? map
                        : { ...map, [accordionKey]: true };
                }, {});
                setActiveModelBlockAccordionKeys(newKeys);
            } else {
                // otherwise collapse all
                setActiveModelBlockAccordionKeys({});
            }
        },
        [activeModelBlockAccordionKeys],
    );

    const getIsModelBlockExpanded = useCallback(
        (modelBlockId: string) => {
            return !!activeModelBlockAccordionKeys[modelBlockId];
        },
        [activeModelBlockAccordionKeys],
    );

    return {
        toggleModelBlockAccordionKey,
        expandMultipleModelBlockAccordionKeys,
        collapseMultipleModelBlockAccordionKeys,
        getIsModelBlockExpanded,
    };
}

export const useRelationshipForm = (
    targetVariable: ModelVariable,
    variableRelationships: VariableRelationship[],
    setVariableRelationships: (
        variableRelationships: VariableRelationship[],
    ) => void,
) => {
    const updateRelationship = useCallback(
        (index: number, prop: keyof VariableRelationship, value: any) => {
            const updatedRelationships = variableRelationships.map((vr, i) =>
                i === index
                    ? {
                          ...vr,
                          [prop]: value,
                      }
                    : vr,
            );
            setVariableRelationships(updatedRelationships);
        },
        [variableRelationships],
    );

    const addNewVariableRelationship = useCallback(() => {
        if (
            targetVariable.variable_type === ModelVariableType["Time Shift"] &&
            variableRelationships?.length > 0
        ) {
            return;
        }

        let newRelationshipArray = [
            ...variableRelationships,
            getNewVariableRelationship(
                targetVariable.id,
                targetVariable.variable_type,
                variableRelationships.length || 0,
            ),
        ];
        if (
            targetVariable.variable_type === ModelVariableType.Conditional ||
            (targetVariable.variable_type === ModelVariableType["Time Shift"] &&
                variableRelationships?.length == 0)
        ) {
            newRelationshipArray = [
                ...newRelationshipArray,
                getNewVariableRelationship(
                    targetVariable.id,
                    targetVariable.variable_type,
                    (variableRelationships.length || 0) + 1,
                ),
            ];
        }
        setVariableRelationships(newRelationshipArray);
    }, [targetVariable, variableRelationships]);

    const removeUnsavedVariableRelationship = useCallback(
        (relationship: VariableRelationship, index: number) => {
            if (
                !relationship.id &&
                targetVariable.variable_type !== ModelVariableType.Conditional
            ) {
                setVariableRelationships(
                    variableRelationships.filter((_, i) => i !== index),
                );
            }
        },
        [targetVariable, variableRelationships],
    );

    return {
        updateRelationship,
        addNewVariableRelationship,
        removeUnsavedVariableRelationship,
    };
};

// to do: refine logic of variables available for source relationships
export const useAvailableVariables = (
    targetVariable: ModelVariable,
    variableRelationshipMap: {
        [index: string]: VariableRelationship;
    },
    modelVariables: { [index: string]: ModelVariable },
    relationshipsBySourceId: Dictionary<VariableRelationship[]>,
    relationshipsByTargetId: Dictionary<VariableRelationship[]>,
) => {
    const downstreamVariableIds = useMemo<{
        [index: string]: boolean;
    }>(() => {
        if (!!targetVariable && !!targetVariable.id) {
            return getDownstreamVariableIds(
                targetVariable.id,
                relationshipsBySourceId,
            );
        }
    }, [targetVariable?.id, relationshipsBySourceId]);

    const upstreamVariableIds = useMemo<{
        [index: string]: boolean;
    }>(() => {
        if (!!targetVariable && !!targetVariable.id) {
            return getUpstreamVariableIds(
                targetVariable.id,
                relationshipsByTargetId,
            );
        }
    }, [targetVariable?.id, relationshipsByTargetId]);

    const availableVariables = useMemo<ModelVariable[]>(() => {
        if (
            !!variableRelationshipMap &&
            !!downstreamVariableIds &&
            !!upstreamVariableIds &&
            !!modelVariables &&
            !!targetVariable
        ) {
            // take out...
            //  - downstream variables
            //  - upstream variables
            //  - self
            //  - variables already having relationship (to do: revisit)
            //  - variables with incompatible time horizon usage
            //  - to do: consider removing selection data types (and their connection siblings)
            //  - to do: consider removing current ancestors by default
            let baseVariableSet = Object.values(modelVariables).filter(
                (variable) =>
                    !downstreamVariableIds[variable.id] &&
                    (!upstreamVariableIds[variable.id] ||
                        (!!upstreamVariableIds[variable.id] &&
                            !!variableRelationshipMap[variable.id])) && // either not upstream or a direct source (for dropdown)
                    variable.id !== targetVariable.id &&
                    // !variableRelationshipMap[variable.id] && // needed to show in dropdown
                    (!variable.uses_time || targetVariable.uses_time),
            );

            // filter further based on type (to do: further restrictions based on unit, etc)
            switch (targetVariable.variable_type) {
                case ModelVariableType["Iterative"]:
                case ModelVariableType.Algebraic:
                case ModelVariableType.Comparison:
                case ModelVariableType["Time Shift"]:
                    baseVariableSet = [
                        ...baseVariableSet.filter(
                            (variable) =>
                                variable.data_type ===
                                ModelVariableDataType.Number,
                        ),
                    ];
                    break;
                case ModelVariableType.Aggregate:
                    baseVariableSet = [
                        ...baseVariableSet.filter((variable) =>
                            targetVariable.aggregate_operation ===
                            ModelVariableAggregateOperation["Count If"]
                                ? variable.data_type ===
                                  ModelVariableDataType.Boolean
                                : variable.data_type ===
                                  ModelVariableDataType.Number,
                        ),
                    ];
                    break;
                case ModelVariableType.Conditional:
                    baseVariableSet = [
                        ...baseVariableSet.filter(
                            (variable) =>
                                variable.data_type !==
                                ModelVariableDataType["Dictionary Entry"],
                        ),
                    ];
                    break;
                case ModelVariableType.Logical:
                    baseVariableSet = [
                        ...baseVariableSet.filter(
                            (variable) =>
                                variable.data_type ===
                                    ModelVariableDataType.Boolean ||
                                variable.variable_type ===
                                    ModelVariableType["Selection Data"],
                        ),
                    ];
                    break;
                default:
                    baseVariableSet = [];
            }

            return baseVariableSet;
        }
    }, [
        variableRelationshipMap,
        downstreamVariableIds,
        upstreamVariableIds,
        modelVariables,
        targetVariable,
    ]);

    const availableVariablesMap = useMemo<{
        [index: string]: ModelVariable;
    }>(() => {
        if (!!availableVariables?.length) {
            return availableVariables.reduce((map, model) => {
                return { ...map, ...{ [model.id]: model } };
            }, {});
        }
    }, [availableVariables]);

    return {
        availableVariablesMap,
    };
};

export const getAreRelationshipsValid = (
    targetVariable: ModelVariable,
    variableRelationships: VariableRelationship[],
    modelVariables: { [index: string]: ModelVariable },
) => {
    if (!!targetVariable && !!targetVariable.variable_type) {
        let canSubmitByRelationships = true;

        // to do...
        if (!!targetVariable.id && !!variableRelationships?.length) {
            let everyRelationshipHasSource = variableRelationships.every(
                (relationship) => !!relationship.source_variable_id,
            );

            let relationshipTargetTimeHorizonsArePresentWhenNecessary =
                targetVariable.uses_time === false ||
                variableRelationships.every(
                    (relationship) =>
                        modelVariables[relationship.source_variable_id]
                            ?.uses_time ||
                        !!relationship.target_time_horizon_id,
                );

            let conditionalSourcesAreValid =
                targetVariable.variable_type === ModelVariableType.Conditional
                    ? variableRelationships.length % 2 === 0 &&
                      variableRelationships.every(
                          (relationship, i) =>
                              (i % 2 === 1 &&
                                  modelVariables[
                                      relationship.source_variable_id
                                  ]?.data_type ===
                                      ModelVariableDataType.Number) ||
                              (i % 2 === 0 &&
                                  (modelVariables[
                                      relationship.source_variable_id
                                  ]?.data_type ===
                                      ModelVariableDataType.Boolean ||
                                      modelVariables[
                                          relationship.source_variable_id
                                      ]?.variable_type ===
                                          ModelVariableType["Selection Data"])),
                      )
                    : true;

            let timeShiftSourcesAreValid =
                targetVariable.variable_type === ModelVariableType["Time Shift"]
                    ? variableRelationships.length === 2 &&
                      variableRelationships.some(
                          (relationship) =>
                              relationship.operation_type ===
                              VariableRelationshipOperation["Shift Index"],
                      ) &&
                      variableRelationships.some(
                          (relationship) =>
                              relationship.operation_type ===
                              VariableRelationshipOperation["Shift Input"],
                      )
                    : true;

            canSubmitByRelationships =
                everyRelationshipHasSource &&
                relationshipTargetTimeHorizonsArePresentWhenNecessary &&
                conditionalSourcesAreValid &&
                timeShiftSourcesAreValid;
        }

        return canSubmitByRelationships;
    } else {
        return false;
    }
};

export const useGetVariableDepthById = () => {
    const variableDepths = useModelBuilderVariableDepths();

    const getVariableDepthById = useCallback(
        (modelVariableId: string) => {
            return (
                variableDepths?.find(
                    (variableDepthObject) =>
                        variableDepthObject.variable_id === modelVariableId,
                )?.depth || 0
            );
        },
        [variableDepths],
    );

    return { getVariableDepthById };
};
