import { ContentBlockShape } from "@/models";
import {
    AlignCenterIcon,
    AlignLeftIcon,
    AlignRightIcon,
    AlignVerticalJustifyCenter,
    AlignVerticalJustifyEnd,
    AlignVerticalJustifyStart,
} from "lucide-react";
import React from "react";

type Props = {
    contentBlock: ContentBlockShape;
    update: (contentBlock: ContentBlockShape) => void;
};

function filterClasses(contentBlock: ContentBlockShape, isVertical: boolean) {
    return (contentBlock.theme?.tailwindClasses || []).filter((className) => {
        if (className.includes("basis-")) {
            return false;
        }
        if (isVertical) {
            return !className.includes("items-") && !className.includes("flex");
        }
        return !className.includes("justify-") && !className.includes("flex");
    });
}

export function Alignment({ contentBlock, update }: Props) {
    const isVertical =
        contentBlock.theme?.tailwindClasses?.includes("flex-col");

    return (
        <div className="flex flex-col gap-3 pb-2 shadow-sm">
            <label>Alignment</label>
            <span className="isolate inline-flex rounded-md">
                <button
                    data-selected={contentBlock.theme?.tailwindClasses?.includes(
                        "flex-col",
                    )}
                    type="button"
                    className="relative inline-flex items-center bg-white px-3 py-2 text-sm font-semibold
                        text-gray-900 ring-1 ring-inset ring-gray-300 transition-all first:rounded-l-md
                        last:rounded-r-md data-[selected='true']:bg-gray-100 hover:bg-gray-50 focus:z-10"
                    onClick={() => {
                        update({
                            ...contentBlock,
                            theme: {
                                ...contentBlock.theme,
                                tailwindClasses: [
                                    ...(
                                        contentBlock.theme?.tailwindClasses ||
                                        []
                                    ).filter(
                                        (className) =>
                                            !className.includes("flex"),
                                    ),
                                    "flex",
                                    "flex-col",
                                    "basis-[content]",
                                ],
                            },
                        });
                    }}
                >
                    Vertical
                </button>
                <button
                    data-selected={
                        !contentBlock.theme?.tailwindClasses?.includes(
                            "flex-col",
                        )
                    }
                    type="button"
                    className="relative inline-flex items-center bg-white px-3 py-2 text-sm font-semibold
                        text-gray-900 ring-1 ring-inset ring-gray-300 transition-all first:rounded-l-md
                        last:rounded-r-md data-[selected='true']:bg-gray-100 hover:bg-gray-50 focus:z-10"
                    onClick={() => {
                        update({
                            ...contentBlock,
                            theme: {
                                ...contentBlock.theme,
                                tailwindClasses: [
                                    ...(
                                        contentBlock.theme?.tailwindClasses ||
                                        []
                                    ).filter(
                                        (className) =>
                                            !className.includes("flex"),
                                    ),
                                    "flex",
                                    "flex-row",
                                    "basis-[content]",
                                ],
                            },
                        });
                    }}
                >
                    Horizontal
                </button>
            </span>
            <span className="isolate inline-flex rounded-md">
                <button
                    data-selected={contentBlock.theme?.tailwindClasses?.includes(
                        isVertical ? "justify-start" : "items-start",
                    )}
                    type="button"
                    className="relative inline-flex items-center bg-white px-3 py-2 text-sm font-semibold
                        text-gray-900 ring-1 ring-inset ring-gray-300 transition-all first:rounded-l-md
                        last:rounded-r-md data-[selected='true']:bg-gray-100 hover:bg-gray-50 focus:z-10"
                    onClick={() => {
                        update({
                            ...contentBlock,
                            theme: {
                                ...contentBlock.theme,
                                tailwindClasses: [
                                    ...filterClasses(contentBlock, isVertical),
                                    isVertical
                                        ? "justify-start"
                                        : "items-start",
                                    "flex",
                                    "w-full",
                                    "basis-[content]",
                                ],
                            },
                        });
                    }}
                >
                    <AlignVerticalJustifyStart />
                </button>
                <button
                    data-selected={contentBlock.theme?.tailwindClasses?.includes(
                        isVertical ? "justify-start" : "items-start",
                    )}
                    type="button"
                    className="relative inline-flex items-center bg-white px-3 py-2 text-sm font-semibold
                        text-gray-900 ring-1 ring-inset ring-gray-300 transition-all first:rounded-l-md
                        last:rounded-r-md data-[selected='true']:bg-gray-100 hover:bg-gray-50 focus:z-10"
                    onClick={() => {
                        update({
                            ...contentBlock,
                            theme: {
                                ...contentBlock.theme,
                                tailwindClasses: [
                                    ...filterClasses(contentBlock, isVertical),
                                    isVertical
                                        ? "justify-center"
                                        : "items-center",
                                    "flex",
                                    "w-full",
                                    "basis-[content]",
                                ],
                            },
                        });
                    }}
                >
                    <AlignVerticalJustifyCenter />
                </button>
                <button
                    data-selected={contentBlock.theme?.tailwindClasses?.includes(
                        isVertical ? "justify-end" : "items-end",
                    )}
                    type="button"
                    className="relative inline-flex items-center bg-white px-3 py-2 text-sm font-semibold
                        text-gray-900 ring-1 ring-inset ring-gray-300 transition-all first:rounded-l-md
                        last:rounded-r-md data-[selected='true']:bg-gray-100 hover:bg-gray-50 focus:z-10"
                    onClick={() => {
                        update({
                            ...contentBlock,
                            theme: {
                                ...contentBlock.theme,
                                tailwindClasses: [
                                    ...filterClasses(contentBlock, isVertical),
                                    isVertical ? "justify-end" : "items-end",
                                    "flex",
                                    "w-full",
                                    "basis-[content]",
                                ],
                            },
                        });
                    }}
                >
                    <AlignVerticalJustifyEnd />
                </button>
            </span>
            <span className="isolate inline-flex rounded-md">
                <button
                    data-selected={contentBlock.theme?.tailwindClasses?.includes(
                        isVertical ? "items-start" : "justify-start",
                    )}
                    type="button"
                    className="relative inline-flex items-center bg-white px-3 py-2 text-sm font-semibold
                        text-gray-900 ring-1 ring-inset ring-gray-300 transition-all first:rounded-l-md
                        last:rounded-r-md data-[selected='true']:bg-gray-100 hover:bg-gray-50 focus:z-10"
                    onClick={() => {
                        update({
                            ...contentBlock,
                            theme: {
                                ...contentBlock.theme,
                                tailwindClasses: [
                                    ...filterClasses(contentBlock, isVertical),
                                    isVertical ? "items-start" : "justify-start",
                                    "flex",
                                    "w-full",
                                    "basis-[content]",
                                ],
                            },
                        });
                    }}
                >
                    <AlignLeftIcon />
                </button>
                <button
                    data-selected={contentBlock.theme?.tailwindClasses?.includes(
                        isVertical ? "items-center" : "justify-center",
                    )}
                    type="button"
                    className="relative inline-flex items-center bg-white px-3 py-2 text-sm font-semibold
                        text-gray-900 ring-1 ring-inset ring-gray-300 transition-all first:rounded-l-md
                        last:rounded-r-md data-[selected='true']:bg-gray-100 hover:bg-gray-50 focus:z-10"
                    onClick={() => {
                        const tailwindClasses = [
                            ...filterClasses(contentBlock, isVertical),
                            isVertical ? "items-center" : "justify-center",
                            "flex",
                            "w-full",
                            "basis-[content]",
                        ];
                        update({
                            ...contentBlock,
                            theme: {
                                ...contentBlock.theme,
                                tailwindClasses,
                            },
                        });
                    }}
                >
                    <AlignCenterIcon />
                </button>
                <button
                    data-selected={contentBlock.theme?.tailwindClasses?.includes(
                        isVertical ? "items-end" : "justify-end",
                    )}
                    type="button"
                    className="relative inline-flex items-center bg-white px-3 py-2 text-sm font-semibold
                        text-gray-900 ring-1 ring-inset ring-gray-300 transition-all first:rounded-l-md
                        last:rounded-r-md data-[selected='true']:bg-gray-100 hover:bg-gray-50 focus:z-10"
                    onClick={() => {
                        update({
                            ...contentBlock,
                            theme: {
                                ...contentBlock.theme,
                                tailwindClasses: [
                                    ...filterClasses(contentBlock, isVertical),
                                    isVertical ? "items-end" : "justify-end",
                                    "flex",
                                    "w-full",
                                    "basis-[content]",
                                ],
                            },
                        });
                    }}
                >
                    <AlignRightIcon />
                </button>
            </span>
        </div>
    );
}
