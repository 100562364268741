import React, { useMemo } from "react";
import { VariableTreeDisplay } from "./VariableTreeDisplay";
import {
    useModelBuilderVariableDepths,
    useModelVariableMap,
    useRelationshipsBySourceId,
} from "../atoms";
// import { VariableFormulaDisplay } from "./VariableFormulaDisplay";

export const ModelTrees = () => {
    const variableDepths = useModelBuilderVariableDepths();
    const modelVariables = useModelVariableMap();
    const relationshipsBySourceId = useRelationshipsBySourceId();

    const topLevelVariables = useMemo(() => {
        return (
            variableDepths?.filter(
                (variableDepthObject) =>
                    modelVariables?.[variableDepthObject.variable_id] &&
                    !relationshipsBySourceId?.[variableDepthObject.variable_id],
            ) || []
        );
    }, [variableDepths, modelVariables, relationshipsBySourceId]);

    return (
        <div className="space-y-4">
            <div className="mt-2">
                <div className="text-xl">Model Trees</div>
            </div>
            {/* <div style={{ overflowX: "hidden" }} className="space-y-4 text-xs">
                {topLevelVariables.map((variableDepthObject) => {
                    const variable =
                        modelVariables?.[variableDepthObject.variable_id];
                    return variable ? (
                        <div
                            key={variableDepthObject.variable_id}
                            className="rounded-md border border-gray-300 bg-gray-50 p-2"
                            style={{ overflowX: "auto" }}
                        >
                            <VariableFormulaDisplay
                                modelVariable={variable}
                                isCollapsible={false}
                                isChild={false}
                            />
                        </div>
                    ) : null;
                })}
            </div> */}
            <div style={{ overflowX: "hidden" }} className="text-xs">
                {topLevelVariables.map((variableDepthObject) => {
                    const variable =
                        modelVariables?.[variableDepthObject.variable_id];
                    return variable ? (
                        <div
                            key={variableDepthObject.variable_id}
                            className="mb-4"
                            style={{ overflowX: "auto" }}
                        >
                            <VariableTreeDisplay
                                modelVariable={variable}
                                isCollapsible={true}
                            />
                        </div>
                    ) : null;
                })}
            </div>
        </div>
    );
};
