import { useGetElementAsync } from "@/hooks/useGetElementAsync";
import React, { useMemo, useState } from "react";
import { useContentBlockState, useGetContentBlockWithChildren } from "../../useContentBlockState";
import { createPortal } from "react-dom";

import { BrushIcon, ImageIcon } from "lucide-react";
import { Dialog, DialogContent, DialogTrigger } from "@/components/Dialog";
import { useSaveContentBlock } from "../../content-block-management/useSaveContentBlock";
import { updateQuestionTheme } from "./updateQuestionTheme";
import { ContentBlockShape } from "@/models";

export const QuestionStyle = () => {
    const { contentBlocks } = useContentBlockState();
    const questionBlocks = useMemo(
        () =>
            Object.values(contentBlocks).filter(
                (block) => block.content_block_type === "QUESTION",
            ),
        [contentBlocks],
    );

    if (!questionBlocks?.length) return null;
    return questionBlocks.map((questionBlock) => (
        <QuestionStyleEditor
            key={questionBlock.id}
            questionContentBlock={questionBlock}
        />
    ));
};

export type QuestionTheme = "default" | "cards";

const QuestionStyleEditor = ({
    questionContentBlock,
}: {
    questionContentBlock: ContentBlockShape;
}) => {
    const questionElement = useGetElementAsync(`[data-question-id="${questionContentBlock.id}"]`);
    const questionBlockWithChildren = useGetContentBlockWithChildren(questionContentBlock.id);

    const [isOpen, setIsOpen] = useState(false);

    const [selectedTheme, setSelectedTheme] = useState<string | null>(
        questionContentBlock?.theme?.variant || "default",
    );

    const { mutateAsync } = useSaveContentBlock();

    const handleThemeChange = async (theme: QuestionTheme) => {
        setSelectedTheme(theme);
        const updatedQuestion = updateQuestionTheme(
            questionBlockWithChildren,
            theme,
        );
        await mutateAsync(updatedQuestion);
        setIsOpen(false);
    };

    if (!questionElement) return null;

    return createPortal(
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <DialogTrigger
                asChild
                className="z-[999999] absolute right-0 top-0 w-24 items-end opacity-0 transition-all
                    duration-300 group-hover/QUESTION:opacity-100
                    group-hover/QUESTION:-translate-y-1/2 data-[is-open=true]:opacity-100
                    data-[is-open=false]:-translate-y-[200%] data-[is-open=true]:-translate-y-1/2"
            >
                <button
                    className="flex w-48 flex-row items-center justify-center gap-2 rounded-md border
                        border-gray-300 bg-white p-2 px-2 shadow-md hover:bg-slate-200"
                >
                    <span>
                        {questionBlockWithChildren.theme?.variant || "Question"}
                    </span>
                    <BrushIcon className="h-4 w-4" />
                </button>
            </DialogTrigger>
            <DialogContent className="overflow-hidden bg-[#f5f5f7] shadow-lg">
                <div className="top-8 flex w-screen max-w-5xl flex-col gap-8 scrollbar-thumb-rounded-full">
                    <h2 className="p-6 text-lg font-bold">
                        Choose a question theme
                    </h2>
                    <div
                        className="flex max-h-[70vh] flex-col gap-6 overflow-y-auto p-6 scrollbar
                            scrollbar-thumb-gray-500 scrollbar-w-2"
                    >
                        <QuestionThemeButton
                            isSelected={selectedTheme === "default"}
                            onSelect={() => handleThemeChange("default")}
                        >
                            <span>Default</span>
                        </QuestionThemeButton>

                        <QuestionThemeButton
                            isSelected={selectedTheme === "cards"}
                            onSelect={() => handleThemeChange("cards")}
                        >
                            <h3 className="w-full text-left text-lg font-bold">
                                Cards
                            </h3>
                            <span className="flex h-8 w-full rounded-md border bg-gray-200 md:w-3/4"></span>
                            <span className="grid w-full grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4">
                                {Array.from({ length: 4 }).map((_, idx) => (
                                    <span
                                        className="flex w-full flex-col items-center gap-2 rounded-2xl bg-white p-6
                                            shadow-[2px_4px_12px_#00000014] transition-all
                                            data-[selected=true]:border-sapien-blue
                                            data-[selected=true]:shadow-[2px_4px_12px_#00000029]"
                                        key={idx}
                                        data-selected={
                                            selectedTheme === "cards"
                                        }
                                    >
                                        <span className="flex w-full flex-col gap-2">
                                            {Array.from({ length: 3 }).map(
                                                (_, idx) => (
                                                    <span
                                                        key={idx}
                                                        className="h-8 w-full rounded-md border bg-gray-200"
                                                    ></span>
                                                ),
                                            )}
                                        </span>
                                        <span className="h-8 w-full text-sm font-medium"></span>
                                        <ImageIcon className="h-auto w-2/3 text-gray-500" />
                                    </span>
                                ))}
                            </span>
                        </QuestionThemeButton>
                    </div>
                </div>
            </DialogContent>
        </Dialog>,
        questionElement,
    );
};

function QuestionThemeButton({
    isSelected,
    onSelect,
    children,
}: {
    isSelected: boolean;
    onSelect: () => void;
    children: React.ReactNode | React.ReactNode[];
}) {
    return (
        <button
            data-selected={isSelected}
            onClick={onSelect}
            className="flex flex-col items-start gap-4 rounded-md border-2 border-gray-300 bg-[#f5f5f7]
                p-6 shadow-md transition-all duration-300
                data-[selected=true]:border-sapien-blue data-[selected=true]:bg-white
                data-[selected=true]:scale-[101%] hover:bg-white hover:scale-[101%]"
        >
            {children}
        </button>
    );
}
