import React, { useEffect } from "react";
import Checkbox from "@/components/Checkbox";
import Guest from "@/Layouts/Guest";
import Input from "@/components/Input";
import Label from "@/components/Label";
import ValidationErrors from "@/components/ValidationErrors";
import { Head, Link, useForm } from "@inertiajs/react";
import { route } from "ziggy-js";
import { SapienPageProps } from "@/inertia-utils/types";
import { sapienRoute } from "@/inertia-utils/hooks";

type Props = {
    statuses?: string[];
    canResetPassword: boolean;
    isCentral: boolean;
} & SapienPageProps;

export default function Login({
    statuses,
    canResetPassword,
    tenant,
    isCentral,
}: Props) {
    const { data, setData, post, processing, errors, reset } = useForm({
        email: "",
        password: "",
        remember: "",
    });

    useEffect(() => {
        return () => {
            reset("password");
        };
    }, []);

    const onHandleChange = (event) => {
        setData(
            event.target.name,
            event.target.type === "checkbox"
                ? event.target.checked
                : event.target.value,
        );
    };

    const submit = (e) => {
        e.preventDefault();

        const intendedRoute = route(isCentral ? "central.login.post" : "login");
        post(intendedRoute, {
            onSuccess(stuff) {},
        });
    };

    return (
        <>
            <Guest tenant={tenant}>
                <Head title="Log in" />

                <ValidationErrors errors={errors} />

                <form onSubmit={submit}>
                    <div>
                        <Label forInput="email" value="Email" />

                        <Input
                            type="text"
                            name="email"
                            value={data.email}
                            className="mt-1 block w-full"
                            autoComplete="username"
                            isFocused={true}
                            handleChange={onHandleChange}
                        />
                    </div>

                    <div className="mt-4">
                        <Label forInput="password" value="Password" />

                        <Input
                            type="password"
                            name="password"
                            value={data.password}
                            className="mt-1 block w-full"
                            autoComplete="current-password"
                            handleChange={onHandleChange}
                        />
                    </div>

                    <div className="mt-4 block">
                        <label className="flex items-center">
                            <Checkbox
                                name="remember"
                                value={data.remember}
                                handleChange={onHandleChange}
                            />

                            <span className="ml-2 text-sm text-gray-600">
                                Remember me
                            </span>
                        </label>
                    </div>

                    <div className="mt-4 flex items-center justify-end">
                        <button
                            className="hover:bg-sapien-blue-dark w-full rounded-md bg-sapien-blue px-4 py-2 text-center
                                text-white"
                            data-testid="login-button"
                            disabled={processing}
                        >
                            Log in
                        </button>
                    </div>
                    {canResetPassword && (
                        <div className="mt-4 flex flex-col justify-between space-y-4 pb-4 text-sm">
                            <p className="text-center">
                                Forgot your password?{" "}
                                <Link
                                    href={route("password.request")}
                                    className="text-sm text-gray-600 underline hover:text-gray-900"
                                >
                                    Reset
                                </Link>
                            </p>
                            <div className="mt-8 flex justify-center space-x-1 text-center">
                                <Link
                                    href={sapienRoute(
                                        "passwordless-login.show",
                                    )}
                                    className="text-sm font-bold text-gray-600 underline hover:text-gray-900"
                                >
                                    Log in with a secure Magic Link
                                    (recommended)
                                </Link>
                            </div>
                        </div>
                    )}
                    {statuses?.length > 0 && (
                        <div className="mt-4 flex flex-col justify-between space-y-4 pb-4 text-sm">
                            {statuses.map((status) => (
                                <div className="text-sm font-medium text-green-600">
                                    {status}
                                </div>
                            ))}
                        </div>
                    )}
                </form>
            </Guest>
        </>
    );
}
