import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { sapienAxios } from "@/inertia-utils/hooks";
import { VariableDepthObject, VariableValue } from "@/models";
import { useAtomValue } from "jotai";
import {
    modelBuilderPagePropsAtom,
    useSetBaseVariableValuesMap,
    useSetModelBuilderVariableDepths,
} from "./atoms";
import { useEffect, useState } from "react";

type BaseVariableValuesPayload = {
    simulation_id?: string;
    model_block_id?: string;
    model_variable_id?: string;
    modelVariableIds?: string[];
};

type BaseVariableValuesResponse = {
    baseVariableValues: Record<string, VariableValue[]>;
    variableDepths: VariableDepthObject[];
};

async function baseVariableValuesFunction({
    body,
}: {
    body: Partial<BaseVariableValuesPayload>;
}) {
    const { data } = await sapienAxios.post<
        BaseVariableValuesResponse,
        "model-builder.base-variable-values"
    >(
        "model-builder.base-variable-values",
        body as Partial<BaseVariableValuesPayload>,
        {},
    );

    return data;
}

const handleBaseVariableValues = (
    parameters: BaseVariableValuesPayload,
    isEnabled: boolean,
    callback: () => void,
) => {
    const { data } = useQuery({
        queryKey: ["baseVariableValues", parameters],
        queryFn: async () => {
            const data = await baseVariableValuesFunction({
                body: parameters,
            });
            callback();
            return data;
        },
        select: (data) => data,
        placeholderData: keepPreviousData,
        enabled: isEnabled,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });

    return data;
};

export const useHandleBaseVariableValues = () =>
    // parameters: BaseVariableValuesPayload,
    {
        const modelBuilderPageProps = useAtomValue(modelBuilderPagePropsAtom);
        const setBaseVariableValuesMap = useSetBaseVariableValuesMap();
        const setModelBuilderVariableDepths =
            useSetModelBuilderVariableDepths();

        const [isEnabled, setIsEnabled] = useState(false);

        useEffect(() => {
            setIsEnabled(true);
        }, [modelBuilderPageProps]);

        const response = handleBaseVariableValues(
            {
                simulation_id: !modelBuilderPageProps?.type
                    ? modelBuilderPageProps?.simulation?.id
                    : null,
                model_block_id: !!modelBuilderPageProps?.interactiveBlockId
                    ? modelBuilderPageProps?.interactiveBlockId
                    : null,
            },
            isEnabled,
            () => setIsEnabled(false),
        );

        useEffect(() => {
            if (!response) return;
            const { baseVariableValues, variableDepths } = response;
            setBaseVariableValuesMap(baseVariableValues);
            setModelBuilderVariableDepths(variableDepths);
        }, [response]);

        return {};
    };
