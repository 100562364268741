import { ContentBlockShape } from "@/models";
import { cn } from "@/util";
import React from "react";

type Props = {
    contentBlock: ContentBlockShape;
    children: React.ReactNode | React.ReactNode[];
    isInAdminContext: boolean;
};

export default function LessonSection({
    contentBlock,
    children,
    isInAdminContext,
}: Props) {
    const tailwindClasses = contentBlock.theme?.tailwindClasses || [];
    const gradientAngle = contentBlock.theme?.tailwindClasses?.find(
        (className) => className.includes("bg-gradient-to-"),
    );
    return (
        <section
            className={`flex w-full items-center justify-center from-[var(--from-color)]
            ${contentBlock.theme?.["via-color"] ? "via-[var(--via-color)]" : ""}
            to-[var(--to-color)] transition-all has-[.cursor-wait]:animate-pulse
            ${gradientAngle}`}
            data-content-block-id={contentBlock.id}
            data-parent-content-block-id={contentBlock.parent_content_block_id}
            style={
                {
                    backgroundColor: contentBlock.theme?.backgroundColor,
                    "--from-color":
                        contentBlock.theme?.["from-color"] || "white",
                    "--to-color": contentBlock.theme?.["to-color"] || "white",
                    "--via-color": contentBlock.theme?.["via-color"] || "white",
                } as React.CSSProperties
            }
            data-test-weight-and-type={`${contentBlock.weight}_${contentBlock.content_block_type}`}
        >
            <div
                style={{ backgroundImage: contentBlock.theme?.backgroundImage }}
                className={`transition-all ${cn([
                    "w-full",
                    "max-w-3xl",
                    "lg:max-w-4xl",
                    ...tailwindClasses.filter(
                        (className) => !className.includes("bg-gradient-to-"),
                    ),
                ])}`}
            >
                {children}
            </div>
        </section>
    );
}
