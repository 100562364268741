import React from "react";
import { TabsContent } from "@/components/Tabs";
import { ContentBlockShape } from "@/models";
import { useGetContentBlockWithChildren } from "@/Pages/ELearning/LessonDesign/useContentBlockState";
import NestedLessonContentBlockDisplay from "../../NestedContentBlockDisplay";
import { cn } from "@/util";

type Props = {
    contentBlock: ContentBlockShape;
    isInAdminContext: boolean;
};

export default function LessonTab({
    contentBlock,
    isInAdminContext = false,
}: Props) {
    const contentBlockWithChildren = useGetContentBlockWithChildren(
        contentBlock.id,
    );

    return (
        <TabsContent
            data-content-block-id={contentBlock.id}
            value={contentBlock.id}
        >
            <div className={cn(contentBlock.theme?.tailwindClasses)}>
                {contentBlockWithChildren.contentBlocks?.map((contentBlock) => (
                    <NestedLessonContentBlockDisplay
                        key={contentBlock.id}
                        contentBlock={contentBlock}
                        isInAdminContext={isInAdminContext}
                    />
                ))}
            </div>
        </TabsContent>
    );
}
