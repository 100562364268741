import { ContentBlockShape } from "@/models";
import { useMemo } from "react";

export function useComponentDisplayProps(contentBlock: ContentBlockShape) {


    return  useMemo(() => {
        const props:any = {};

        if(contentBlock.image_url) {
            props.src = contentBlock.image_url;
        }

        if(contentBlock.parent_content_block_id) {
            props["data-parent-content-block-id"] = contentBlock.parent_content_block_id;
        }

        return props;
    },[contentBlock])
    
    
}
