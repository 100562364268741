import { useCallback } from "react";
import { atom, useAtom, useAtomValue } from "jotai";
import { ModelVariable, VariableRelationship } from "@/models";
import { getNewVariableRelationship } from "./utils";
import { useModelVariableMap, useRelationshipsByTargetId } from "./atoms";

const workspaceTargetVariableAtom = atom<ModelVariable>(
    undefined as ModelVariable,
);
const workspaceSourceVariablesAtom = atom<{
    [variableId: string]: ModelVariable;
}>({});
const workspaceSourceVariableRelationshipsAtom = atom<VariableRelationship[]>(
    [],
);
const workspaceAvailableVariablesMapAtom = atom<{
    [index: string]: ModelVariable;
}>({});
const workspaceSourceVariableRelationshipMapAtom = atom<
    { [index: string]: VariableRelationship } | undefined
>((get) => {
    const sourceVariableRelationships = get(
        workspaceSourceVariableRelationshipsAtom,
    );
    return sourceVariableRelationships?.length > 0
        ? sourceVariableRelationships.reduce(
              (map, variableRelationship, index) => ({
                  ...map,
                  [variableRelationship.source_variable_id || index]:
                      variableRelationship,
              }),
              {},
          )
        : {};
});

export function useBuilderWorkspace() {
    // const targetVariable = useAtomValue(workspaceTargetVariableAtom);
    // const sourceVariables = useAtomValue(workspaceSourceVariablesAtom);
    // const sourceVariableRelationships = useAtomValue(
    //     workspaceSourceVariableRelationshipsAtom,
    // );
    // const availableVariablesMap = useAtomValue(
    //     workspaceAvailableVariablesMapAtom,
    // );

    const [_targetVariable, _setTargetVariable] = useAtom(
        workspaceTargetVariableAtom,
    );
    const [_sourceVariables, _setSourceVariables] = useAtom(
        workspaceSourceVariablesAtom,
    );
    const [_sourceVariableRelationships, _setSourceVariableRelationships] =
        useAtom(workspaceSourceVariableRelationshipsAtom);
    const [_availableVariablesMap, _setAvailableVariablesMap] = useAtom(
        workspaceAvailableVariablesMapAtom,
    );

    const _sourceVariableRelationshipMap = useAtomValue(
        workspaceSourceVariableRelationshipMapAtom,
    );

    const _modelVariables = useModelVariableMap();
    const _relationshipsByTargetId = useRelationshipsByTargetId();

    const getCanToggleTargetVariable = useCallback(
        (variable: ModelVariable) => {
            // toggle as target only if variable isn't source (confusing otherwise)
            // tbd: other rules (based on target and sources)?
            // for now we're setting/unsetting everything as appropriate (no fancy stuff)
            return !_sourceVariables[variable.id];
        },
        [_sourceVariables],
    );

    const toggleTargetVariable = useCallback(
        (variable: ModelVariable) => {
            if (!!_targetVariable && _targetVariable.id === variable.id) {
                // if we have a target and it's this variable, unset it
                _setTargetVariable(undefined);

                // unset source variables and relationships (just based on existing relationships? preserve some? TBD)
                _setSourceVariables({});
                _setSourceVariableRelationships([]);
            } else {
                // otherwise, either we don't have a target or we do and it isn't this variable
                // so change the target to this variable
                _setTargetVariable(variable);

                const existingRelationships =
                    !!_relationshipsByTargetId &&
                    _relationshipsByTargetId[variable.id];
                // if new target has relationships, set source variables and relationships accordingly
                // otherwise, unset source variables and relationships (for now, but behavior TBD)
                if (existingRelationships?.length > 0) {
                    _setSourceVariables(
                        existingRelationships.reduce((map, relationship) => {
                            if (
                                !!_modelVariables &&
                                _modelVariables[relationship.source_variable_id]
                            ) {
                                return {
                                    ...map,
                                    [relationship.source_variable_id]:
                                        _modelVariables[
                                            relationship.source_variable_id
                                        ],
                                };
                            } else {
                                return map;
                            }
                        }, {}),
                    );
                    _setSourceVariableRelationships([
                        ...existingRelationships.map((relationship, index) => ({
                            ...relationship,
                            weight: index,
                        })),
                    ]);
                } else {
                    _setSourceVariables({});
                    _setSourceVariableRelationships([]);
                }
            }
        },
        [
            _targetVariable,
            _setTargetVariable,
            _setSourceVariables,
            _setSourceVariableRelationships,
            _relationshipsByTargetId,
            _modelVariables,
        ],
    );

    const getCanToggleSourceVariable = useCallback(
        (variable: ModelVariable) => {
            // toggle as source only if variable isn't target
            // plus other rules (based on target and sources)
            // e.g., for simplicity, if already-saved relationship exists, don't allow removal
            // also, check type/etc of target/sources if we want to add it
            const isTarget = variable.id === _targetVariable?.id;
            const isSource = !!_sourceVariables[variable.id];
            const hasSavedRelationship = _sourceVariableRelationships.find(
                (relationship) =>
                    relationship.source_variable_id === variable.id &&
                    !!relationship.id,
            );
            let isAddableAsNewSource = true;
            // if we have a target, we need to check conditions
            if (!!_targetVariable && !!_availableVariablesMap) {
                isAddableAsNewSource =
                    !!_availableVariablesMap[variable.id] &&
                    variable.scope === _targetVariable.scope; // for now, to make less confusing
            } else {
                isAddableAsNewSource = false; // remove when new target creation is implemented
            }
            // otherwise, we don't have a target and can assume we don't have other sources either
            // (otherwise a target would have been generated)

            return (
                !isTarget && // not target and
                ((isSource && !hasSavedRelationship) || // either is source but not saved relationship
                    (!isSource && isAddableAsNewSource)) // or is not source (therefore no relationship) and is addable given conditions
            );
        },
        [
            _targetVariable,
            _sourceVariables,
            _sourceVariableRelationships,
            _availableVariablesMap,
        ],
    );

    const toggleSourceVariable = useCallback(
        (variable: ModelVariable) => {
            if (!getCanToggleSourceVariable(variable)) return;

            if (!!_sourceVariables[variable.id]) {
                // if this variable is already a source, we want to remove it
                // with above check, we know there isn't an already-saved relationship
                _setSourceVariables(
                    Object.keys(_sourceVariables).reduce(
                        (map, sourceVariableId) => {
                            return variable.id === sourceVariableId
                                ? map
                                : {
                                      ...map,
                                      [sourceVariableId]:
                                          _sourceVariables[sourceVariableId],
                                  };
                        },
                        {},
                    ),
                );
                _setSourceVariableRelationships(
                    [..._sourceVariableRelationships]
                        .filter(
                            (relationship) =>
                                relationship.source_variable_id !== variable.id,
                        )
                        .map((relationship, i) => ({
                            ...relationship,
                            weight: i,
                        })),
                );
            } else {
                // otherwise, this variable isn't already a source, so we want to add it in
                // with above check, we know it's addable

                // for now, only add source variable/relationship if we have a target
                if (!!_targetVariable) {
                    _setSourceVariables({
                        ..._sourceVariables,
                        [variable.id]: variable,
                    });
                    _setSourceVariableRelationships([
                        ..._sourceVariableRelationships,
                        {
                            ...getNewVariableRelationship(
                                _targetVariable.id,
                                _targetVariable.variable_type,
                                _sourceVariableRelationships.length || 0,
                            ),
                            source_variable_id: variable.id,
                        },
                    ]);
                } else {
                    // TO DO: if we don't have a target variable already, we want to generate a new one
                    // const newTargetVariable = {};
                    // _setTargetVariable(newTargetVariable);
                    // _setSourceVariables({
                    //     ..._sourceVariables,
                    //     [variable.id]: variable,
                    // });
                    // create new relationship based on conditions
                    // _setSourceVariableRelationships([
                    //     ..._sourceVariableRelationships,
                    //     {
                    //         ...getNewVariableRelationship(
                    //             null,
                    //             newTargetVariable.variable_type,
                    //             _sourceVariableRelationships.length || 0
                    //         ),
                    //         source_variable_id: variable.id,
                    //     },
                    // ]);
                }
            }
        },
        [
            _targetVariable,
            _sourceVariables,
            _sourceVariableRelationships,
            _setSourceVariables,
            _setSourceVariableRelationships,
            getCanToggleSourceVariable,
        ],
    );

    const getIsSelectedAsSourceVariable = useCallback(
        (variableId: string) => {
            return !!_sourceVariables[variableId];
        },
        [_sourceVariables],
    );

    const getIsSelectedAsTargetVariable = useCallback(
        (variableId: string) => {
            return _targetVariable?.id === variableId;
        },
        [_targetVariable],
    );

    const updateSourceRelationship = useCallback(
        (index: number, prop: keyof VariableRelationship, value: any) => {
            const updatedRelationships = [..._sourceVariableRelationships].map(
                (vr, i) =>
                    i === index
                        ? {
                              ...vr,
                              [prop]: value,
                          }
                        : vr,
            );
            _setSourceVariableRelationships(updatedRelationships);
        },
        [_sourceVariableRelationships, _setSourceVariableRelationships],
    );

    const resetBuilderWorkspace = useCallback(() => {
        _setTargetVariable(undefined);
        _setSourceVariables({});
        _setSourceVariableRelationships([]);
    }, [
        _setTargetVariable,
        _setSourceVariableRelationships,
        _setSourceVariableRelationships,
    ]);

    return {
        targetVariable: _targetVariable,
        sourceVariables: _sourceVariables,
        sourceVariableRelationships: _sourceVariableRelationships,
        sourceVariableRelationshipMap: _sourceVariableRelationshipMap,
        modelVariables: _modelVariables,

        toggleTargetVariable,
        toggleSourceVariable,

        getCanToggleTargetVariable,
        getCanToggleSourceVariable,

        getIsSelectedAsTargetVariable,
        getIsSelectedAsSourceVariable,

        updateSourceRelationship,
        resetBuilderWorkspace,
        setAvailableVariablesMap: _setAvailableVariablesMap,
    };
}
