import { sapienRoute } from "@/inertia-utils/hooks";
import { Link } from "@inertiajs/react";
import { ChevronDown } from "lucide-react";
import React, { useState } from "react";

type Props = {
    rounds: App.Data.RoundOrLessonData[];
    currentPageId: string;
    simulationId: string;
    currentRoundId: string;
};

export default function RoundListNavigation({
    rounds,
    currentPageId,
    simulationId,
    currentRoundId,
}: Props) {
    return (
        <div className="flex flex-col gap-2">
            {rounds.map((round) => (
                <RoundListNavigationItem
                    key={round.id}
                    round={round}
                    currentPageId={currentPageId}
                    simulationId={simulationId}
                    currentRoundId={currentRoundId}
                />
            ))}
        </div>
    );
}

function RoundListNavigationItem({
    round,
    currentPageId,
    simulationId,
    currentRoundId,
}: {
    round: App.Data.RoundOrLessonData;
    currentPageId: string;
    simulationId: string;
    currentRoundId: string;
}) {
    const [isOpen, setIsOpen] = useState(currentRoundId === round.id);

    return (
        <div className="flex flex-col gap-2 rounded-md bg-slate-100 p-4">
            <div className="flex gap-2">
                <button onClick={() => setIsOpen(!isOpen)}>
                    <ChevronDown
                        className="h-4 w-4 transition-transform duration-300 data-[isopen=false]:-rotate-90"
                        data-isopen={isOpen}
                    />
                </button>
                <h3 className="text-lg font-bold">{round.title}</h3>
            </div>

            <div
                className="flex-col gap-2 px-6 data-[isopen=true]:flex data-[isopen=false]:hidden transition-all"
                data-isopen={isOpen}
                style={{
                    transitionBehavior: "allow-discrete",
                    opacity: isOpen ? 1 : 0,
                }}
            >
                {round.pages.map((page) => (
                    <Link
                        key={page.id}
                        href={sapienRoute("design-studio.view.page", {
                            simulationId,
                            roundId: round.id,
                            page: page.id,
                        })}
                        data-is-current-page={page.id === currentPageId}
                        className="rounded-md p-2 text-xs transition-colors
                            data-[is-current-page=true]:bg-sapien-blue
                            data-[is-current-page=true]:text-white hover:bg-sapien-blue hover:text-white"
                    >
                        {page.title}
                    </Link>
                ))}
            </div>
        </div>
    );
}
