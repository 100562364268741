import React from "react";
import {
    Dialog,
    DialogPanel,
    DialogTitle,
    Transition,
    TransitionChild,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { createPortal } from "react-dom";
import { useDesignStudioSidebarState } from "./useDesignStudioSidebarState";
import { ContentBlockType } from "@/models";
import ImageSidebar from "./sidebars/ImageSidebar";
import { useEditedType } from "./editFunctions";
import { useGetElementAsync } from "@/hooks/useGetElementAsync";
import QuestionSidebar from "./sidebars/QuestionSidebar";
import FlashcardSidebar from "./sidebars/flash-cards/FlashcardSidebar";
import AccordionAndTabsSidebar from "./sidebars/accordions-and-tabs/AccordionAndTabsSidebar";
import EditchartSidebar from "./sidebars/charts/EditChartSidebar";

type Props = {
    children: React.ReactNode | React.ReactNode[];
    title?: string;
};

function EditorAsides() {
    const { editedContentBlockType } = useEditedType();
    if (!editedContentBlockType) return null;
    console.log("editedContentBlockType", editedContentBlockType);
    switch (editedContentBlockType) {
        case ContentBlockType.Tabs:
        case ContentBlockType.Accordion:
            return <AccordionAndTabsSidebar />;
        case ContentBlockType.Image:
            return <ImageSidebar />;
        case ContentBlockType.Form:
            return <QuestionSidebar />;
        case ContentBlockType["Flip Card"]:
            return <FlashcardSidebar />;
        case ContentBlockType["Chart Container"]:
            return <EditchartSidebar />;
        default:
            return null;
    }
}

export function AsideContents({ children, title }: Props) {
    const titleEl = !!title ? useGetElementAsync("#dialog-title") : null;
    const contentEl = useGetElementAsync("#dialog-children");
    return (
        <>
            {!!titleEl && createPortal(<>{title}</>, titleEl)}
            {!!contentEl && createPortal(children, contentEl)}
        </>
    );
}

export default function Aside() {
    const { isOpen, handleClose } = useDesignStudioSidebarState();

    return (
        <>
            <EditorAsides />
            <Transition show={isOpen}>
                <Dialog className="relative z-[9999]" onClose={handleClose}>
                    <div className="fixed inset-0 bg-black/25" />

                    <div className="fixed inset-0 overflow-hidden">
                        <div className="absolute inset-0 overflow-hidden">
                            <div className="pointer-events-none fixed inset-y-0 left-0 top-16 flex max-w-full pr-10">
                                <TransitionChild
                                    enter="transform transition-all ease-in-out duration-500 sm:duration-700"
                                    enterFrom="-translate-x-full"
                                    enterTo="translate-x-0"
                                    leave="transform transition-all ease-in-out duration-500 sm:duration-700"
                                    leaveFrom="translate-x-0"
                                    leaveTo="-translate-x-full"
                                >
                                    <DialogPanel className="pointer-events-auto w-screen max-w-xl">
                                        <div
                                            className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl scrollbar
                                                scrollbar-thumb-gray-300 scrollbar-thumb-rounded-full scrollbar-w-2"
                                        >
                                            <div className="px-4 sm:px-6">
                                                <div className="flex items-start justify-between">
                                                    <DialogTitle
                                                        id="dialog-title"
                                                        className="mt-16 text-base font-semibold leading-6 text-gray-900"
                                                    ></DialogTitle>
                                                    <div className="ml-3 flex h-7 items-center">
                                                        <button
                                                            type="button"
                                                            className="relative rounded-md bg-white text-gray-400 hover:text-gray-500
                                                                focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                            onClick={() =>
                                                                handleClose()
                                                            }
                                                        >
                                                            <span className="absolute -inset-2.5" />
                                                            <span className="sr-only">
                                                                Close panel
                                                            </span>
                                                            <XMarkIcon
                                                                className="h-6 w-6"
                                                                aria-hidden="true"
                                                            />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="relative mt-6 flex-1 px-4 sm:px-6"
                                                id="dialog-children"
                                            ></div>
                                        </div>
                                    </DialogPanel>
                                </TransitionChild>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
}
