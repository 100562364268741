import React from "react";
import { AdminPageSection } from "./AdminPageSection";
import { cn } from "@/util";

export const AdminPageHeader = ({
    children,
    pageTitle,
    className,
}: {
    children?: React.ReactNode;
    pageTitle?: string;
    className?: string;
}) => {
    return (
        <AdminPageSection className={cn("border-b border-slate-200 bg-slate-50", className)}>
            <div className="flex min-h-16 flex-row items-center justify-between py-2">
                {!!pageTitle && (
                    <div>
                        <span className="text-xl font-semibold text-slate-900">
                            {pageTitle}
                        </span>
                    </div>
                )}
                {children !== undefined && (
                    <div className={!pageTitle ? "w-full" : ""}>{children}</div>
                )}
            </div>
        </AdminPageSection>
    );
};
