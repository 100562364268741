import { useEffect } from "react";
import { activeContentBlockAtom } from "./useGetActiveContentBlock";
import { ContentBlockShape } from "@/models/ContentBlock";
import { useAtom } from "jotai";
import { useSelectActiveContentBlock } from "./useSelectActiveContentBlock";
import { useContentBlockState } from "@/Pages/DesignStudio/Design/useContentBlockState";

export function useHandleSavedContentBlock(
    savedBlock: ContentBlockShape | undefined
) {
    const { setContentBlock } = useContentBlockState();
    const [activeContentBlock, updateActiveContentBlock] = useAtom(
        activeContentBlockAtom
    );
    const { setActiveContentBlock } = useSelectActiveContentBlock();
    useEffect(() => {
        if (!savedBlock) return;
        setContentBlock(savedBlock);

        if (
            !activeContentBlock ||
            !activeContentBlock.id ||
            activeContentBlock.id !== savedBlock.id
        ) {
            setTimeout(() => {
                updateActiveContentBlock(savedBlock);
            }, 1);
            return;
        } 
        setActiveContentBlock("");
    }, [savedBlock]);
}
