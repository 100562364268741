import {
    ModelVariable,
    ModelVariableAggregateOperation,
    ModelVariableDataType,
    ModelVariableResampleFunction,
    ModelVariableType,
    VariableRelationship,
    VariableRelationshipOperation,
    VariableValue,
} from "@/models";
import { Dictionary } from "lodash";

// generate properties of new variables if type changes
export const setVariableProperties = (variable: ModelVariable) => {
    let baseProperties = {
        data_type:
            variable.variable_type === ModelVariableType.Logical ||
            variable.variable_type === ModelVariableType.Comparison
                ? ModelVariableDataType.Boolean
                : ModelVariableDataType.Number,
        uses_time: variable.variable_type === ModelVariableType["Iterative"],
        default_numerical_value: 0,
        default_boolean_value: false,
        unit: null,
        is_integer: false,
        is_absolute_value: false,
        expose_to_facilitator: false,
        aggregate_operation: null,
        expose_to_designer: false,
        is_key_metric: false,
        minimum: null,
        maximum: null,
        range_step: null,
        resample_function:
            variable.variable_type === ModelVariableType.Logical ||
            variable.variable_type === ModelVariableType.Comparison
                ? ModelVariableResampleFunction.last
                : null,
    };
    switch (variable.variable_type) {
        case ModelVariableType["Independent"]:
        case ModelVariableType["Iterative"]:
        case ModelVariableType.Aggregate:
        case ModelVariableType.Algebraic:
        case ModelVariableType.Conditional:
        case ModelVariableType.Logical:
        case ModelVariableType.Comparison:
        default:
            return {
                ...variable,
                ...baseProperties,
            } as ModelVariable;
    }
};

export const getVariableRelationshipOperationKeySubset = (
    targetVariableType: ModelVariableType,
) => {
    switch (targetVariableType) {
        case ModelVariableType["Iterative"]:
        case ModelVariableType.Algebraic:
            return ["Add", "Subtract", "Multiply", "Divide", "Exponentiate"];
        case ModelVariableType.Logical:
            return [
                "Logical And",
                "Logical Or",
                "Logical And Not",
                "Logical Or Not",
            ];
        case ModelVariableType.Comparison:
            return [
                "Equal To",
                "Not Equal To",
                "Less Than",
                "Less Than Or Equal To",
                "Greater Than",
                "Greater Than Or Equal To",
            ];
        case ModelVariableType["Time Shift"]:
            return ["Shift Index", "Shift Input"];
        default:
            return [];
    }
};

const VariableRelationshipOperationDisplayObject: {
    [index in VariableRelationshipOperation]: string;
} = {
    // arithmetic
    [VariableRelationshipOperation["Add"]]: "\u002B",
    [VariableRelationshipOperation["Subtract"]]: "\u2212",
    [VariableRelationshipOperation["Multiply"]]: "\u00D7",
    [VariableRelationshipOperation["Divide"]]: "\u00F7",
    [VariableRelationshipOperation["Exponentiate"]]: "\u005E",
    // logical
    [VariableRelationshipOperation["Logical And"]]: "\u0026\u0026",
    [VariableRelationshipOperation["Logical Or"]]: "\u007C\u007C",
    [VariableRelationshipOperation["Logical Not"]]: "\u00AC",
    [VariableRelationshipOperation["Logical And Not"]]: "\u0026\u00AC",
    [VariableRelationshipOperation["Logical Or Not"]]: "\u007C\u00AC",
    // comparison
    [VariableRelationshipOperation["Equal To"]]: "\u003D",
    [VariableRelationshipOperation["Not Equal To"]]: "\u2260",
    [VariableRelationshipOperation["Less Than"]]: "\u003C",
    [VariableRelationshipOperation["Less Than Or Equal To"]]: "\u2264",
    [VariableRelationshipOperation["Greater Than"]]: "\u003E",
    [VariableRelationshipOperation["Greater Than Or Equal To"]]: "\u2265",
    // time shift
    [VariableRelationshipOperation["Shift Index"]]: "index",
    [VariableRelationshipOperation["Shift Input"]]: "input",
};

export const displayVariableRelationshipOperation = (
    variableRelationshipOperation: VariableRelationshipOperation,
): string => {
    return (
        VariableRelationshipOperationDisplayObject[
            variableRelationshipOperation
        ] || ""
    );
};

const ModelVariableAggregateOperationDisplayObject: {
    [index in ModelVariableAggregateOperation]: string;
} = {
    [ModelVariableAggregateOperation.Average]: "avg", // Ø (average)
    [ModelVariableAggregateOperation.Minimum]: "min", // min
    [ModelVariableAggregateOperation.Maximum]: "max", // max
    [ModelVariableAggregateOperation["Standard Deviation"]]: "\u03C3", // σ (standard deviation)
    [ModelVariableAggregateOperation.Count]: "#", // # (count)
    [ModelVariableAggregateOperation["Count If"]]: "#if", // #if (count if)
    [ModelVariableAggregateOperation["Cumulative Sum"]]: "\u2211", // ∑ (cumulative sum)
    [ModelVariableAggregateOperation["Cumulative Product"]]: "\u220F", // ∏ (cumulative product)
};

export const displayModelVariableAggregateOperation = (
    variableAggregateOperation: ModelVariableAggregateOperation,
): string => {
    return (
        ModelVariableAggregateOperationDisplayObject[
            variableAggregateOperation
        ] || ""
    );
};

export const getDownstreamVariableIds = (
    modelVariableId: string,
    variableRelationshipsBySourceId: Dictionary<VariableRelationship[]>,
): { [index: string]: boolean } => {
    const descendantVariableIds =
        !!variableRelationshipsBySourceId &&
        !!variableRelationshipsBySourceId[modelVariableId]
            ? variableRelationshipsBySourceId[modelVariableId].reduce(
                  (map, targetRelationship) => ({
                      ...map,
                      [targetRelationship.target_variable_id]: true,
                  }),
                  {},
              )
            : {};

    if (Object.keys(descendantVariableIds).length === 0) {
        return {};
    }

    let downstreamVariableIds = {};

    Object.keys(descendantVariableIds).forEach((descendantVariableId) => {
        const nextGenerationIds = getDownstreamVariableIds(
            descendantVariableId,
            variableRelationshipsBySourceId,
        );
        downstreamVariableIds = {
            ...downstreamVariableIds,
            ...nextGenerationIds,
        };
    });

    return { ...descendantVariableIds, ...downstreamVariableIds };
};

export const getUpstreamVariableIds = (
    modelVariableId: string,
    variableRelationshipsByTargetId: Dictionary<VariableRelationship[]>,
): { [index: string]: boolean } => {
    const ancestorVariableIds =
        !!variableRelationshipsByTargetId &&
        !!variableRelationshipsByTargetId[modelVariableId]
            ? variableRelationshipsByTargetId[modelVariableId].reduce(
                  (map, sourceRelationship) => ({
                      ...map,
                      [sourceRelationship.source_variable_id]: true,
                  }),
                  {},
              )
            : {};

    if (Object.keys(ancestorVariableIds).length === 0) {
        return {};
    }

    let upstreamVariableIds = {};

    Object.keys(ancestorVariableIds).forEach((ancestorVariableId) => {
        const nextGenerationIds = getUpstreamVariableIds(
            ancestorVariableId,
            variableRelationshipsByTargetId,
        );
        upstreamVariableIds = {
            ...upstreamVariableIds,
            ...nextGenerationIds,
        };
    });

    return { ...ancestorVariableIds, ...upstreamVariableIds };
};

// helper function to generate values
export const getNewVariableValue = (
    modelVariableId: string,
    timeHorizonId: string = null,
    timeIndex: number = null,
    numericalValue: number = 0,
    booleanValue: boolean = false,
) =>
    ({
        id: null,
        model_variable_id: modelVariableId,
        numerical_value: numericalValue,
        boolean_value: booleanValue,
        time_horizon_id: timeHorizonId,
        time_index: timeIndex,
    }) as VariableValue;

// helper function to generate relationships
export const getNewVariableRelationship = (
    targetVariableId: string,
    targetVariableType: ModelVariableType,
    relationshipCount: number,
) =>
    ({
        source_variable_id: null,
        target_variable_id: targetVariableId,
        source_time_horizon_id: null,
        target_time_horizon_id: null,
        operation_type:
            targetVariableType === ModelVariableType["Iterative"] ||
            targetVariableType === ModelVariableType.Algebraic
                ? VariableRelationshipOperation.Add
                : targetVariableType === ModelVariableType.Logical
                  ? VariableRelationshipOperation["Logical And"]
                  : targetVariableType === ModelVariableType.Comparison
                    ? VariableRelationshipOperation["Equal To"]
                    : targetVariableType === ModelVariableType["Time Shift"] &&
                        relationshipCount == 0
                      ? VariableRelationshipOperation["Shift Index"]
                      : targetVariableType ===
                              ModelVariableType["Time Shift"] &&
                          relationshipCount > 0
                        ? VariableRelationshipOperation["Shift Input"]
                        : null,
        weight: relationshipCount,
    }) as VariableRelationship;
