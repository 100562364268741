import { SapienInertia } from "@/inertia-utils/hooks";
import {
    ContentBlockShape,
    ContentBlockType,
    PromptShape,
    PromptType,
    SelectionShape,
} from "@/models";
import { atom, useAtom } from "jotai";
import { FormEvent, useEffect, useMemo, useRef, useState } from "react";
import { useScorm } from "@/hooks/scorm-export/useScormApi";
import { useContentBlockState } from "../Design/useContentBlockState";
// import { useLessonScore } from "../LessonDisplay/useLessonScore";

export type Submission = Omit<
    SelectionShape,
    "simulation_id" | "team_id" | "user_id"
> & {
    prompt?: PromptShape;
};

const selectionAtom = atom<{ [index: string]: Submission[] }>({});

function mapSelections(selections: Submission[]) {
    return selections.reduce((carry, selection) => {
        if (!carry[selection.prompt_id]) {
            carry = { ...carry, [selection.prompt_id]: [] };
        }

        return {
            ...carry,
            [selection.prompt_id]: [...carry[selection.prompt_id], selection],
        };
    }, {});
}

export function useSetupSelections(selections: Submission[]) {
    const [_, setSelections] = useAtom(selectionAtom);
    useEffectArray<Submission>(() => {
        const mapped = mapSelections(selections);
        setSelections(mapped);
    }, selections);
}

export function useSelections(promptId: string) {
    const [selections] = useAtom(selectionAtom);

    return selections[promptId] || [];
}

export function useAreAllScoredPromptsComplete() {
    const [selections] = useAtom(selectionAtom);
    const { contentBlocks } = useContentBlockState();

    const scoredPrompts = useMemo(() => {

        return Object.values(contentBlocks).reduce((carry, block) => {
            if (
                block.prompt?.options?.some(
                    (option) => option.selected_numerical_value > 0,
                )
            ) {
                carry = [...carry, block.prompt];
            }

            return carry;
        }, []);
    }, [contentBlocks, selections]);


    return useMemo(() => {
        if(scoredPrompts.length === 0) return false;
        return scoredPrompts.every((prompt) => {
            return selections[prompt.id]?.length > 0;
        });
    }, [scoredPrompts]);


}

export function selectionsFromEvent(
    e: FormEvent,
    contentBlock: ContentBlockShape,
) {
    e.preventDefault();
    const formData = new FormData(e.currentTarget as HTMLFormElement);
    const formEntries = Object.fromEntries(formData.entries()) as {
        [index: string]: string | number;
    };

    const prompt = contentBlock?.contentBlocks.find(
        (block) => block.content_block_type === ContentBlockType.Question,
    )?.prompt;

    const selections: Submission[] = prompt.options.map((option) => {
        let value: string | number = formEntries[option.id];

        if (prompt.prompt_type === PromptType["Multiple Choice"]) {
            value = Object.values(formEntries).find((v) => v === option.id);
        }

        return {
            prompt_id: prompt?.id,
            option_id: option.id,
            numerical_value: Number(value) || 0,
            string_value:
                typeof value === "string"
                    ? value
                    : value?.toString() || option.content,
            is_selected: !!value,
            prompt_text: prompt.content,
            option_text: option.content,
        };
    });

    return selections;
}

const scormQuestionTypes = {
    [PromptType["Multiple Choice"]]: "choice", // Multiple Choice
    [PromptType["Short Text"]]: "fill-in", // Fill in the Blank
    [PromptType["Long Text"]]: "long-fill-in", // Long Fill in the Blank (extended text)
    // "matching",     // Matching
    // "performance",  // Performance Task
    // "likert",       // Likert Scale (opinion scale)
    [PromptType["Numerical Input"]]: "numeric", // Numeric Response
    [PromptType["Numerical Slider"]]: "numeric", // Numeric Response
    [PromptType["Rank Order"]]: "sequencing", // Sequencing
};

export function useSubmitSelections(isInAdminContext: boolean) {
    // const [_, setSelections] = useAtom(selectionAtom);
    const { scorm } = useScorm();

    const [isPending, setIsPending] = useState(false);
    const save = (selections: Submission[]) => {
        setIsPending(true);
        if (isInAdminContext) {
            setIsPending(false);
            return Promise.resolve({
                selections,
                required_score: 0,
                current_score: 0,
            });
        }

        const selection = selections[0];
        scorm?.submit({
            questionId: selection.prompt_id,
            questionType:
                scormQuestionTypes[selection.prompt?.prompt_type] || "choice",
            learnerResponse: selections
                .map((selection) => selection.option_text)
                .join(", "),
        });

        const endpoint = !!scorm?.isAvailable()
            ? "e-learning.participant.api.submit"
            : "e-learning.participant.submit";
        SapienInertia.post(
            endpoint,
            { selections },
            {},
            {
                only: ["selections", "required_score", "current_score", "blockedLessonIds"],
                preserveScroll: true,
                onSuccess() {
                    setIsPending(false);
                },
            },
        );
    };

    return { save, isPending };
}

function arrayEqual(a1: any[], a2: any[]) {
    if (a1.length !== a2.length) return false;
    for (let i = 0; i < a1.length; i++) {
        if (a1[i] !== a2[i]) {
            return false;
        }
    }
    return true;
}

type MaybeCleanUpFn = void | (() => void);

function useEffectArray<T>(cb: () => MaybeCleanUpFn, deps: T[]) {
    const ref = useRef<T[]>(deps);

    if (!arrayEqual(deps, ref.current)) {
        ref.current = deps;
    }

    useEffect(cb, [ref.current]);
}
