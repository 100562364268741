import React from "react";
import { createPortal } from "react-dom";
import { ErrorBoundary } from "react-error-boundary";
import { MouseOverWrapper } from "@/modules/cursor/Cursor";
import EditorLineageBreadcrumbs from "./EditorLineageBreadcrumbs";
import { NestedContentBlockDisplay } from "./blocks";
import { ContentBlockShape, DisplayRule } from "@/models";
import { useDisplayRules } from "@/hooks/useDisplayRules";
import MobileWrapper from "./mobile-wrapper/MobileWrapper";
import { useMobileViewState } from "./mobile-wrapper/useMobileViewState";
import { WidthSideNav } from "@/Pages/Participant/SimulationDisplay/WithSideNav";
import { useAuthStore } from "@/hooks/store";
import ContentBlockOutline from "./blocks/content-block-selection/ContentBlockOutline";
import ManualTableEntry from "./charts-and-tables/tables/ManualTableEntry";
import {
    useGetActiveContentBlock,
} from "@/hooks/activeContentBlock";
import { useQueryErrorResetBoundary } from "@tanstack/react-query";
import { TextEditorWrapper } from "./TextEditorWrapper";
import { useMovedContentBlock } from "@/modules/moved-content-block/useMovedContentBlock";
import { HandRaisedIcon } from "@heroicons/react/24/solid";
import { useAddContentBlockState } from "./content-block-buttons/useAddContentBlockState";
import { useGetElementAsync } from "@/hooks/useGetElementAsync";
import { QuestionStyle } from "@/Pages/DesignStudio/Design/content-block-editing/questions/QuestionStyle";
import { ChildBlockStyles } from "@/Pages/DesignStudio/Design/content-block-editing/child-block-styles/ChildBlockStyles";
import { ImageHandler } from "@/Pages/DesignStudio/Design/content-block-editing/ImageHandler";
import {
    useContentBlockState,
    useGetContentBlockWithChildren,
} from "@/Pages/DesignStudio/Design/useContentBlockState";

function getIndexInParent(child: ContentBlockShape, parent: ContentBlockShape) {
    if (child && parent && parent.contentBlocks)
        return parent.contentBlocks.findIndex(
            (contentBlock) => contentBlock.id === child.id,
        );

    return -1;
}

function getSiblingCount(parent: ContentBlockShape) {
    return parent?.contentBlocks?.length || 0;
}

type Props = {
    roundId: string;
    simulationId?: string;
    editorSizes: {
        toolbar: string;
        leftMenu: string;
        rightMenu: string;
    };
    toggleLeftMenu: () => void;
    sapienErrors?: { [index: string]: any };
};

export default function EditorCenterColumn({
    roundId,
    editorSizes,
    simulationId,
    sapienErrors,
}: Props) {
    const { reset } = useQueryErrorResetBoundary();
    const { addContentBlockState } = useAddContentBlockState();
    const { parentContentBlocks } = useContentBlockState();

    const { data: activeContentBlock } = useGetActiveContentBlock();
    const { movedContentBlock, setMovedContentBlock } = useMovedContentBlock();

    const { device } = useMobileViewState();

    const { auth, tenant } = useAuthStore();
    const { displayRulesByContentBlockId, hiddenContentBlockIds } =
        useDisplayRules();

    if (!auth?.user) return <></>;

    return (
        <MouseOverWrapper
            className="EDITOR-CENTER-PANEL w-full"
            icon={
                !!addContentBlockState.addableContentBlockMetaData ? (
                    addContentBlockState.addableContentBlockMetaData?.icon
                ) : !!movedContentBlock ? (
                    <HandRaisedIcon className="h-16 w-16" />
                ) : null
            }
            isForbidden={false}
            additionalClassNameToWatch={device ? ".mobile-wrapper" : undefined}
        >
            <>
                <div
                    className={`z-[9999] w-full bg-black h-[${editorSizes.toolbar}]`}
                >
                    <div data-menu-bar-compact={true} />
                </div>
                <div
                    className="scroll-child flex flex-col items-center"
                    style={{
                        height: `calc(100vh - 108px)`,
                        minHeight: `calc(100vh - 108px)`,
                    }}
                >
                    <MobileWrapper unavailableHeightPx={72}>
                        <WidthSideNav
                            auth={auth}
                            isInEditorContext={true}
                            tenant={tenant}
                        >
                            <>
                                {!!movedContentBlock && (
                                    <div className="absolute left-2 top-2 z-[99999] cursor-pointer">
                                        <button
                                            className="cursor-pointer rounded-md bg-white/70 px-2 py-1 text-red-900 transition-colors
                                                hover:bg-red-500 hover:text-white"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                setMovedContentBlock(false);
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                )}
                                {parentContentBlocks?.map((contentBlock, i) => {
                                    return (
                                        <NestedWrapper
                                            key={contentBlock.id}
                                            contentBlock={contentBlock}
                                            parentContentBlocks={
                                                parentContentBlocks
                                            }
                                            index={i}
                                            displayRulesByContentBlockId={
                                                displayRulesByContentBlockId
                                            }
                                            editorSizes={editorSizes}
                                            hiddenContentBlockIds={
                                                hiddenContentBlockIds
                                            }
                                        />
                                    );
                                })}
                            </>
                        </WidthSideNav>
                    </MobileWrapper>
                </div>
                {!!sapienErrors && !!Object.keys(sapienErrors).length && (
                    <div className="fixed right-0 top-2 z-100 flex items-center">
                        <div
                            className="max-w-sm space-y-2 rounded-md bg-pink-200 p-6 text-black shadow-lg
                                shadow-red-900"
                        >
                            {Object.keys(sapienErrors).map((key) => (
                                <div
                                    className="flex w-full flex-col justify-between space-y-2"
                                    key={key}
                                >
                                    <div className="text-bold flex">{key}</div>
                                    <div className="flex text-red-700">
                                        {sapienErrors[key]}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                <div
                    className={`sticky bottom-0 w-full h-[${editorSizes.toolbar}]`}
                >
                    <EditorLineageBreadcrumbs />
                </div>

                <TextEditorWrapper />

                {!!activeContentBlock && (
                    <>
                    
                    <ActiveContentBlock
                        contentBlock={activeContentBlock}
                        editorSizes={editorSizes}
                    />
                    </>
                )}

                <QuestionStyle />
                <ChildBlockStyles />
                <ImageHandler />
            </>
        </MouseOverWrapper>
    );
}

function ActiveContentBlock({
    contentBlock,
    editorSizes,
}: {
    contentBlock: ContentBlockShape;
    editorSizes: {
        toolbar: string;
        leftMenu: string;
        rightMenu: string;
    };
}) {

    const { reset } = useQueryErrorResetBoundary();
    const activeContentBlock = useGetContentBlockWithChildren(contentBlock.id);
    const activeContentBlockEl = useGetElementAsync(
        `[data-content-block-id="${activeContentBlock?.id}"]`,
    );


    const parentContentBlock = useGetContentBlockWithChildren(
        activeContentBlock?.parent_content_block_id,
    );


    return !!activeContentBlockEl
        ? createPortal(
              <ErrorBoundary
                  fallbackRender={({ resetErrorBoundary }) => (
                      <div>
                          There was an error!
                          <button
                              className="2bluefocus:ring-2 inline-flex items-center rounded bg-blue-600 px-4 py-2 text-sm
                                font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none
                                focus:ring-blue-500 focus:ring-offset-2"
                              onClick={() => resetErrorBoundary()}
                          >
                              Try again
                          </button>
                      </div>
                  )}
                  onError={() => {
                      reset();
                  }}
                  onReset={() => {
                      reset();
                  }}
              >
                  <React.Suspense
                      fallback={
                          <div className="absolute popover-fallback inset-0 flex items-center justify-center bg-blue-400/20">
                              <div className="h-32 w-32 animate-spin rounded-full border-b-2 border-gray-900">

                              </div>
                          </div>
                      }
                  >
                      <ContentBlockOutline
                          contentBlock={activeContentBlock}
                          siblingIndex={getIndexInParent(
                              activeContentBlock,
                              parentContentBlock,
                          )}
                          siblingCount={getSiblingCount(parentContentBlock)}
                          parentType={parentContentBlock?.content_block_type}
                          editorSizes={editorSizes}
                      />
                  </React.Suspense>
              </ErrorBoundary>,
              activeContentBlockEl,
          )
        : null;
}

function NestedWrapper({
    displayRulesByContentBlockId,
    editorSizes,
    hiddenContentBlockIds,
    index,
    contentBlock,
    parentContentBlocks,
}: {
    displayRulesByContentBlockId: { [index: string]: DisplayRule };
    editorSizes: {
        toolbar: string;
        leftMenu: string;
        rightMenu: string;
    };
    hiddenContentBlockIds: string[];
    index: number;
    contentBlock: ContentBlockShape;
    parentContentBlocks: ContentBlockShape[];
}) {
    const nestedContentBlock = useGetContentBlockWithChildren(contentBlock.id);

    return (
        <>
            <NestedContentBlockDisplay
                key={contentBlock.id}
                contentBlockId={contentBlock.id}
                contentBlockNested={nestedContentBlock}
                siblingCount={parentContentBlocks.length}
                siblingIndex={index}
                displayRules={displayRulesByContentBlockId}
                editorSizes={editorSizes}
                hiddenContentBlockIds={hiddenContentBlockIds}
                depth={0}
            />
        </>
    );
}
