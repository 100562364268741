import React, { useCallback, useState } from "react";
import { useGetElementAsync } from "@/hooks/useGetElementAsync";
import { ContentBlockShape } from "@/models";
import { createPortal } from "react-dom";
import { useEditFunctions } from "./editFunctions";
import { PencilIcon, PlusIcon, TrashIcon, X } from "lucide-react";
import DeleteContentBlockOverlay from "./DeleteContentBlockOverlay";
import ReorderButtons from "./ReorderButtons";
import AddContentBlockWidget from "../AddContentBlockWidget";
import { useIsAddingContentBlock } from "./useIsAddingContentBlock";
import ThemeDropdown from "./theme-dropdowns/ThemeDropdown";
import StyleDropdown from "./style-dropdowns/StyleDropdown";
import { useGetContentBlockWithChildren } from "../useContentBlockState";
import SimulationTools from "../SimulationTools";
import DisplayRuleModal from "../modals/display-rule-modal/DisplayRuleModal";
// import UpdateContentBlockOverlay from "./UpdateContentBlockOverlay";

type Props = {
    parentBlock: ContentBlockShape;
    numberOfBlocks: number;
    index: number;
    isSimulation: boolean;
};

export default function ContentBlockEditor({
    parentBlock,
    index,
    numberOfBlocks,
    isSimulation,
}: Props) {
    const { isAddingContentBlock, setIsAddingBlock, isAddingAnyContentBlock } =
        useIsAddingContentBlock(parentBlock.id);
    const parentBlockWithChildren = useGetContentBlockWithChildren(
        parentBlock.id,
    );
    const contentBlockElement = useGetElementAsync(
        `[data-design-studio-content-block-id="${parentBlock.id}"]`,
    );
    const addContentBlockElement = useGetElementAsync(
        `[data-design-studio-add-block-id="${parentBlock.id}"]`,
    );
    const { editFunction } = useEditFunctions(parentBlockWithChildren);

    const handleEdit = useCallback(() => {
        if ("function" === typeof editFunction) {
            editFunction(parentBlockWithChildren);
        }
    }, [parentBlockWithChildren, editFunction]);

    const [isDeleting, setIsDeleting] = useState(false);
    if (!contentBlockElement || !addContentBlockElement) {
        return null;
    }



    return (
        <>
            {createPortal(
                <>
                    <div
                        data-shown-state={
                            isAddingContentBlock ? "shown" : "hidden"
                        }
                        className="flex w-full items-center justify-center transition-all duration-300
                            data-[shown-state='hidden']:h-0 data-[shown-state='shown']:h-32
                            data-[shown-state='shown']:border-b-2 data-[shown-state='shown']:border-t-2
                            data-[shown-state='hidden']:border-none data-[shown-state='hidden']:py-0
                            data-[shown-state='shown']:py-2"
                    >
                        <div
                            data-shown-state={
                                isAddingContentBlock ? "shown" : "hidden"
                            }
                            className="bg-blue-white absolute inset-0 z-1 w-full data-[shown-state='hidden']:h-0
                                data-[shown-state='shown']:h-32"
                        ></div>
                        <div
                            data-shown-state={
                                isAddingContentBlock ? "shown" : "hidden"
                            }
                            className="z-2 flex w-full items-center justify-center transition-all delay-300
                                data-[shown-state='hidden']:opacity-0 data-[shown-state='shown']:opacity-100"
                        >
                            {!!isAddingContentBlock && (
                                <div className="flex w-full items-center justify-center space-x-2 bg-slate-100">
                                    <AddContentBlockWidget
                                        roundId={parentBlock.round_id}
                                        hasBlocks={true}
                                        index={index - 1}
                                    />
                                    <button
                                        className="flex h-[5.25rem] flex-col items-center justify-center rounded-md border
                                            border-dashed border-red-500 p-2 px-4 text-red-500 transition-colors
                                            hover:bg-red-500 hover:text-white"
                                        onClick={() => {
                                            setIsAddingBlock(false);
                                        }}
                                    >
                                        <X className="h-6 w-6" />
                                        Cancel
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </>,
                addContentBlockElement,
            )}
            {createPortal(
                <>
                    {!isAddingAnyContentBlock && (
                        <div
                            className="content-block-buttons add-block-buttons-top absolute top-0 z-[9999] flex h-12
                                w-full items-center justify-center px-4 transition-all delay-200
                                -translate-y-1/2"
                        >
                            <button
                                className={`rounded-full bg-sapien-blue p-2 text-white ${index === 0 ? "mt-12" : "mt-0"}`}
                                onClick={() => setIsAddingBlock(parentBlock.id)}
                            >
                                <PlusIcon />
                            </button>
                        </div>
                    )}
                    <div
                        className="content-block-buttons sticky top-0 flex h-12 w-full items-center justify-between
                            px-4 transition-all delay-200 z-100"
                    >
                        <div className="flex space-x-2">
                            <ThemeDropdown
                                contentBlock={parentBlockWithChildren}
                            />
                            <div className="flex rounded-md bg-white p-1 shadow-lg">
                                {!!editFunction && (
                                    <button
                                        className="rounded-md p-2 hover:bg-slate-200"
                                        onClick={handleEdit}
                                        data-testid={`edit-${parentBlock.content_block_type}-${parentBlock.weight}`}
                                    >
                                        <PencilIcon className="h-4 w-4" />
                                    </button>
                                )}
                                <StyleDropdown
                                    contentBlock={parentBlockWithChildren}
                                />
                            </div>
                        </div>
                        <div className="flex space-x-2">
                            <div className="flex rounded-md bg-white p-1 shadow-lg">
                                <button
                                    className="rounded-md p-2 hover:bg-red-200"
                                    onClick={() => setIsDeleting(true)}
                                >
                                    <TrashIcon className="h-4 w-4" />
                                </button>
                                <ReorderButtons
                                    index={index}
                                    numberOfBlocks={numberOfBlocks}
                                    contentBlockId={parentBlock.id}
                                />
                                {isSimulation && (
                                    <SimulationTools contentBlock={parentBlockWithChildren} />
                                )}
                                {isSimulation && (
                                    <DisplayRuleModal
                                        contentBlock={parentBlockWithChildren}
                                    />
                            )}
                            </div>
                        </div>
                    </div>
                    {isDeleting && (
                        <DeleteContentBlockOverlay
                            contentBlock={parentBlock}
                            cancelDelete={() => setIsDeleting(false)}
                        />
                    )}
                    {/* <UpdateContentBlockOverlay
                        contentBlockId={parentBlock.id}
                    /> */}
                </>,
                contentBlockElement,
            )}
        </>
    );
}
