import React, { useMemo } from "react";
import { useFindContentBlockMethods, useModalQueryParams } from "@/hooks";
import { ContentBlockShape } from "@/models/ContentBlock";
import { PlusIcon } from "@heroicons/react/24/solid";
import { ContentBlockType } from "@/models";
import { useGetActiveContentBlock } from "@/hooks/activeContentBlock";
import { useGetElementAsync } from "@/hooks/useGetElementAsync";

type AddSiblingProps = {
    contentBlock: ContentBlockShape;
};

export function SmallAddSiblingButton({ contentBlock }: AddSiblingProps) {
    const { data: activeContentBlock } = useGetActiveContentBlock();
    const { getContentBlock } = useFindContentBlockMethods();
    const element = useGetElementAsync(`[data-content-block-id="${contentBlock.id}"]`);
    const positionClass = useMemo(() => {
        if (activeContentBlock) {
            const parent = getContentBlock(
                activeContentBlock.parent_content_block_id
            );
            if (parent?.content_block_type === ContentBlockType.Page) {
                return "right- bottom-1 -mb-4 mr-0";
            }
        }

        if(element?.getBoundingClientRect()?.width < 100) return "right-0 bottom-0 translate-x-full translate-y-full";

        return "right-0 bottom-0 -mr-4 -mb-4";
    }, [activeContentBlock, getContentBlock,element]);

    const { openModal } = useModalQueryParams();
    if(!element) return null;
    return (
        <>
            <div
                className={`absolute z-100 pointer-events-auto opacity-50 hover:opacity-100 transition-opacity ${positionClass}`}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                }}
            >
                {/* {!addSiblingBlockData && ( */}
                <button
                    onClick={() => {
                        openModal({
                            modalComponent: "AddContentBlockModal",
                            contentBlockId: contentBlock.id,
                            position: "after",
                        });
                    }}
                    data-testid="add-sibling-button"
                    className="btn inline-flex p-2 items-center text-white justify-start overflow-hidden transition-all cursor-pointer text-xs rounded-full bg-gradient-to-b from-blue-500 to-blue-700 border border-white shadow-sm"
                >
                    <span className="sr-only">Add Content Block</span>
                    <PlusIcon className="h-4 w-4" />
                </button>
                {/* )} */}
            </div>
        </>
    );
}
