import React, { memo } from "react";
import NestedContentBlockDisplay from "./NestedContentBlockDisplay";
import { useTrackParentContentBlocks } from "./viewed-content-block-tracking/useTrackParentContentBlocks";
import { useContentBlockState } from "../Design/useContentBlockState";

type Props = {
    scrollCallback?: (contentBlockId: string) => void;
    lesson: App.Data.RoundOrLessonData;
    lessonCount: number;
    isInAdminContext: boolean;
    // children?: JSX.Element
};

const Display = memo(function Display({
    lesson,
    scrollCallback,
    lessonCount,
    isInAdminContext,
    // children
}: Props) {
    const { parentContentBlocks, } = useContentBlockState();

    const {} = useTrackParentContentBlocks(200, scrollCallback);

    return (
        <div className="flex w-full flex-col items-center justify-center">
            {parentContentBlocks?.map((block) => (
                <NestedContentBlockDisplay
                    key={block.id}
                    contentBlock={block}
                    scrollCallback={scrollCallback}
                    isInAdminContext={isInAdminContext}
                />
            ))}
            {/* {children} */}
        </div>
    );
});

export default Display;
