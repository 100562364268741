import React, { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { useQueryErrorResetBoundary } from "@tanstack/react-query";
import { ErrorBoundary } from "react-error-boundary";
import "react-loading-skeleton/dist/skeleton.css";
import {
    QuestionConfigObject,
    useModalQueryParams,
    useQuestionEditor,
} from "@/hooks";
import { Prompt, PromptType } from "@/models";
import { ModalContainer } from "@/modules/shared";
import { H6Span } from "@/components";
import {
    EditorFormLabel,
    EditorFormOption,
} from "@/styles/editor-components/StyledEditorComponents";
import QuestionBank from "./QuestionBank";
import QuestionOriginSelector from "./QuestionOriginSelector";
import QuestionCreationSteps from "./QuestionCreationSteps";
import PromptTypeTag from "./PromptTypeTag";
import QuestionModalFooter from "./QuestionModalFooter";
import { OptionList } from "./OptionList";
import { usePage } from "@inertiajs/react";
import { SapienAdminPageProps } from "@/inertia-utils/types";
import { useGetAllPromptsByContentBlockId } from "./question-queries/useGetAllPromptsByContentBlockId";

const shouldShowOptions = (promptType: PromptType) => {
    return (
        promptType !== PromptType["Numerical Input"] &&
        promptType !== PromptType["Numerical Slider"] &&
        promptType !== PromptType["Long Text"] &&
        promptType !== PromptType["Short Text"]
    );
};

function QuestionModalForm() {
    const { queryParams, closeModal } = useModalQueryParams("QuestionModal");
    const { contentBlockId, promptType } = queryParams;

    const {
        data: { allPrompts },
        isLoading,
    } = useGetAllPromptsByContentBlockId(contentBlockId);

    const serverPrompt = useMemo(() => {
        const prompt = allPrompts?.find(
            (prompt) => prompt.content_block_id === contentBlockId,
        );
        return prompt;
    }, [contentBlockId, isLoading, allPrompts]);

    const { rounds, errors: modalPageErrors } =
        usePage<SapienAdminPageProps>().props;

    let {
        prompt,
        setPrompt,
        questionConfig,
        sortedOptionIds,
        setSortedOptionIds,
        optionMap,
        savePrompt,
        setOption,
        addOption,
        onSelectPromptFromBank,
        preparePromptForSave,
        deletedOptionIds,
        markOptionDeletionState,
    } = useQuestionEditor(
        contentBlockId,
        !!serverPrompt?.id ? serverPrompt : undefined,
        promptType,
    );

    const [isBusy, setIsBusy] = useState(false);

    const scopes = ["Participant", "Team"];

    //for QuestionOriginSelector
    const [isFromScratch, setIsFromScratch] = useState(true);

    const steps = [1, 2];

    const [currentStep, setCurrentStep] = useState(1);

    const isLastStep = !!prompt?.id || currentStep === steps[steps.length - 1];

    const isFirstStep = currentStep === steps[0];

    const [selectedQuestionBankPrompt, setSelectedQuestionBankPrompt] =
        useState<Prompt | null>(null);

    const isRightButtonDisabled =
        !isFromScratch &&
        (!selectedQuestionBankPrompt ||
            selectedQuestionBankPrompt.prompt_type !== prompt.prompt_type);

    const rightButtonHandler = useCallback(() => {
        if (isLastStep) {
            setIsBusy(true);
            savePrompt(preparePromptForSave());
        } else {
            //if not from scratch
            if (!isFromScratch && !!selectedQuestionBankPrompt) {
                onSelectPromptFromBank(selectedQuestionBankPrompt);
            }

            setCurrentStep((previousStep) => previousStep + 1);
            if (
                isFromScratch &&
                !QuestionConfigObject[prompt.prompt_type]
                    .requiresManualOptionCreation &&
                ((QuestionConfigObject[prompt.prompt_type]
                    .canSpecifyOptionCountOnCreation &&
                    !optionMap) ||
                    !Object.keys(optionMap).length)
            ) {
                addOption();
            }
        }
    }, [
        isLastStep,
        savePrompt,
        selectedQuestionBankPrompt,
        isFromScratch,
        prompt,
        optionMap,
    ]);

    //hide left cancel button in first step when question is new (no id)
    const leftButtonHandler = useCallback(() => {
        if (isFirstStep) {
            //cancel
            setIsBusy(true);
            closeModal();
        } else {
            setCurrentStep((previousStep) => previousStep - 1);
        }
    }, [isFirstStep, closeModal]);

    useEffect(() => {
        setIsBusy(false);
    }, [prompt, modalPageErrors]);

    if (!prompt) return <></>;
    return (
        <div
            className={`py-6 text-white ${isBusy ? "animate-pulse" : ""}`}
            data-testid="question-modal-form"
        >
            <div
                className={`flex px-6 ${!prompt.id ? "justify-between" : "justify-end"}`}
            >
                {!prompt.id && (
                    <QuestionCreationSteps
                        numberOfSteps={steps.length}
                        currentStep={currentStep}
                    />
                )}
                <PromptTypeTag promptType={prompt.prompt_type} />
            </div>

            <div className="flex flex-row items-center justify-between p-6 text-white">
                <H6Span>{!!prompt?.id ? "Edit " : "Create "} Question</H6Span>
            </div>

            {currentStep === 1 && !prompt.id && (
                <QuestionOriginSelector
                    isNew={isFromScratch}
                    setIsNew={setIsFromScratch}
                />
            )}

            {currentStep === 1 && !prompt.id && (
                <div className="m-0 flex items-center justify-between border-t border-[#374151] p-6">
                    <EditorFormLabel>{`Question Type`}</EditorFormLabel>
                    <select
                        value={prompt.prompt_type}
                        onChange={(e) =>
                            setPrompt({
                                ...prompt,
                                prompt_type: e.target.value as PromptType,
                            } as Prompt)
                        }
                        disabled={!!prompt.id}
                        className="block w-full max-w-xl rounded-md border-gray-300 bg-white/10 text-gray-200
                            focus:text-white focus:outline-offset-0 focus:outline-[#1d4ed8] sm:text-sm"
                    >
                        {Object.keys(PromptType)
                            .filter(
                                (key) =>
                                    PromptType[key] !== PromptType.Timeline,
                            )
                            .map((key) => (
                                <EditorFormOption
                                    className="bg-[#29303e]"
                                    key={key}
                                    value={PromptType[key]}
                                >
                                    {key}
                                </EditorFormOption>
                            ))}
                    </select>
                </div>
            )}

            {!!allPrompts &&
                currentStep === 1 &&
                !isFromScratch &&
                !prompt?.id && (
                    <QuestionBank
                        prompts={allPrompts}
                        onSelectPrompt={setSelectedQuestionBankPrompt}
                        selectedPrompt={selectedQuestionBankPrompt}
                        promptType={prompt.prompt_type}
                        questionConfig={questionConfig}
                        rounds={rounds}
                    />
                )}
            {isLastStep && (
                <form className="flex flex-col space-y-4 border-t border-[#374151] p-6 text-white">
                    <div className="flex justify-between">
                        <label>Scope</label>
                        <select
                            value={prompt.scope}
                            onChange={(e) =>
                                setPrompt({
                                    ...prompt,
                                    scope: e.target.value,
                                } as Prompt)
                            }
                            className="block w-full max-w-3xl rounded-md border-gray-300 bg-white/10 text-gray-200
                                focus:text-white focus:outline-offset-0 focus:outline-[#1d4ed8] sm:text-sm"
                        >
                            {scopes.map((scope) => (
                                <option
                                    className="bg-[#29303e]"
                                    key={scope}
                                    value={scope}
                                >
                                    {scope}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="flex justify-between">
                        <label>Prompt</label>
                        <div className="w-full max-w-3xl">
                            <textarea
                                value={prompt.content}
                                onChange={(e) => {
                                    setPrompt({
                                        ...prompt,
                                        content: e.target.value,
                                    } as Prompt);
                                }}
                                className={`block w-full max-w-3xl border-gray-300 bg-white/10 text-gray-200
                                focus:text-white focus:outline-offset-0 focus:outline-[#1d4ed8] sm:text-sm ${
                                    modalPageErrors?.content
                                        ? " rounded-t-md border-pink-500"
                                        : "rounded-md"
                                }`}
                            ></textarea>
                            {!!modalPageErrors?.content && (
                                <p className="rounded-b-md bg-pink-600 p-2 text-xs">
                                    {modalPageErrors?.content}
                                </p>
                            )}
                        </div>
                    </div>

                    {questionConfig?.requiresNumericalData && (
                        <div className="flex flex-col space-y-4">
                            <div className="flex justify-between">
                                <label>Minimum</label>
                                <div className="block w-full max-w-3xl">
                                    <input
                                        type="number"
                                        className={`text-gray-200" block w-full max-w-3xl border-gray-300 bg-white/10
                                        focus:text-white focus:outline-offset-0 focus:outline-[#1d4ed8] sm:text-xs ${
                                            modalPageErrors?.min
                                                ? " rounded-t-md border-pink-500"
                                                : "rounded-md"
                                        }`}
                                        value={prompt.min}
                                        onChange={(e) => {
                                            setPrompt({
                                                ...prompt,
                                                min: Number(e.target.value),
                                            } as Prompt);
                                        }}
                                    />
                                    {!!modalPageErrors?.min && (
                                        <p className="rounded-b-md bg-pink-600 p-2 text-xs">
                                            {modalPageErrors?.min}
                                        </p>
                                    )}
                                </div>
                            </div>

                            <div className="flex justify-between">
                                <label>Maximum</label>
                                <div className="block w-full max-w-3xl">
                                    <input
                                        type="number"
                                        className={`text-gray-200" block w-full max-w-3xl border-gray-300 bg-white/10
                                        focus:text-white focus:outline-offset-0 focus:outline-[#1d4ed8] sm:text-sm ${
                                            modalPageErrors?.max
                                                ? " rounded-t-md border-pink-500"
                                                : "rounded-md"
                                        }`}
                                        value={prompt.max}
                                        onChange={(e) => {
                                            setPrompt({
                                                ...prompt,
                                                max: Number(e.target.value),
                                            } as Prompt);
                                        }}
                                    />
                                    {!!modalPageErrors?.max && (
                                        <p className="rounded-b-md bg-pink-600 p-2 text-xs">
                                            {modalPageErrors?.max}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div className="flex justify-between">
                                <label>Increment</label>
                                <div className="block w-full max-w-3xl">
                                    <input
                                        type="number"
                                        className={`text-gray-200" block w-full max-w-3xl border-gray-300 bg-white/10
                                        focus:text-white focus:outline-offset-0 focus:outline-[#1d4ed8] sm:text-sm ${
                                            modalPageErrors?.increment
                                                ? " rounded-t-md border-pink-500"
                                                : "rounded-md"
                                        }`}
                                        value={prompt?.increment}
                                        onChange={(e) => {
                                            setPrompt({
                                                ...prompt,
                                                increment: Number(
                                                    e.target.value,
                                                ),
                                            } as Prompt);
                                        }}
                                    />
                                    {!!modalPageErrors?.increment && (
                                        <p className="rounded-b-md bg-pink-600 p-2 text-xs">
                                            {modalPageErrors?.increment}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div className="flex justify-between">
                                <label>Default Value</label>
                                <div className="block w-full max-w-3xl">
                                    <input
                                        type="number"
                                        className={`text-gray-200" block w-full max-w-3xl border-gray-300 bg-white/10
                                        focus:text-white focus:outline-offset-0 focus:outline-[#1d4ed8] sm:text-sm ${
                                            modalPageErrors?.default_numeric_value
                                                ? " rounded-t-md border-pink-500"
                                                : "rounded-md"
                                        }`}
                                        value={prompt?.default_numeric_value}
                                        onChange={(e) => {
                                            setPrompt({
                                                ...prompt,
                                                default_numeric_value: Number(
                                                    e.target.value,
                                                ),
                                            } as Prompt);
                                        }}
                                    />
                                    {!!modalPageErrors?.default_numeric_value && (
                                        <p className="rounded-b-md bg-pink-600 p-2 text-xs">
                                            {modalPageErrors?.default_numeric_value}
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    {sortedOptionIds !== undefined &&
                        optionMap !== undefined &&
                        prompt.prompt_type !== PromptType.Timeline &&
                        shouldShowOptions(prompt.prompt_type) && (
                            <OptionList
                                optionMap={optionMap}
                                sortedOptionIds={sortedOptionIds}
                                setSortedOptionIds={setSortedOptionIds}
                                addOption={addOption}
                                setOption={setOption}
                                modalPageErrors={modalPageErrors}
                                markOptionDeletionState={
                                    markOptionDeletionState
                                }
                                deletedOptionIds={deletedOptionIds}
                            />
                        )}
                </form>
            )}
            <QuestionModalFooter
                isLastStep={isLastStep}
                isFirstStep={isFirstStep}
                rightButtonHandler={
                    !isRightButtonDisabled ? rightButtonHandler : null
                }
                leftButtonHandler={leftButtonHandler}
                hideLeftButton={!prompt.id && isFirstStep}
                isBusy={isBusy}
            />
        </div>
    );
}
function SkeletonWrapper() {
    return (
        <div className="flex animate-pulse flex-col gap-2 p-4 py-4">
            <div className="flex justify-between border-b py-8">
                <div className="h-4 w-64 rounded-md bg-white/80" />
                <div className="h-4 w-12 rounded-md bg-white/80" />
            </div>
            <div className="flex-col space-y-4 py-6">
                {[...Array(5).keys()].map((i) => (
                    <div className="flex gap-2" key={i}>
                        <div className="h-6 w-32 rounded-md bg-white/80"></div>
                        <div className="h-8 w-full rounded-md bg-white/80"></div>
                    </div>
                ))}
            </div>
            <div className="grid grid-cols-2 justify-between gap-2 border-t py-4">
                <div className="h-12 rounded-xl bg-white/80" />
                <div className="h-12 rounded-xl bg-white/80" />
            </div>
        </div>
    );
}
export default function QuestionModal() {
    const { isAtModalUrl: isOpen, closeModal } =
        useModalQueryParams("QuestionModal");

    const { reset } = useQueryErrorResetBoundary();

    return (
        <ModalContainer
            isModalOpen={isOpen}
            setIsModalOpen={() => closeModal()}
            size="l"
            backgroundColor="#111928"
        >
            {isOpen && (
                <ErrorBoundary
                    onReset={reset}
                    fallbackRender={({ resetErrorBoundary }) => (
                        <div>
                            There was an error!
                            <button
                                className="2bluefocus:ring-2 inline-flex items-center rounded bg-blue-600 px-4 py-2 text-sm
                                    font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none
                                    focus:ring-blue-500 focus:ring-offset-2"
                                onClick={() => resetErrorBoundary()}
                            >
                                Try again
                            </button>
                        </div>
                    )}
                >
                    <Suspense fallback={<SkeletonWrapper />}>
                        <QuestionModalForm />
                    </Suspense>
                </ErrorBoundary>
            )}
        </ModalContainer>
    );
}
