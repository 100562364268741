import { ContentBlockShape, ContentBlockType } from "@/models";
import { useDesignStudioSidebarState } from "./useDesignStudioSidebarState";
import { useMemo } from "react";
import { useEditedContentBlock } from "./useEditedContentBlock";
import { atom, useAtom } from "jotai";
import { useModalQueryParams } from "@/hooks";

const editedTypeAtom = atom<ContentBlockType | false>(false);
editedTypeAtom.debugLabel = "editedTypeAtom";
export function useEditedType() {
    const [editedContentBlockType, setEditedContentBlockType] =
        useAtom(editedTypeAtom);
    return {
        editedContentBlockType,
        setEditedContentBlockType,
    };
}

export function useEditFunctions(contentBlock: ContentBlockShape): {
    editFunction: (contentBlock: ContentBlockShape) => any | void | undefined;
    editedContentBlockType: ContentBlockType | false;
} {

    const { setIsOpen } = useDesignStudioSidebarState();
    const { setEditedContentBlockId } = useEditedContentBlock();

    const { editedContentBlockType, setEditedContentBlockType } =
        useEditedType();

    const { openModal } = useModalQueryParams();

    const editFunctionMap = {
        [ContentBlockType.Accordion]: (contentBlock: ContentBlockShape) => {
            setIsOpen(true);
            setEditedContentBlockId(contentBlock.id);
            setEditedContentBlockType(ContentBlockType.Accordion);
        },
        [ContentBlockType["Chart Container"]]: (
            contentBlock: ContentBlockShape,
        ) => {
            const chartBlock = contentBlock?.contentBlocks?.find(
                (child) =>
                    child.content_block_type ===
                    ContentBlockType["Chart Container"],
            );
            console.log("chartBlock", chartBlock,contentBlock);
            if (!chartBlock) return;
            setIsOpen(true);
            setEditedContentBlockId(contentBlock.id);
            setEditedContentBlockType(ContentBlockType["Chart Container"]);
        },
        [ContentBlockType["Flip Card"]]: (contentBlock: ContentBlockShape) => {
            const flipCardBlock = contentBlock?.contentBlocks?.find(
                (child) =>
                    child.content_block_type === ContentBlockType["Flip Card"],
            );
            if (!flipCardBlock) return;
            setIsOpen(true);
            setEditedContentBlockId(contentBlock.id);
            setEditedContentBlockType(ContentBlockType["Flip Card"]);
        },
        [ContentBlockType.Image]: (contentBlock: ContentBlockShape) => {
            setIsOpen(true);
            setEditedContentBlockId(contentBlock.id);
            setEditedContentBlockType(ContentBlockType.Image);
        },
        [ContentBlockType.Tabs]: (contentBlock: ContentBlockShape) => {
            setIsOpen(true);
            setEditedContentBlockId(contentBlock.id);
            setEditedContentBlockType(ContentBlockType.Accordion);
        },
        [ContentBlockType.Video]: (contentBlock: ContentBlockShape) => {
            const videoBlock = contentBlock?.contentBlocks?.find(
                (child) => child.content_block_type === ContentBlockType.Video,
            );
            if (!videoBlock) return;
            openModal({
                modalComponent: "VideoModal",
                videoContentBlockId: videoBlock.id,
            });
        },
        default: (contentBlock: ContentBlockShape) => {
            setEditedContentBlockId(contentBlock.id);
            setIsOpen(true);
            setEditedContentBlockType(contentBlock.content_block_type);
        },
    };

    function getEditFunction(contentBlock: ContentBlockShape) {
        const contentBlockType = contentBlock.content_block_type;
        if (!editFunctionMap[contentBlockType]) {
            for (let i = 0; i < contentBlock.contentBlocks?.length; i++) {
                const child = contentBlock.contentBlocks[i];
                return getEditFunction(child);
            }
        }

        return editFunctionMap[contentBlockType] || false;
    }

    const editFunction = useMemo(() => {
        if (!contentBlock) {
            return false;
            console.log("no block", contentBlock);
        }
        return getEditFunction(contentBlock);
    }, [contentBlock]);

    return {
        editFunction,
        editedContentBlockType,
    };
}
