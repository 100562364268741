import React, { useState } from "react";
import { 
    useModelBuilderVariableDepths,
    useModelVariableMap,
    useRelationshipsBySourceId,
} from "../atoms";

export const SubmodulePanel = ({}: {}) => {
    const variableDepths = useModelBuilderVariableDepths();
    const modelVariables = useModelVariableMap();
    const relationshipsBySourceId = useRelationshipsBySourceId();

    const [isExpanded, setIsExpanded] = useState(true);

    // const displayRuleMap = useMemo(() => {
    //     const allDisplayRules = rounds.reduce((displayRuleArray, round) => {
    //         if (!!round?.displayRules?.length) {
    //             return [...displayRuleArray, ...round.displayRules];
    //         } else {
    //             return displayRuleArray;
    //         }
    //     }, []);
    //     return groupBy(allDisplayRules, "model_variable_id");
    // }, [rounds]);

    return (
        <div>
            {/* <div className="mb-2 flex items-baseline justify-between">
                <div className="text-l font-bold">Variables</div>
            </div> */}
            {/* <div className="mb-2 flex items-baseline justify-end">
                <div className="text-xs mr-2">Display Rule Variables:</div>
                <div className="text-xs">
                    <span
                        className="mr-1 cursor-pointer hover:underline"
                        // onClick={() =>
                        //     !!expandMultipleModelBlockAccordionKeys &&
                        //     !!modelBlockArray?.length &&
                        //     expandMultipleModelBlockAccordionKeys(
                        //         modelBlockArray.map((block) => block.id)
                        //     )
                        // }
                    >
                        Show
                    </span>
                    <span
                        className="cursor-pointer hover:underline"
                        // onClick={() =>
                        //     !!collapseMultipleModelBlockAccordionKeys &&
                        //     collapseMultipleModelBlockAccordionKeys()
                        // }
                    >
                        Hide
                    </span>
                </div>
            </div> */}

            {!!modelVariables &&
                !!relationshipsBySourceId &&
                !!variableDepths &&
                variableDepths
                    .filter(
                        (variableDepthObject) =>
                            !!modelVariables[variableDepthObject.variable_id] &&
                            !relationshipsBySourceId[
                                variableDepthObject.variable_id
                            ]
                    )
                    .map((variableDepthObject) => (
                        <div
                            key={variableDepthObject.variable_id}
                            className={`text-white w-full cursor-default`}
                            onClick={() => setIsExpanded(true)}
                        >
                            <div
                                className={`p-2 my-1 ${
                                    isExpanded
                                        ? // "bg-[rgba(255,255,255,0.24)]"
                                          // : !isDisplayRuleVariable ?
                                          // "bg-[rgba(255,255,255,0.1)]"
                                          "bg-[rgba(255,255,255,0.03)]"
                                        : "bg-[rgba(255,255,255,0.03)]"
                                }
                                ${""
                                    // !!displayRuleMap &&
                                    // !displayRuleMap[
                                    //     variableDepthObject.variable_id
                                    // ]
                                    //     ? "font-medium"
                                    //     : "font-light"
                                }`}
                            >
                                <div className="flex items-center text-sm justify-between">
                                    {
                                        modelVariables[
                                            variableDepthObject.variable_id
                                        ].label
                                    }
                                </div>
                            </div>
                        </div>
                    ))}
        </div>
    );
};
