import React from "react";

export default function DesignStudioLeftNavigation({
    children,
    isOpen = false,
    setIsOpen,
}: {
    children: React.ReactNode;
    isOpen?: boolean;
    setIsOpen?: (isOpen: boolean) => void;
}) {
    return (
        <>
            <div
                className="fixed left-0 top-[129px] z-10 w-96 border-r border-slate-200 bg-white
                    transition-all duration-300 data-[isopen=false]:left-[-338px]
                    data-[isopen=true]:left-0"
                data-isopen={isOpen}
            >
                <div
                    className="relative flex h-[calc(100vh-129px)] flex-col gap-4 scrollbar
                        scrollbar-thumb-gray-500 scrollbar-thumb-rounded-full scrollbar-w-2
                        data-[isopen=false]:overflow-hidden data-[isopen=true]:overflow-y-auto"
                    data-isopen={isOpen}
                >
                    {children}
                </div>
            </div>
        </>
    );
}
