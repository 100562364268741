import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import { ModelBuilderPageProps } from "./ModelBuilder";
import {
    ModelBlock,
    ModelVariable,
    PromptType,
    Round,
    TimeHorizon,
    VariableDepthObject,
    VariableRelationship,
    VariableValue,
} from "@/models";
import { groupBy } from "lodash";
import { createNestedTree } from "@/util";

// model builder page props

export const modelBuilderPagePropsAtom = atom<ModelBuilderPageProps>(
    undefined as ModelBuilderPageProps,
);
export const useSetModelBuilderPageProps = () => {
    const setModelBuilderPageProps = useSetAtom(modelBuilderPagePropsAtom);
    return setModelBuilderPageProps;
};

// model variables and values

const modelBuilderBaseVariableValuesMapAtom = atom<
    Record<string, VariableValue[]>
>(undefined as Record<string, VariableValue[]>);
export const useSetBaseVariableValuesMap = () => {
    const setBaseVariableValuesMap = useSetAtom(
        modelBuilderBaseVariableValuesMapAtom,
    );
    return setBaseVariableValuesMap;
};

const modelBuilderModelVariablesAtom = atom<ModelVariable[] | undefined>(
    (get) => {
        const modelBuilderPageProps = get(modelBuilderPagePropsAtom);
        const modelVariables = modelBuilderPageProps?.modelVariables ?? [];
        const baseVariableValuesMap = get(
            modelBuilderBaseVariableValuesMapAtom,
        );
        return (
            modelVariables.map(
                (variable) =>
                    ({
                        ...variable,
                        baseVariableValues:
                            !!baseVariableValuesMap &&
                            !!baseVariableValuesMap[variable.id]
                                ? baseVariableValuesMap[variable.id]
                                : [],
                    }) as ModelVariable,
            ) ?? []
        );
    },
);

// model blocks

const modelBuilderModelBlocksAtom = atom<ModelBlock[] | undefined>((get) => {
    const modelBuilderPageProps = get(modelBuilderPagePropsAtom);
    const modelBlocks = modelBuilderPageProps?.modelBlocks ?? [];
    const modelVariables = get(modelBuilderModelVariablesAtom);
    const variableMap = groupBy(modelVariables, "model_block_id");
    return (
        modelBlocks.map(
            (block) =>
                ({
                    ...block,
                    modelVariables: !!variableMap[block.id]
                        ? [...variableMap[block.id]].sort(
                              (a, b) => a.weight - b.weight,
                          )
                        : [],
                }) as ModelBlock,
        ) ?? []
    );
});
export const useModelBlockArray = () => {
    const modelBlocks = useAtomValue(modelBuilderModelBlocksAtom);
    return modelBlocks;
};

const modelBuilderNestedModelBlocksAtom = atom<
    { [index: string]: ModelBlock } | undefined
>((get) => {
    const modelBlocks = get(modelBuilderModelBlocksAtom);

    const sortedModelBlocks = [...modelBlocks].sort(
        (a, b) => a.weight - b.weight,
    );

    return createNestedTree(
        "parent_model_block_id",
        "modelBlocks",
        sortedModelBlocks,
    )?.reduce((map, model) => {
        return { ...map, ...{ [model.id]: model } };
    }, {});
});
export const useModelBlockNestedObject = () => {
    const nestedModelBlocks = useAtomValue(modelBuilderNestedModelBlocksAtom);
    return nestedModelBlocks;
};

// active model variable

export const modelBuilderActiveModelVariableAtom = atom<ModelVariable>(
    undefined as ModelVariable,
);
export const useActiveModelVariable = () => {
    const [activeModelVariable, setActiveModelVariable] = useAtom(
        modelBuilderActiveModelVariableAtom,
    );
    return { activeModelVariable, setActiveModelVariable };
};

// model variables

// const modelBuilderModelVariablesAtom = atom<ModelVariable[] | undefined>(
//     (get) => {
//         const modelBlocks = get(modelBuilderModelBlocksAtom);
//         return (
//             modelBlocks?.reduce(
//                 (map, modelBlock) => [...map, ...modelBlock.modelVariables],
//                 [],
//             ) ?? []
//         );
//     },
// );
// export const useModelBuilderModelVariables = () => {
//     const modelVariables = useAtomValue(modelBuilderModelVariablesAtom);
//     return modelVariables;
// };

const modelBuilderModelVariableMapAtom = atom<
    { [index: string]: ModelVariable } | undefined
>((get) => {
    const modelVariables = get(modelBuilderModelVariablesAtom);
    return (
        modelVariables?.reduce(
            (map, modelVariable) => ({
                ...map,
                [modelVariable.id]: modelVariable,
            }),
            {},
        ) ?? {}
    );
    // return (
    //     modelBlocks?.reduce(
    //         (map, modelBlock) => ({
    //             ...map,
    //             ...modelBlock.modelVariables?.reduce(
    //                 (vMap, modelVariable) => ({
    //                     ...vMap,
    //                     [modelVariable.id]: modelVariable,
    //                 }),
    //                 {},
    //             ),
    //         }),
    //         {},
    //     ) ?? {}
    // );
});
export const useModelVariableMap = () => {
    const modelVariableMap = useAtomValue(modelBuilderModelVariableMapAtom);
    return modelVariableMap;
};

// variable relationships

const modelBuilderVariableRelationshipsAtom = atom<
    VariableRelationship[] | undefined
>((get) => {
    const modelBuilderPageProps = get(modelBuilderPagePropsAtom);
    return modelBuilderPageProps?.variableRelationships ?? [];
});
// export const useModelBuilderVariableRelationships = () => {
//     const variableRelationships = useAtomValue(
//         modelBuilderVariableRelationshipsAtom,
//     );
//     return variableRelationships;
// };

// const modelBuilderVariableRelationshipMapAtom = atom<
//     { [index: string]: VariableRelationship } | undefined
// >((get) => {
//     const variableRelationships = get(modelBuilderVariableRelationshipsAtom);
//     return (
//         variableRelationships?.reduce(
//             (map, variableRelationship) => ({
//                 ...map,
//                 [variableRelationship.id]: variableRelationship,
//             }),
//             {},
//         ) ?? {}
//     );
// });
// export const useModelBuilderVariableRelationshipMap = () => {
//     const variableRelationshipMap = useAtomValue(
//         modelBuilderVariableRelationshipMapAtom,
//     );
//     return variableRelationshipMap;
// };

const modelBuilderRelationshipsBySourceIdAtom = atom<
    { [index: string]: VariableRelationship[] } | undefined
>((get) => {
    const variableRelationships = get(modelBuilderVariableRelationshipsAtom);
    return groupBy(variableRelationships || [], "source_variable_id");
});
export const useRelationshipsBySourceId = () => {
    const relationshipsBySourceId = useAtomValue(
        modelBuilderRelationshipsBySourceIdAtom,
    );
    return relationshipsBySourceId;
};

const modelBuilderRelationshipsByTargetIdAtom = atom<
    { [index: string]: VariableRelationship[] } | undefined
>((get) => {
    const variableRelationships = get(modelBuilderVariableRelationshipsAtom);
    return groupBy(variableRelationships || [], "target_variable_id");
});
export const useRelationshipsByTargetId = () => {
    const relationshipsByTargetId = useAtomValue(
        modelBuilderRelationshipsByTargetIdAtom,
    );
    return relationshipsByTargetId;
};

// rounds

const modelBuilderRoundsAtom = atom<Round[] | undefined>((get) => {
    const modelBuilderPageProps = get(modelBuilderPagePropsAtom);
    const modelVariables = get(modelBuilderModelVariablesAtom);
    const variablesByPromptId =
        groupBy(modelVariables ?? [], "prompt_id") ?? {};

    return modelBuilderPageProps?.rounds?.length > 0
        ? modelBuilderPageProps.rounds.map(
              (round, i) =>
                  ({
                      ...round,
                      prompts: round.prompts
                          .filter(
                              (prompt) =>
                                  prompt.prompt_type !==
                                      PromptType["Short Text"] &&
                                  prompt.prompt_type !==
                                      PromptType["Long Text"],
                          )
                          .map((prompt) => {
                              const selectionDataModelVariables =
                                  variablesByPromptId[prompt.id] ?? [];
                              return {
                                  ...prompt,
                                  selectionDataModelVariables:
                                      selectionDataModelVariables,
                                  has_model_effects:
                                      selectionDataModelVariables?.length > 0,
                              };
                          }),
                  }) as Round,
          )
        : [];
});
export const useModelBuilderRounds = () => {
    const rounds = useAtomValue(modelBuilderRoundsAtom);
    return rounds;
};

// time horizons

const modelBuilderTimeHorizonsAtom = atom<TimeHorizon[] | undefined>((get) => {
    const modelBuilderPageProps = get(modelBuilderPagePropsAtom);
    return modelBuilderPageProps?.timeHorizons ?? [];
});
export const useModelBuilderTimeHorizons = () => {
    const timeHorizons = useAtomValue(modelBuilderTimeHorizonsAtom);
    return timeHorizons;
};

// variable depths

const modelBuilderVariableDepthsAtom = atom<VariableDepthObject[]>(
    undefined as VariableDepthObject[],
);
export const useModelBuilderVariableDepths = () => {
    const variableDepths = useAtomValue(modelBuilderVariableDepthsAtom);
    return variableDepths;
};
export const useSetModelBuilderVariableDepths = () => {
    const setModelBuilderVariableDepths = useSetAtom(
        modelBuilderVariableDepthsAtom,
    );
    return setModelBuilderVariableDepths;
};

// page type

const modelBuilderPageTypeAtom = atom<string | undefined>((get) => {
    const modelBuilderPageProps = get(modelBuilderPagePropsAtom);
    return modelBuilderPageProps?.type ?? undefined;
});
export const useModelBuilderPageType = () => {
    const pageType = useAtomValue(modelBuilderPageTypeAtom);
    return pageType;
};
