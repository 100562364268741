import React, { useEffect, useMemo, useState } from "react";
import { QuestionDisplay } from "./QuestionDisplay";
import { SapienInertia } from "@/inertia-utils/hooks";
import { ModelConnections } from "./ModelConnections";
import { QuestionSubmoduleData } from "./QuestionSubmoduleForm";
import { Prompt, Round } from "@/models";
import {
    useModelBuilderRounds,
    useModelVariableMap,
    useRelationshipsBySourceId,
} from "../atoms";

const createNewQuestionSubmoduleData = ({
    simulationId,
    roundId,
    prompt,
}: {
    simulationId: string;
    roundId: string;
    prompt: Prompt;
}): QuestionSubmoduleData => {
    return {
        simulation_id: simulationId,
        round_id: roundId,
        prompt_id: prompt.id,
        unit: null,
        modelBlockLabel: "",
        parent_model_block_id: null,
        optionIds: prompt.options.map((option) => option.id),
        independentVariableLabels: prompt.options.map(() => ""),
        dependentVariableLabels: [""],
        independentVariableNumericalValues: prompt.options.map(() => 0),
        comparisonVariableNumericalValues: [],
    };
};

export const QuestionList = (props: {
    simulationId: string;
    selectedBuilderRoundId: string;
    setSelectedBuilderRoundId: (roundId: string) => void;
    setQuestionSubmoduleData: (
        questionSubmoduleData: QuestionSubmoduleData,
    ) => void;
}) => {
    const {
        simulationId,
        selectedBuilderRoundId,
        setSelectedBuilderRoundId,
        setQuestionSubmoduleData,
    } = props;

    const rounds = useModelBuilderRounds();
    const relationshipsBySourceId = useRelationshipsBySourceId();
    const modelVariables = useModelVariableMap();

    const roundsWithFilteredPrompts = useMemo(() => {
        return rounds.map(
            (round, i) =>
                ({
                    ...round,
                    prompts: round.prompts
                        // .filter(
                        //     (prompt) =>
                        //         prompt.prompt_type !==
                        //             PromptType["Short Text"] &&
                        //         prompt.prompt_type !== PromptType["Long Text"],
                        // )
                        .map((prompt) => {
                            const connectionVariables = !!modelVariables
                                ? prompt.selectionDataModelVariables
                                      ?.map((selectionDataVariable) =>
                                          !!relationshipsBySourceId &&
                                          !!relationshipsBySourceId[
                                              selectionDataVariable.id
                                          ]
                                              ? relationshipsBySourceId[
                                                    selectionDataVariable.id
                                                ].map(
                                                    (targetRelationship) =>
                                                        modelVariables[
                                                            targetRelationship
                                                                .target_variable_id
                                                        ],
                                                )
                                              : [],
                                      )
                                      .reduce((map, cmvArray) => {
                                          return [
                                              ...map,
                                              ...cmvArray.filter(
                                                  (cmv) =>
                                                      !map
                                                          .map((v) => v.id)
                                                          .includes(cmv.id),
                                              ),
                                          ];
                                      }, [])
                                : [];
                            return {
                                ...prompt,
                                connectionModelVariables: connectionVariables,
                                // has_model_effects:
                                //     prompt?.selectionDataModelVariables
                                //         ?.length > 0,
                            };
                        }),
                }) as Round,
        );
    }, [rounds, modelVariables, relationshipsBySourceId]);

    const [hiddenPromptAccordionKeys, setHiddenPromptAccordionKeys] = useState<{
        [key: string]: boolean;
    }>({});

    useEffect(() => {
        setHiddenPromptAccordionKeys({});
    }, [selectedBuilderRoundId]);

    const connectQuestion = async (promptId: string) => {
        return SapienInertia.post(
            "model-builder.connect-question",
            {
                prompt_id: promptId,
            },
            {},
            { preserveScroll: true },
        );
    };

    const reorderPrompts = async (
        promptId: string,
        destinationIndex: number,
    ) => {
        return SapienInertia.post(
            "model-builder.reorder-prompts",
            {
                prompt_id: promptId,
                destination_index: destinationIndex,
            },
            {},
            { preserveScroll: true },
        );
    };

    return (
        <div>
            <div className="mb-4 mt-2 text-xl">Questions by Round</div>
            <div className="mx-4 mb-4">
                <span className="flex items-center">
                    <label className="font-medium">Round</label>
                    <select
                        className="ml-2 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base
                            focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        value={selectedBuilderRoundId}
                        onChange={(e) => {
                            setSelectedBuilderRoundId(e.target.value);
                        }}
                    >
                        <option key="" value="" />
                        {roundsWithFilteredPrompts.map((round) => (
                            <option
                                key={round.id}
                                value={round.id}
                                disabled={!round.prompts?.length}
                            >
                                {round.title}
                            </option>
                        ))}
                    </select>
                </span>
            </div>
            <div className="mb-4">
                {!!selectedBuilderRoundId &&
                    roundsWithFilteredPrompts
                        ?.filter((round) => round.id === selectedBuilderRoundId)
                        .map((round) => (
                            <div key={round.id}>
                                {round.prompts !== undefined &&
                                    round.prompts.length > 0 && (
                                        <>
                                            <div className="pb-4 text-center text-xs">
                                                <span
                                                    className="mr-2 cursor-pointer hover:underline"
                                                    onClick={() =>
                                                        setHiddenPromptAccordionKeys(
                                                            {},
                                                        )
                                                    }
                                                >
                                                    Expand
                                                </span>
                                                <span
                                                    className="cursor-pointer hover:underline"
                                                    onClick={() =>
                                                        setHiddenPromptAccordionKeys(
                                                            round.prompts.reduce(
                                                                (
                                                                    map,
                                                                    prompt,
                                                                ) => ({
                                                                    ...map,
                                                                    [prompt.id]:
                                                                        true,
                                                                }),
                                                                {},
                                                            ),
                                                        )
                                                    }
                                                >
                                                    Collapse
                                                </span>
                                            </div>
                                            {round.prompts.map((prompt) => (
                                                <QuestionDisplay
                                                    key={prompt.id}
                                                    simulationId={simulationId}
                                                    prompt={prompt}
                                                    connectQuestion={
                                                        connectQuestion
                                                    }
                                                    reorderPrompts={
                                                        reorderPrompts
                                                    }
                                                    isExpanded={
                                                        !hiddenPromptAccordionKeys[
                                                            prompt.id
                                                        ]
                                                    }
                                                    toggleExpanded={() =>
                                                        setHiddenPromptAccordionKeys(
                                                            {
                                                                ...hiddenPromptAccordionKeys,
                                                                [prompt.id]:
                                                                    !hiddenPromptAccordionKeys[
                                                                        prompt
                                                                            .id
                                                                    ],
                                                            },
                                                        )
                                                    }
                                                    handleSetQuestionSubmoduleData={() =>
                                                        setQuestionSubmoduleData(
                                                            createNewQuestionSubmoduleData(
                                                                {
                                                                    simulationId,
                                                                    roundId:
                                                                        round.id,
                                                                    prompt,
                                                                },
                                                            ),
                                                        )
                                                    }
                                                />
                                            ))}
                                        </>
                                    )}
                            </div>
                        ))}
            </div>
            <div>
                {!!selectedBuilderRoundId &&
                    roundsWithFilteredPrompts
                        ?.filter((round) => round.id === selectedBuilderRoundId)
                        .map((round) => (
                            <React.Fragment key={round.id}>
                                <div className="mb-4 mt-10 text-xl">
                                    Variables by Question
                                </div>
                                <ModelConnections round={round} />
                            </React.Fragment>
                        ))}
            </div>
        </div>
    );
};
