import React, { useCallback, useState } from "react";
import { Button } from "@/components/Button";
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/Dialog";
import { ChevronDownIcon, EyeIcon } from "lucide-react";
import { ContentBlockShape } from "@/models";
import { useModelVariables } from "@/hooks/useModelVariables";
import { useTimeHorizons } from "@/hooks/useTimeHorizons";
import {
    Combobox,
    ComboboxInput,
    ComboboxOptions,
    ComboboxOption,
    ComboboxButton,
} from "@headlessui/react";
import { useSelectedRoundId, useSelectedSimulation } from "@/hooks";
import { SapienInertia } from "@/inertia-utils/hooks";

type Props = {
    contentBlock: ContentBlockShape;
};

const DisplayRuleModal = ({ contentBlock }: Props) => {
    const { booleanModelVariables } = useModelVariables();
    const { timeHorizons } = useTimeHorizons();
    const { selectedSimulation } = useSelectedSimulation();
    const { selectedRoundId } = useSelectedRoundId();

    const [open, setOpen] = useState(false);
    const [query, setQuery] = useState("");

    const [selectedModelVariable, setSelectedModelVariable] = useState<
        string | null
    >(contentBlock.displayRule?.model_variable_id ?? null);

    const chosenModelVariable = booleanModelVariables.find(
        (variable) => variable.id === selectedModelVariable,
    );

    const filteredModelVariables = booleanModelVariables.filter(
        (variable) =>
            !query?.length ||
            variable.label.toLowerCase().includes(query.toLowerCase()),
    );

    const [isSubmitting, setIsSubmitting] = useState(false);
    const submit = useCallback(() => {
        setIsSubmitting(true);
        if (contentBlock?.displayRule) {
            SapienInertia.put(
                "creator.design.display-rules.update",
                {
                    model_variable_id: selectedModelVariable,
                    content_block_id: contentBlock.id,
                    from_design_studio: true,
                },
                {
                    simulationSlug: selectedSimulation.slug,
                    roundId: selectedRoundId,
                    displayRuleId: contentBlock.displayRule.id,
                },
                {
                    onSuccess: () => {
                        setOpen(false);
                        setIsSubmitting(false);
                    },
                },
            );
        } else {
            SapienInertia.post(
                "creator.design.display-rules.store",
                {
                    model_variable_id: selectedModelVariable,
                    content_block_id: contentBlock.id,
                    from_design_studio: true,
                },
                {
                    simulationSlug: selectedSimulation.slug,
                    roundId: selectedRoundId,
                },
                {
                    onSuccess: () => {
                        setOpen(false);
                        setIsSubmitting(false);
                    },
                },
            );
        }
    }, [
        contentBlock,
        selectedModelVariable,
        selectedSimulation,
        selectedRoundId,
    ]);

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger>
                <Button
                    className="rounded-md bg-white p-2 transition-colors
                        data-[has-display-rule=true]:bg-green-500
                        data-[has-display-rule=true]:text-white hover:bg-slate-200
                        data-[has-display-rule=true]:hover:bg-green-600"
                    data-has-display-rule={!!contentBlock.displayRule}
                >
                    <EyeIcon className="h-4 w-4" />
                </Button>
            </DialogTrigger>
            <DialogContent className="w-full max-w-screen-lg bg-slate-50 p-6">
                <DialogHeader>
                    <DialogTitle>Display Rule</DialogTitle>
                </DialogHeader>
                <div className="flex flex-col gap-4">
                    <div className="flex flex-col gap-2">
                        <Combobox
                            onChange={(value: string) =>
                                setSelectedModelVariable(value)
                            }
                        >
                            <label htmlFor="model-variable">
                                Model Variable
                            </label>
                            <span className="relative">
                                <ComboboxInput
                                    className="w-full rounded-md border border-gray-300 p-2"
                                    id="model-variable"
                                    placeholder="Select a model variable"
                                    onChange={(e) => setQuery(e.target.value)}
                                    displayValue={(value) => {
                                        return chosenModelVariable?.label ?? "";
                                    }}
                                />
                                <ComboboxButton className="absolute right-2 top-1/2 -translate-y-1/2">
                                    <ChevronDownIcon className="h-4 w-4" />
                                </ComboboxButton>
                            </span>

                            <div className="relative mt-2">
                                <ComboboxOptions
                                    className="absolute flex max-h-96 flex-col gap-2 overflow-y-auto rounded-lg bg-white p-4
                                        shadow-lg scrollbar scrollbar-thumb-gray-500 scrollbar-thumb-rounded-full
                                        scrollbar-w-2"
                                >
                                    {filteredModelVariables.map((variable) => (
                                        <ComboboxOption
                                            key={variable.id}
                                            value={variable.id}
                                            className="flex items-center gap-2 rounded-md p-2 transition-colors hover:bg-blue-500
                                                hover:text-white"
                                            onClick={() =>
                                                setSelectedModelVariable(
                                                    variable.id,
                                                )
                                            }
                                        >
                                            {variable.label}
                                        </ComboboxOption>
                                    ))}
                                </ComboboxOptions>
                            </div>
                            {chosenModelVariable?.uses_time && (
                                <div className="flex flex-col gap-2">
                                    <label htmlFor="time-horizon">
                                        Time Horizon
                                    </label>
                                    <select
                                        className="w-full rounded-md border border-gray-300 p-2"
                                        id="time-horizon"
                                    >
                                        <option value="all">
                                            Choose a time horizon
                                        </option>
                                        {timeHorizons.map((horizon) => (
                                            <option key={horizon.id}>
                                                Time Horizon{" "}
                                                {horizon.time_index}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            )}
                        </Combobox>
                    </div>
                </div>
                <DialogFooter>
                    <button
                        disabled={isSubmitting}
                        className="rounded-md bg-blue-500 px-4 py-2 text-white transition-colors hover:bg-blue-600
                            disabled:opacity-50"
                        onClick={submit}
                    >
                        {isSubmitting ? "Saving..." : "Save"}
                    </button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

export default DisplayRuleModal;
