import React from "react";
import { useModelBuilderRounds } from "../atoms";

export const QuestionPanel = (props: {
    selectedBuilderRoundId: string;
    setSelectedBuilderRoundId: (roundId: string) => void;
}) => {
    const { selectedBuilderRoundId, setSelectedBuilderRoundId } = props;

    const rounds = useModelBuilderRounds();

    return (
        <div>
            <div className="w-full text-white">
                <div className="my-1 p-1">
                    <div className="flex items-center justify-between text-sm font-bold">
                        <div>ROUND</div>
                        <div>QUESTIONS</div>
                    </div>
                </div>
            </div>
            {rounds?.map((round, index) => (
                <div
                    key={round.id}
                    className={`w-full text-white ${
                        !!round.prompts?.length
                            ? "cursor-pointer"
                            : "cursor-default"
                    }`}
                    onClick={() =>
                        !!round.prompts?.length &&
                        setSelectedBuilderRoundId(
                            round.id === selectedBuilderRoundId ? "" : round.id,
                        )
                    }
                >
                    <div
                        className={`my-1 p-2 ${
                            round.id === selectedBuilderRoundId
                                ? "bg-[rgba(255,255,255,0.24)]"
                                : !!round.prompts?.length
                                  ? "bg-[rgba(255,255,255,0.1)]"
                                  : "bg-[rgba(255,255,255,0.03)]"
                        }`}
                    >
                        <div className="flex items-center justify-between text-sm">
                            <div className="w-[12.5%] pr-1">{`${
                                index + 1
                            }`}</div>
                            <div className="w-[80%]">{round.title}</div>
                            <div className="w-[12.5%] pl-1 text-right">{`${
                                round.prompts?.length || "-"
                            }`}</div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};
