import React from "react";
import { ContentBlockShape } from "@/models";
import {
    Accordion,
    AccordionItem,
    AccordionTrigger,
    AccordionContent,
} from "@/components/Accordion";
import ButtonGroup from "../ButtonGroup";
import { OptionCardImageHandler } from "../questions/OptionCardImage";
import { ColorPickerPopover } from "@/components/color-picker";
type Props = {
    update: (contentBlock: ContentBlockShape) => void;
    contentBlock: ContentBlockShape;
};
export function OptionCardStyles({ update, contentBlock }: Props) {
    return (
        <>
            <OptionCardImageHandler
                contentBlock={contentBlock}
                update={update}
            />
            <div className="flex flex-col gap-2 pb-2 shadow-sm">
                <label
                    htmlFor="border"
                    className="flex items-center gap-2 text-xs"
                >
                    Border Color (when selected)
                    <ColorPickerPopover
                        value={""}
                        disallowGradients={true}
                        onChange={(value) => {
                            update({
                                ...contentBlock,
                                theme: {
                                    ...contentBlock.theme,
                                    activeBorderColor: value,
                                    tailwindClasses: [
                                        ...(
                                            contentBlock.theme
                                                ?.tailwindClasses || []
                                        ).filter(
                                            (className) =>
                                                !className.includes("border"),
                                        ),
                                        `border has-[:checked]:border-[var(--border-color-active)]`,
                                    ],
                                },
                            });
                        }}
                        onGradientChange={function (
                            value: App.Data.DesignColorGradientData,
                        ): void {
                            // throw new Error("Function not implemented.");
                        }}
                    />
                </label>
            </div>
            <Accordion type="single" collapsible={true}>
                <AccordionItem value="width">
                    <AccordionTrigger>Width</AccordionTrigger>
                    <AccordionContent>
                        <div className="flex flex-col gap-4">
                            <ButtonGroup
                                selectedValue={(
                                    contentBlock.theme
                                        ?.tailwindClasses as string[]
                                )
                                    ?.filter(
                                        (className) =>
                                            !/\bborder-\d+\b/.test(className),
                                    )
                                    .join(" ")}
                                values={[
                                    {
                                        label: "1 Column",
                                        value: `basis-full 
                            @2xl:basis-[calc(50%-1rem)] 
                            @5xl:basis-[calc(33%-1rem)] 
                            @7xl:basis-[calc(25%-1rem)]`,
                                    },
                                    {
                                        label: "2 Columns",
                                        value: `basis-full 
                            @5xl:basis-[calc(66.666666%-1rem)] 
                            @7xl:basis-[calc(50%-1rem)]`,
                                    },
                                    {
                                        label: "3 Columns",
                                        value: `basis-full @7xl:basis-[calc(75%-1rem)]`,
                                    },
                                ]}
                                handleChange={(value) => {
                                    update({
                                        ...contentBlock,
                                        theme: {
                                            ...contentBlock.theme,
                                            tailwindClasses: [
                                                ...(
                                                    contentBlock.theme
                                                        ?.tailwindClasses || []
                                                ).filter(
                                                    (className) =>
                                                        !className.includes(
                                                            "basis",
                                                        ),
                                                ),
                                                value,
                                            ],
                                        },
                                    });
                                }}
                            />
                        </div>
                    </AccordionContent>
                </AccordionItem>
                <AccordionItem value="margin">
                    <AccordionTrigger>Orientation</AccordionTrigger>
                    <AccordionContent>
                        <div className="flex flex-col gap-4">
                            <ButtonGroup
                                selectedValue={(
                                    contentBlock.theme
                                        ?.tailwindClasses as string[]
                                )
                                    ?.filter(
                                        (className) =>
                                            !/\bborder-\d+\b/.test(className),
                                    )
                                    .join(" ")}
                                values={[
                                    {
                                        label: "Image on left",
                                        value: "flex-row gap-4",
                                    },
                                    {
                                        label: "Image on right",
                                        value: "flex-row-reverse  gap-4",
                                    },
                                    {
                                        label: "Image at top",
                                        value: "flex-col gap-2",
                                    },
                                    {
                                        label: "Image at bottom",
                                        value: "flex-col-reverse gap-2",
                                    },
                                ]}
                                handleChange={(value) => {
                                    update({
                                        ...contentBlock,
                                        theme: {
                                            ...contentBlock.theme,
                                            tailwindClasses: [
                                                ...(
                                                    contentBlock.theme
                                                        ?.tailwindClasses || []
                                                ).filter(
                                                    (className) =>
                                                        !className.includes(
                                                            "flex-row",
                                                        ) &&
                                                        !className.includes(
                                                            "flex-col",
                                                        ),
                                                ),
                                                value,
                                            ],
                                        },
                                    });
                                }}
                            />
                        </div>
                    </AccordionContent>
                </AccordionItem>
            </Accordion>
        </>
    );
}
