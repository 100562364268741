import React from "react";
import { SapienPageProps } from "@/inertia-utils/types";
import { Simulation, SimulationShape } from "@/models";
import { AdminContainer } from "@/Layouts/admin";

import {
    AdminPageHeader,
    AdminPageSection,
} from "@/components/admin-components";
import { AdminDashboardTopNav } from "@/Layouts/admin/AdminDashboardTopNav";
import { Course } from "@/Pages/ELearning/types";
import { Link } from "@inertiajs/react";
import { sapienRoute } from "@/inertia-utils/hooks";

type Props = SapienPageProps & {
    simulations: Simulation[];
    courses: Course[];
};

const SimOrCourseDisplay = ({
    simOrCourse,
    isCourse,
}: {
    simOrCourse: SimulationShape | Course;
    isCourse?: boolean;
}) => {
    const backgroundImage =
        simOrCourse?.theme?.image_url ||
        "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/fallback/noun-image-5275349.png";

    return (
        <>
            <div className="flex flex-col gap-2">
                <Link
                    key={simOrCourse.id}
                    href={
                        isCourse
                            ? sapienRoute("e-learning.courses.edit", {
                                  course: simOrCourse.id,
                              })
                            : sapienRoute("simulation.projects.show", {
                                  project: simOrCourse.slug,
                              })
                    }
                    className="rounded-md bg-white/75 p-4 shadow-sm transition-all hover:bg-white/100
                        hover:shadow-md"
                >
                    <div className="flex w-full items-center space-x-4">
                        <div
                            className={`h-16 w-full max-w-20 rounded-md ${
                                !!simOrCourse?.theme?.image_url
                                    ? "bg-cover bg-center bg-no-repeat shadow-sm"
                                    : "border-2 bg-contain bg-center bg-no-repeat"
                            }`}
                            style={{
                                backgroundImage: `url(${backgroundImage})`,
                            }}
                        ></div>
                        <div className="flex w-full flex-col space-y-1">
                            <span className="text-base font-semibold">
                                {simOrCourse.title}
                            </span>
                            <span className="text-xs text-slate-700">
                                {`${isCourse ? "Lessons" : "Rounds"}: ${isCourse ? (simOrCourse as Course).lessons_count : simOrCourse.rounds_count}`}
                            </span>
                        </div>
                    </div>
                </Link>
                {!isCourse && (
                    <Link
                        href={sapienRoute("design-studio.index", {
                            simulationId: simOrCourse.id,
                        })}
                        className="text-sm text-blue-600 underline"
                    >
                        Design Studio
                    </Link>
                )}
            </div>
        </>
    );
};

export default function Dashboard({ simulations, courses, tenant }: Props) {
    return (
        <AdminContainer headTitle={"Admin"}>
            <AdminPageHeader>
                <AdminDashboardTopNav header={"Home"} />
            </AdminPageHeader>
            <AdminPageSection>
                <div className="space-y-4 text-slate-900">
                    <div className="flex min-h-9 flex-row items-center justify-between">
                        <span className="text-xl font-normal">
                            {"Simulations"}
                        </span>
                    </div>
                    <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
                        {simulations?.length > 0 ? (
                            simulations.map((simulation) => (
                                <SimOrCourseDisplay
                                    key={simulation.id}
                                    simOrCourse={simulation}
                                    isCourse={false}
                                />
                            ))
                        ) : (
                            <span className="text-sm">No simulations</span>
                        )}
                    </div>
                    {!!tenant?.has_e_learning_feature && (
                        <>
                            <div className="flex min-h-9 flex-row items-center justify-between">
                                <span className="text-xl font-normal">
                                    {"Courses"}
                                </span>
                            </div>
                            <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
                                {courses?.length > 0 ? (
                                    courses.map((course) => (
                                        <SimOrCourseDisplay
                                            key={course.id}
                                            simOrCourse={course}
                                            isCourse={true}
                                        />
                                    ))
                                ) : (
                                    <span className="text-sm">No courses</span>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </AdminPageSection>
        </AdminContainer>
    );
}
