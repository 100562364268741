import { Accordion } from "@/components/Accordion";
import { ContentBlockShape } from "@/models";
import React from "react";
import { cn } from "@/util";

type Props = {
    contentBlock: ContentBlockShape;
    children: React.ReactNode | React.ReactNode[];
};

export default function AccordionBlock({ contentBlock, children }: Props) {
    const accordionType = contentBlock.theme?.accordionType || "single";

    return (
        <Accordion
            data-content-block-id={contentBlock.id}
            type={accordionType}
            collapsible
            className={`overflow-hidden w-full border ${cn(contentBlock.theme?.tailwindClasses)}`}
            style={{
                borderColor: contentBlock.theme?.borderColor,
                backgroundColor: contentBlock.theme?.backgroundColor,
            }}
        >
            
            {children}
        </Accordion>
    );
}
