import React, { useCallback, useMemo } from "react";
import {
    ModelBlock,
    ModelVariable,
    ModelVariableScope,
    ModelVariableType,
    TimeHorizon,
} from "@/models";
import { plus } from "react-icons-kit/feather/plus";
import {
    NestedModelVariableDisplay,
    NestedModelVariableWorkspaceButtons,
} from "./NestedModelVariableDisplay";
import { PrimaryButtonOutline } from "@/components/admin-components";
import {
    useActiveModelVariable,
    useModelBuilderPageType,
    useModelBuilderTimeHorizons,
} from "../atoms";
import { useBuilderWorkspace } from "../useModelBuilderWorkspace";
import { useDefaultModelVariableScope } from "@/hooks";
import { useGetVariableDepthById } from "../hooks";

const ModelBlockHeader = React.memo(
    ({
        modelBlock,
        isExpanded,
        onToggle,
    }: {
        modelBlock: ModelBlock;
        isExpanded: boolean;
        onToggle: () => void;
    }) => (
        <div
            className={`my-0.5 cursor-pointer py-0.5 ${
                isExpanded ? "bg-neutral-200" : "bg-neutral-100"
            }`}
            onClick={onToggle}
            data-testid={`block_${modelBlock.label}`}
        >
            <div className="mr-2 flex items-center justify-between">
                <div className="flex items-center">
                    <svg
                        className={`-mr-0.5 ml-2 h-5 w-5 transition-all ${isExpanded ? "rotate-0" : "-rotate-90"}`}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                    >
                        <path
                            fillRule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        />
                    </svg>
                    <div className="block px-1.5 py-1.5">
                        <div
                            className={
                                modelBlock.is_interactive ? "font-bold" : ""
                            }
                        >
                            {modelBlock.label}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ),
);

const ModelVariableTableHeader = React.memo(
    ({
        timeHorizons,
        showTimeHorizonRow,
        showAllValues,
    }: {
        timeHorizons: TimeHorizon[];
        showTimeHorizonRow: boolean;
        showAllValues: boolean;
    }) => (
        <thead className="text-xs uppercase text-gray-700">
            <tr className="border">
                <th className="px-1 py-3" rowSpan={showTimeHorizonRow ? 2 : 1}>
                    variable
                </th>
                <th
                    className="px-1 py-3"
                    rowSpan={showTimeHorizonRow ? 2 : 1}
                ></th>
                <th className="px-1 py-3" rowSpan={showTimeHorizonRow ? 2 : 1}>
                    type
                </th>
                <th className="px-1 py-3" rowSpan={showTimeHorizonRow ? 2 : 1}>
                    depth
                </th>
                <th className="px-1 py-3" rowSpan={showTimeHorizonRow ? 2 : 1}>
                    scope
                </th>
                <th
                    className="border-l px-1 py-3"
                    colSpan={timeHorizons?.length || 1}
                    rowSpan={showTimeHorizonRow ? (showAllValues ? 1 : 2) : 1}
                >
                    values
                </th>
            </tr>
            {!!showAllValues && !!showTimeHorizonRow && (
                <tr className="border">
                    {timeHorizons?.map((timeHorizon) => (
                        <th
                            key={timeHorizon.id}
                            className="border-l px-1 py-1 text-right"
                        >
                            {timeHorizon.time_index}
                        </th>
                    ))}
                </tr>
            )}
        </thead>
    ),
);

// const ModelVariablesTable = React.memo(
//     ({
//         modelBlock,
//         isExpanded,
//         onToggle,
//     }: {
//         modelBlock: ModelBlock;
//         isExpanded: boolean;
//         onToggle: () => void;
//     }) => (
//         <div className="relative overflow-x-auto">
//             <table
//                 className="w-full table-auto text-left text-sm text-gray-500"
//                 style={{
//                     fontSize: "0.8rem",
//                 }}
//             >
//                 <thead className="text-xs uppercase text-gray-700">
//                     <tr className="border">
//                         <th
//                             className="px-1 py-3"
//                             rowSpan={
//                                 simHasTimeHorizons && blockHasTimeVariables
//                                     ? 2
//                                     : 1
//                             }
//                         >
//                             variable
//                         </th>
//                         <th
//                             className="px-1 py-3"
//                             rowSpan={
//                                 simHasTimeHorizons && blockHasTimeVariables
//                                     ? 2
//                                     : 1
//                             }
//                         ></th>
//                         <th
//                             className="px-1 py-3"
//                             rowSpan={
//                                 simHasTimeHorizons && blockHasTimeVariables
//                                     ? 2
//                                     : 1
//                             }
//                         >
//                             type
//                         </th>
//                         <th
//                             className="px-1 py-3"
//                             rowSpan={
//                                 simHasTimeHorizons && blockHasTimeVariables
//                                     ? 2
//                                     : 1
//                             }
//                         >
//                             depth
//                         </th>
//                         <th
//                             className="px-1 py-3"
//                             rowSpan={
//                                 simHasTimeHorizons && blockHasTimeVariables
//                                     ? 2
//                                     : 1
//                             }
//                         >
//                             scope
//                         </th>
//                         <th
//                             className="border-l px-1 py-3"
//                             colSpan={timeHorizons?.length || 1}
//                             rowSpan={
//                                 simHasTimeHorizons && blockHasTimeVariables
//                                     ? showAllValues
//                                         ? 1
//                                         : 2
//                                     : 1
//                             }
//                         >
//                             values
//                         </th>
//                     </tr>
//                     {!!showAllValues && !!blockHasTimeVariables && (
//                         <tr className="border">
//                             {timeHorizons?.map((timeHorizon) => (
//                                 <th
//                                     key={timeHorizon.id}
//                                     className="border-l px-1 py-1 text-right"
//                                 >
//                                     {timeHorizon.time_index}
//                                 </th>
//                             ))}
//                         </tr>
//                     )}
//                 </thead>
//                 <tbody>
//                     {modelBlock.modelVariables.map((modelVariable) => (
//                         <NestedModelVariableDisplay
//                             key={modelVariable.id}
//                             modelVariable={modelVariable}
//                             showAllValues={showAllValues}
//                             timeHorizons={timeHorizons}
//                             setActiveModelVariable={setActiveModelVariable}
//                             variableDepth={getVariableDepthById(
//                                 modelVariable.id,
//                             )}
//                             isInteractive={modelBlock.is_interactive}
//                         >
//                             <NestedModelVariableWorkspaceButtons
//                                 showTargetButton={
//                                     modelVariable.variable_type !==
//                                         ModelVariableType["Selection Data"] &&
//                                     modelVariable.variable_type !==
//                                         ModelVariableType.Independent
//                                 }
//                                 toggleSourceVariable={
//                                     getCanToggleSourceVariable(modelVariable)
//                                         ? () =>
//                                               toggleSourceVariable(
//                                                   modelVariable,
//                                               )
//                                         : undefined
//                                 }
//                                 toggleTargetVariable={
//                                     getCanToggleTargetVariable(modelVariable)
//                                         ? () =>
//                                               toggleTargetVariable(
//                                                   modelVariable,
//                                               )
//                                         : undefined
//                                 }
//                                 isSelectedAsSourceVariable={getIsSelectedAsSourceVariable(
//                                     modelVariable.id,
//                                 )}
//                                 isSelectedAsTargetVariable={getIsSelectedAsTargetVariable(
//                                     modelVariable.id,
//                                 )}
//                             />
//                         </NestedModelVariableDisplay>
//                     ))}
//                 </tbody>
//             </table>
//         </div>
//     ),
// );

const NestedModelBlockDisplayComponent = ({
    modelBlock,
    showAllValues,
    setShowAllValues,
    toggleModelBlockAccordionKey,
    getIsModelBlockExpanded,
}: {
    modelBlock: ModelBlock;
    showAllValues?: boolean;
    setShowAllValues?: (showAllValues: boolean) => void;
    toggleModelBlockAccordionKey: (key: string) => void;
    getIsModelBlockExpanded: (modelBlockId: string) => boolean;
}) => {
    const timeHorizons = useModelBuilderTimeHorizons();
    const modelBuilderPageSection = useModelBuilderPageType();
    const { setActiveModelVariable } = useActiveModelVariable();

    const defaultModelVariableScope = useDefaultModelVariableScope();

    const { getVariableDepthById } = useGetVariableDepthById();

    const {
        toggleTargetVariable,
        toggleSourceVariable,
        getCanToggleTargetVariable,
        getCanToggleSourceVariable,
        getIsSelectedAsTargetVariable,
        getIsSelectedAsSourceVariable,
    } = useBuilderWorkspace();

    const isExpanded = useMemo(
        () => getIsModelBlockExpanded(modelBlock.id),
        [getIsModelBlockExpanded],
    );

    const blockHasTimeVariables = useMemo(() => {
        return (
            !!modelBlock &&
            !!modelBlock.modelVariables &&
            !!modelBlock.modelVariables.some((variable) => variable?.uses_time)
        );
    }, [modelBlock?.modelVariables?.length]);

    const handleToggle = useCallback(
        () =>
            !!toggleModelBlockAccordionKey &&
            toggleModelBlockAccordionKey(modelBlock.id),
        [modelBlock.id, toggleModelBlockAccordionKey],
    );

    const handleCreateVariable = useCallback(() => {
        setActiveModelVariable({
            model_block_id: modelBlock.id,
            label: "",
            weight: modelBlock?.modelVariables?.length
                ? Math.max(...modelBlock.modelVariables.map((v) => v.weight)) +
                  1
                : 0,
            scope: !modelBuilderPageSection
                ? defaultModelVariableScope
                : ModelVariableScope.Team,
        } as ModelVariable);
    }, [
        modelBlock.id,
        modelBlock?.modelVariables,
        modelBuilderPageSection,
        defaultModelVariableScope,
    ]);

    return (
        <div className="w-full text-sm">
            <ModelBlockHeader
                modelBlock={modelBlock}
                isExpanded={isExpanded}
                onToggle={handleToggle}
            />

            {!!isExpanded && (
                <div className="border-box -mt-0.5 mb-2 border border-neutral-200">
                    <div className="flex items-center justify-between p-2">
                        <div
                            style={{
                                fontWeight: "bold",
                                marginRight: "0.5rem",
                            }}
                        >{`Variables: ${
                            modelBlock?.modelVariables?.length || 0
                        }`}</div>
                        {!modelBlock.has_connections && (
                            <span className="flex items-center">
                                {!!blockHasTimeVariables && (
                                    <span
                                        className="mr-2 cursor-pointer text-xs hover:underline"
                                        onClick={() =>
                                            setShowAllValues(!showAllValues)
                                        }
                                    >
                                        {showAllValues
                                            ? "Hide time horizons"
                                            : "Show time horizons"}
                                    </span>
                                )}
                                <PrimaryButtonOutline
                                    text={"Create Variable"}
                                    icon={plus}
                                    handler={handleCreateVariable}
                                />
                            </span>
                        )}
                    </div>
                    {!!modelBlock?.modelVariables?.length && (
                        <div className="relative overflow-x-auto">
                            <table
                                className="w-full table-auto text-left text-sm text-gray-500"
                                style={{
                                    fontSize: "0.8rem",
                                }}
                            >
                                <ModelVariableTableHeader
                                    timeHorizons={timeHorizons}
                                    showTimeHorizonRow={
                                        !!timeHorizons?.length &&
                                        blockHasTimeVariables
                                    }
                                    showAllValues={showAllValues}
                                />
                                <tbody>
                                    {modelBlock.modelVariables.map(
                                        (modelVariable) => (
                                            <NestedModelVariableDisplay
                                                key={modelVariable.id}
                                                modelVariable={modelVariable}
                                                showAllValues={showAllValues}
                                                timeHorizons={timeHorizons}
                                                setActiveModelVariable={
                                                    setActiveModelVariable
                                                }
                                                variableDepth={getVariableDepthById(
                                                    modelVariable.id,
                                                )}
                                                isInteractive={
                                                    modelBlock.is_interactive
                                                }
                                            >
                                                <NestedModelVariableWorkspaceButtons
                                                    showTargetButton={
                                                        modelVariable.variable_type !==
                                                            ModelVariableType[
                                                                "Selection Data"
                                                            ] &&
                                                        modelVariable.variable_type !==
                                                            ModelVariableType.Independent
                                                    }
                                                    toggleSourceVariable={
                                                        getCanToggleSourceVariable(
                                                            modelVariable,
                                                        )
                                                            ? () =>
                                                                  toggleSourceVariable(
                                                                      modelVariable,
                                                                  )
                                                            : undefined
                                                    }
                                                    toggleTargetVariable={
                                                        getCanToggleTargetVariable(
                                                            modelVariable,
                                                        )
                                                            ? () =>
                                                                  toggleTargetVariable(
                                                                      modelVariable,
                                                                  )
                                                            : undefined
                                                    }
                                                    isSelectedAsSourceVariable={getIsSelectedAsSourceVariable(
                                                        modelVariable.id,
                                                    )}
                                                    isSelectedAsTargetVariable={getIsSelectedAsTargetVariable(
                                                        modelVariable.id,
                                                    )}
                                                />
                                            </NestedModelVariableDisplay>
                                        ),
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            )}

            {modelBlock.modelBlocks && modelBlock.modelBlocks.length !== 0 && (
                <div className="pl-8">
                    {modelBlock.modelBlocks.map((child) => (
                        <NestedModelBlockDisplay
                            modelBlock={child}
                            key={child.id}
                            showAllValues={showAllValues}
                            setShowAllValues={setShowAllValues}
                            toggleModelBlockAccordionKey={
                                toggleModelBlockAccordionKey
                            }
                            getIsModelBlockExpanded={getIsModelBlockExpanded}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export const NestedModelBlockDisplay = React.memo(
    NestedModelBlockDisplayComponent,
);
