/// <reference types="vite/client" />
import React from "react";
import { createRoot } from "react-dom/client";
import isPropValid from "@emotion/is-prop-valid";
import { Provider } from "react-redux";
import { createInertiaApp } from "@inertiajs/react";
import { GlobalTheme, GloablStyledComponent } from "./styles";
import { StyleSheetManager, ThemeProvider } from "styled-components";
import { AppStore } from "./redux-state";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import "../css/app.css";
import setupLocatorUI from "@locator/runtime";
import InitFromProps from "./InitFromProps";
import { DevTools } from "jotai-devtools";

if (process.env.NODE_ENV === "development") {
    setupLocatorUI();
}

const appName =
    window.document.getElementsByTagName("title")[0]?.innerText || "Laravel";

const rootElement: any = document.getElementById("inertia-app");
const root = createRoot(rootElement);
createInertiaApp({
    progress: { color: "#4B5563" },
    title: (title) => (!!title ? `${title} - ${appName}` : `${appName}`),
    resolve: (name) => {
        const pages = import.meta.glob("./Pages/**/*.tsx", { eager: true });
        let page =
            pages[`./Pages/${name}.tsx`] || pages[`./Pages/${name}/index.tsx`];

        (page as any).default.layout =
            (page as any).default.layout ||
            ((page) => <InitFromProps children={page} />);

        return pages[`./Pages/${name}.tsx`];
    },
    setup({ el, App, props }) {
        return root.render(
            <Provider store={AppStore}>
                <StyleSheetManager
                    shouldForwardProp={(prop) => {
                        return isPropValid(prop);
                    }}
                >
                    <DevTools/>
                    <GloablStyledComponent />
                    <ThemeProvider theme={GlobalTheme}>
                        <App {...props} />
                    </ThemeProvider>
                </StyleSheetManager>
            </Provider>,
        );
    },
});
