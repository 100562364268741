import React from "react";
import AddContentBlockButton from "../AddContentBlockButton";
import { contentBlockFunctionMap } from "./content-block-functions/contentBlockFunctionMap";
import { BookOpenCheck } from "lucide-react";
import { ContentBlockType } from "@/models";
import { useEditedType } from "../content-block-editing/editFunctions";
import { useDesignStudioSidebarState } from "../content-block-editing/useDesignStudioSidebarState";
import { useEditedContentBlock } from "../content-block-editing/useEditedContentBlock";

type Props = {
    roundId: string;
    weight: number;
};

export default function CreateQuestionButton({ roundId, weight }: Props) {
    const { setEditedContentBlockType } = useEditedType();
    const { setContentBlockToCreate } = useEditedContentBlock();
    const { setIsOpen } = useDesignStudioSidebarState();

    return (
        <AddContentBlockButton
            buildContentBlock={() =>
                contentBlockFunctionMap.createFormBlock(roundId, weight)
            }
            saveOveride={async (formBlock) => {
                setEditedContentBlockType(ContentBlockType.Form);
                setContentBlockToCreate(formBlock);
                setIsOpen(true);
            }}
        >
            <BookOpenCheck className="h-6 w-6" />
            <p>Quiz</p>
        </AddContentBlockButton>
    );
}
