import {
    useGetAllElementsAsync,
    useGetElementAsync,
} from "@/hooks/useGetElementAsync";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { createPortal } from "react-dom";
import {
    useContentBlockState,
    useGetContentBlockWithChildren,
} from "../../useContentBlockState";
import { atom, useAtom } from "jotai";
import { ChildBlockStylePopover } from "./ChildBlocktStylePopover";
import { debounce, useGotContentBlock } from "@/hooks";
import {
    useGetActiveContentBlock,
    useUpdateActiveContentBlock,
} from "@/hooks/activeContentBlock";
import { ContentBlockShape } from "@/models";
import { useAddContentBlockState } from "@/Pages/Admin/editor/content-block-buttons/useAddContentBlockState";

export const ChildBlockStyles = () => {
    const { contentBlocks } = useContentBlockState();
    const childBlocks = useMemo(() => {
        return Object.values(contentBlocks).filter(
            (block) => !!block.parent_content_block_id,
        );
    }, [contentBlocks]);

    return childBlocks.map((childBlock) => (
        <ChildBlockStyleEditor key={childBlock.id} contentBlock={childBlock} />
    ));
};

const hoveredAtom = atom<string>("");
hoveredAtom.debugLabel = "hoveredAtom";

const useHovered = () => {
    const [hovered, setHovered] = useAtom(hoveredAtom);
    return [hovered, setHovered] as const;
};

const ChildBlockStyleEditor = ({
    contentBlock,
}: {
    contentBlock: ContentBlockShape;
}) => {
    const childBlockElement = useGetElementAsync(
        `[data-content-block-id="${contentBlock.id}"]`,
    );
    const { data: activeContentBlock } = useGetActiveContentBlock();

    const [hovered, setHovered] = useHovered();
    const handleMouseLeave = (e: MouseEvent) => {
        setHovered("");
        childBlockElement?.classList.remove("outline-2");
        childBlockElement?.classList.remove("outline-offset-2");
        childBlockElement?.classList.remove("outline-dashed");
        childBlockElement?.classList.remove("outline-blue-500/50");
    };

    const handleMouseEnter = useCallback(
        (e: MouseEvent) => {
            if (
                contentBlock.content_block_type !== "OPTION" &&
                (!activeContentBlock ||
                    activeContentBlock.id !== contentBlock.id)
            )
                return;
            document.querySelectorAll(".outline-dashed").forEach((el) => {
                el.classList.remove("outline-2");
                el.classList.remove("outline-offset-2");
                el.classList.remove("outline-dashed");
                el.classList.remove("outline-blue-500/50");
            });

            const target = e.currentTarget as HTMLElement;
            if (
                !target?.getAttribute("data-content-block_id") ||
                target.getAttribute("data-content-block-id") ===
                    contentBlock?.id
            ) {
                setHovered(contentBlock?.id);
                childBlockElement?.classList.add("outline-2");
                childBlockElement?.classList.add("outline-offset-2");
                childBlockElement?.classList.add("outline-dashed");
                childBlockElement?.classList.add("outline-blue-500/50");
            }
        },
        [contentBlock?.id, activeContentBlock],
    );

    useEffect(() => {
        if (!childBlockElement) return;
        childBlockElement.addEventListener(
            "mouseenter",
            debounce(handleMouseEnter, 200),
        );
        childBlockElement.addEventListener(
            "mouseleave",
            debounce(handleMouseLeave, 1000),
        );
        return () => {
            childBlockElement.removeEventListener(
                "mouseenter",
                handleMouseEnter,
            );
            childBlockElement.removeEventListener(
                "mouseleave",
                handleMouseLeave,
            );
        };
    }, [childBlockElement, activeContentBlock]);

    const { updateActiveContentBlock } = useUpdateActiveContentBlock();
    const { gotContentBlock } = useGotContentBlock();
    const { addContentBlockState, } =
    useAddContentBlockState();
    if (!childBlockElement) return null;

    return createPortal(
        <div
            data-editing-content-block-id={contentBlock?.id}
            className={`child-block-style-editor absolute left-full top-0 z-[9001] flex h-6 items-center
                justify-center overflow-visible p-1 text-blue-500 opacity-0 transition-all
                duration-300 -translate-x-1/2 -translate-y-1/2 group-hover/add-margin:ml-4
                group-hover/add-margin:mt-4 data-[has-children=true]:left-1/2
                data-[has-children=true]:top-1/4 data-[has-metadata=true]:hidden
                data-[is-hovered=true]:opacity-100 data-[has-children=true]:-translate-x-1/2
                data-[has-children=true]:-translate-y-1/2
                data-[is-option=true]:-translate-x-[105%] data-[is-option=true]:translate-y-[5%]`}
            data-is-hovered={hovered === contentBlock?.id}
            data-has-children={contentBlock?.contentBlocks?.length > 1}
            data-is-option={contentBlock?.content_block_type === "OPTION"}
            data-has-metadata={
                !!addContentBlockState.addableContentBlockMetaData
            }
        >
            <ChildBlockStylePopover
                contentBlock={contentBlock}
                additionalUpdate={(updatedBlock) => {
                    gotContentBlock(updatedBlock);
                    updateActiveContentBlock(updatedBlock);
                }}
                element={childBlockElement}
            />
        </div>,
        childBlockElement,
    );
};
