import React, { useState } from "react";
import { NestedModelBlockDisplay } from "./NestedModelBlockDisplay";
import { useModelBlockNestedObject } from "../atoms";

export const NestedDataList = ({
    toggleModelBlockAccordionKey,
    getIsModelBlockExpanded,
    isInteractive,
}: {
    toggleModelBlockAccordionKey: (key: string) => void;
    getIsModelBlockExpanded: (modelBlockId: string) => boolean;
    isInteractive: boolean;
}) => {
    const modelBlocks = useModelBlockNestedObject();

    const [showAllValues, setShowAllValues] = useState<boolean>(true);

    return (
        <div>
            <div className="mb-4 mt-2 flex items-end justify-between">
                <div className="text-xl">Variables</div>
                {!isInteractive && (
                    <span
                        className="cursor-pointer text-xs hover:underline"
                        onClick={() => setShowAllValues(!showAllValues)}
                    >
                        {showAllValues
                            ? "Hide time horizons"
                            : "Show time horizons"}
                    </span>
                )}
            </div>
            <div>
                {Object.values(modelBlocks)
                    .filter((modelBlock) => !modelBlock.has_connections)
                    .map((modelBlock, i) => (
                        <NestedModelBlockDisplay
                            key={modelBlock.id}
                            modelBlock={modelBlock}
                            showAllValues={showAllValues}
                            setShowAllValues={setShowAllValues}
                            toggleModelBlockAccordionKey={
                                toggleModelBlockAccordionKey
                            }
                            getIsModelBlockExpanded={getIsModelBlockExpanded}
                        />
                    ))}
            </div>
            {Object.values(modelBlocks).filter(
                (modelBlock) => modelBlock.has_connections,
            ).length > 0 && (
                <>
                    <div className="mb-4 mt-10 text-xl">
                        Selection Data Variables
                    </div>
                    <div>
                        {Object.values(modelBlocks)
                            .filter((modelBlock) => modelBlock.has_connections)
                            .map((modelBlock, i) => (
                                <NestedModelBlockDisplay
                                    key={modelBlock.id}
                                    modelBlock={modelBlock}
                                    toggleModelBlockAccordionKey={
                                        toggleModelBlockAccordionKey
                                    }
                                    getIsModelBlockExpanded={
                                        getIsModelBlockExpanded
                                    }
                                />
                            ))}
                    </div>
                </>
            )}
        </div>
    );
};
