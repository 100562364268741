import React from "react";
import { ImageHandler } from "./ImageHandler";
import { QuestionStyle } from "./questions/QuestionStyle";
import { ChildBlockStyles } from "./child-block-styles/ChildBlockStyles";

export const EditorsByBlockType = () => {
    return (
        <div>
            <ImageHandler />
            <QuestionStyle />
            <ChildBlockStyles />
        </div>
    );
};
