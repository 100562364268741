import React from "react";
import { SortableModelVariableTree } from "./SortableModelVariableTree";
import { ModelBlock } from "@/models";
import {
    useModelBlockArray,
    useModelBlockNestedObject,
    useModelVariableMap,
} from "../atoms";

export const VariableOrganization = () => {
    const modelBlocks = useModelBlockNestedObject();
    const modelBlockArray = useModelBlockArray();
    const modelVariables = useModelVariableMap();

    return (
        <div>
            <SortableModelVariableTree
                modelBlocks={modelBlocks}
                modelBlockArray={modelBlockArray?.map((modelBlock) => ({
                    ...(modelBlock as Omit<ModelBlock, "modelVariables">),
                    modelVariables: modelBlock.modelVariables.map(
                        (modelVariable) => modelVariable.id,
                    ),
                }))}
                modelVariables={modelVariables}
            />
        </div>
    );
};
