import React, { useEffect, useState } from "react";
import {
    Participant,
    RoundShape,
    TeamShape,
    TeamWithProgressData,
} from "@/models";
import { CheckIcon, PencilIcon, PlusIcon } from "@heroicons/react/24/solid";
import { TeamParticipantForm } from "./TeamParticipantForm";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import { SapienInertia } from "@/inertia-utils/hooks";
import { AllowTeamOrCohortAdvance } from "./AllowAdvanceControls";

export const TeamDisplay = ({
    team,
    rounds,
    showAdvanceControls = false,
    isExpanded,
    setIsExpanded,
    roundMenu,
    children,
    columnLabels,
    // setParticipantToMove,
}: {
    team: TeamWithProgressData;
    rounds: RoundShape[];
    showAdvanceControls: boolean;
    isExpanded: boolean;
    setIsExpanded: (isExpanded: boolean) => void;
    roundMenu: React.ReactNode;
    children?: React.ReactNode;
    columnLabels: string[];
    setParticipantToMove: (participantToMove: Participant) => void;
}) => {
    const [editedTeam, setEditedTeam] = useState<TeamShape>();

    const [isFormOpen, setIsFormOpen] = useState(false);

    // const participantsCanMove = useMemo(() => {
    //     if (!team?.participants) return false;
    //     return team.team_user_rounds?.every(
    //         (teamUserRound) => teamUserRound.teamSelections?.length === 0,
    //     );
    // }, [team]);

    useEffect(() => {
        if (!!editedTeam) {
            document.getElementById(`team-${editedTeam.id}`).focus();
        }
    }, [editedTeam?.id]);

    return (
        <div
            key={team.id}
            className={`flex flex-col rounded-md border transition-all dark:bg-slate-800/25 ${
                isExpanded
                    ? "border-indigo-800/20 shadow-indigo-800/5 hover:shadow-sm dark:border-slate-800"
                    : "border-slate-200 dark:border-slate-800"
            }`}
        >
            <div className="">
                <div
                    className="group"
                    data-is-shown={isExpanded}
                    onClick={() => {
                        setIsExpanded(!isExpanded);
                    }}
                >
                    <div
                        className={`flex items-center justify-between rounded-md p-3 font-semibold
                        group-data-[is-shown='false']:rounded-md
                        group-data-[is-shown='true']:rounded-b-none
                        group-data-[is-shown='true']:rounded-t-md
                        group-data-[is-shown='false']:border-b-0 group-data-[is-shown='true']:border-b
                        hover:cursor-pointer dark:bg-slate-800/25 dark:hover:bg-slate-800/50 ${
                            isExpanded
                                ? `border-slate-200 border-b-indigo-800/20 border-b-slate-200 bg-white/75
                                    hover:bg-white/100 dark:border-slate-800`
                                : `border-transparent bg-white/75 hover:bg-white/100 hover:shadow-sm
                                    dark:border-transparent`
                        }`}
                    >
                        <div className="flex items-center space-x-4">
                            <ChevronRightIcon
                                data-testid={`view_team_${team.name}`}
                                className="h-4 w-4 transition-all group-data-[is-shown='true']:rotate-90"
                            />
                            {editedTeam?.id === team.id ? (
                                <form>
                                    <div>
                                        <input
                                            type="text"
                                            id={`team-${team.id}`}
                                            className="block w-full rounded-md border-0 px-3 py-1.5 text-base font-normal leading-6
                                                ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2
                                                focus:ring-inset focus:ring-indigo-500"
                                            placeholder="Name"
                                            value={editedTeam.name}
                                            onClick={(e) => e.stopPropagation()}
                                            onChange={(e) => {
                                                e.stopPropagation();
                                                setEditedTeam({
                                                    ...editedTeam,
                                                    name: e.target.value,
                                                });
                                            }}
                                        />
                                    </div>
                                </form>
                            ) : (
                                <span className="font-medium">{team.name}</span>
                            )}
                            <button
                                type="button"
                                className="rounded-full p-2 transition-all hover:bg-slate-100 hover:text-indigo-600"
                                disabled={false}
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    if (editedTeam?.id === team.id) {
                                        SapienInertia.post(
                                            "facilitation.save-team",
                                            {
                                                ...editedTeam,
                                            },
                                            {},
                                            {
                                                preserveScroll: true,
                                            },
                                        );
                                        setEditedTeam(undefined);
                                    } else {
                                        setEditedTeam({
                                            name: team.name,
                                            cohort_id: team.cohort_id,
                                            team_number: team.team_number,
                                            id: team.id,
                                            invitations: team.invitations || [],
                                        });
                                    }
                                }}
                            >
                                {editedTeam?.id === team.id ? (
                                    <CheckIcon className="h-4 w-4" />
                                ) : (
                                    <PencilIcon className="h-4 w-4" />
                                )}
                            </button>
                        </div>
                        {!!team.participants && !!team.participants.length && (
                            <div className="flex min-w-[25%] items-center">
                                {roundMenu}
                                {!!showAdvanceControls && (
                                    <div className="flex items-center">
                                        <AllowTeamOrCohortAdvance
                                            // status={teamStatus}
                                            targetType="Team"
                                            team_id={team.id}
                                            team={team}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                {isExpanded && (
                    <div
                        className="space-y-4 rounded-b-md bg-slate-50 py-3 pl-12 pr-3 text-slate-600 transition-all
                            dark:text-slate-400"
                    >
                        <div
                            className="relative h-full w-full overflow-auto transition-all scrollbar-thin
                                scrollbar-track-slate-200/75 scrollbar-thumb-slate-300/75
                                scrollbar-track-rounded-full scrollbar-thumb-rounded-full
                                scrollbar-corner-rounded-full 
                                dark:scrollbar-track-slate-800 dark:scrollbar-thumb-slate-700/75"
                            data-is-open={isFormOpen}
                        >
                            {!!team.participants &&
                            !!team.participants.length ? (
                                <div className="min-w-[1024px] space-y-1 divide-y divide-slate-200/75">
                                    <div className="grid w-full grid-cols-4 gap-3 text-sm text-slate-500 md:grid-cols-4">
                                        {columnLabels?.map((label, index) => (
                                            <div
                                                key={`${label}-${index}`}
                                                className="flex flex-nowrap items-center space-x-2 font-light tracking-wide text-slate-700"
                                            >
                                                {label == "Participant" ? (
                                                    <>
                                                        <span>{label}</span>
                                                        <button
                                                            data-testid={`add_participant`}
                                                            type="button"
                                                            className="rounded-full p-1 transition-all hover:bg-slate-100 hover:text-indigo-600"
                                                            disabled={
                                                                isFormOpen
                                                            }
                                                            onClick={() => {
                                                                setIsExpanded(
                                                                    true,
                                                                );
                                                                setIsFormOpen(
                                                                    true,
                                                                );
                                                            }}
                                                        >
                                                            <PlusIcon className="h-4 w-4" />
                                                        </button>
                                                    </>
                                                ) : (
                                                    label
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                    <div
                                        className="data-[is-open='true']:transform-y-0 absolute top-0 h-20 w-full rounded-md
                                            bg-slate-200 p-4 text-slate-800 opacity-0 transition-all
                                            data-[is-open='false']:pointer-events-none data-[is-open='true']:relative
                                            data-[is-open='true']:opacity-100 data-[is-open='false']:-translate-y-full"
                                        data-is-open={isFormOpen}
                                    >
                                        <TeamParticipantForm
                                            team={team}
                                            exit={() => setIsFormOpen(false)}
                                            rounds={rounds}
                                        />
                                    </div>
                                    <ul
                                        role="list"
                                        className="space-y-1 p-0 pt-1"
                                    >
                                        {children}
                                    </ul>
                                </div>
                            ) : (
                                <>
                                    <div className="flex items-center space-x-2">
                                        <div className="text-sm font-semibold text-slate-500">
                                            Add Participants
                                        </div>
                                        <button
                                            data-testid={`add_participant`}
                                            type="button"
                                            className="rounded-full p-2 transition-all hover:bg-slate-100 hover:text-indigo-600"
                                            disabled={isFormOpen}
                                            onClick={() => {
                                                setIsExpanded(true);
                                                setIsFormOpen(true);
                                            }}
                                        >
                                            <PlusIcon className="h-4 w-4" />
                                        </button>
                                    </div>
                                    <div
                                        className="data-[is-open='true']:transform-y-0 absolute top-0 h-20 w-full rounded-md
                                            bg-slate-200 p-4 text-slate-800 opacity-0 transition-all
                                            data-[is-open='false']:pointer-events-none data-[is-open='true']:relative
                                            data-[is-open='true']:opacity-100 data-[is-open='false']:-translate-y-full"
                                        data-is-open={isFormOpen}
                                    >
                                        <TeamParticipantForm
                                            team={team}
                                            exit={() => setIsFormOpen(false)}
                                            rounds={rounds}
                                        />
                                    </div>
                                </>
                            )}
                            {team.invitations.length > 0 && (
                                <div className="mt-4 flex flex-col gap-2 border-t border-slate-200 pt-4">
                                    <h3 className="font-bold">
                                        Pending Invitations
                                    </h3>
                                    <div className="grid gap-2 p-2 grid-cols-2">
                                        <span>Name</span>
                                        <span>Email</span>
                                    </div>
                                    <div className="grid gap-2 p-2 odd:bg-slate-100 grid-cols-2">
                                        {team.invitations.map((invitation) => (
                                            <React.Fragment key={invitation.id}>
                                                <span>{invitation.name}</span>
                                                <span>{invitation.email}</span>
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
