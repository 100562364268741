import React from "react";
import { useContentBlockState } from "../useContentBlockState";
import ContentBlockEditor from "./ContentBlockEditor";
import { useSelectedSimulation } from "@/hooks";

export default function ContentBlockWidgets() {
    const { editableContentBlocks } = useContentBlockState();
    const { selectedSimulation } = useSelectedSimulation();

    return (
        <>
            {editableContentBlocks.map((block, index) => (
                <ContentBlockEditor
                    key={block.id}
                    parentBlock={block}
                    numberOfBlocks={editableContentBlocks.length}
                    index={index}
                    isSimulation={!selectedSimulation.is_course}
                />
            ))}
        </>
    );
}
