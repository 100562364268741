import { ContentBlockShape } from "@/models";
import React from "react";
import { z } from "zod";
import RichTextBlock from "../../rich-text/RichTextBlock";
import NestedContentBlockDisplay from "../../NestedContentBlockDisplay";
import { cn } from "@/util";
import { themeToStyleVariables } from "@/tailwind-colors/themePropertyToStyleVariable";
import { useComponentDisplayProps } from "./useComponentDisplayProps";
import { contentBlockAtom } from "@/Pages/DesignStudio/Design/useContentBlockState";

export const asValidator = z.enum([
    "button",
    "a",
    "section",
    "div",
    "main",
    "p",
    "img",
    // "video",
    "nav",
    "div",
]);

const nonChildTypes = ["img"];

// const className = `mb-1 mt-1 text-[var(--text-color)] border-[var(--border-color)] bg-[var(--bg-color)] hover:border-[var(--border-color-hover)] active:border-[var(--border-color-active)] disabled:border-[var(--border-color-disabled)] hover:bg-[var(--bg-hover-color)] active:bg-[var(--bg-active-color)] disabled:bg-[var(--bg-disabled-color)] py-[48px] p-`;
export const PolymorphicBlock = ({
    block,
    childBlocks,
    isInAdminContext,
}: {
    block: ContentBlockShape;
    childBlocks: ContentBlockShape[];
    isInAdminContext: boolean;
}) => {
    const Component = asValidator.parse(block.theme.as);

    const styleVariables = themeToStyleVariables(block.theme);

    const props = useComponentDisplayProps(block);
    if (nonChildTypes.includes(Component)) {
        return (
            <span
                data-content-block-type={block.content_block_type}
                data-content-block-id={block.id}
                className={`group transition-all duration-300 ${cn(block.theme.tailwindClasses)} `}
            >
                <Component
                    className="h-full w-full"
                    style={styleVariables}
                    {...props}
                />
            </span>
        );
    }
    return (
        <>
            <Component
                data-content-block-type={block.content_block_type}
                data-content-block-id={block.id}
                className={`group/${block.content_block_type} group transition-all duration-300
                ${cn(block.theme.tailwindClasses)}`}
                style={styleVariables}
                data-is-polymorphic-block={true}
                {...props}
            >
                {!!block.rich_text && (
                    <RichTextBlock
                        contentBlock={block}
                        editable={true}
                        content={block.rich_text}
                    />
                )}
                {childBlocks.length > 0 &&
                    childBlocks.map((child) => (
                        <NestedContentBlockDisplay
                            key={child.id}
                            contentBlock={child}
                            isInAdminContext={isInAdminContext}
                        />
                    ))}
            </Component>
        </>
    );
};
