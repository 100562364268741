import { ContentBlockShape } from "@/models";
import { SettingsIcon } from "lucide-react";
import React from "react";

type Props = {
    contentBlock: ContentBlockShape;
};

const SimulationTools = ({ contentBlock }: Props) => {
    return (
        <button
            className="rounded-md p-2 hover:bg-slate-200"
            onClick={() => {
                console.log(contentBlock);
            }}
        >
            <SettingsIcon className="h-4 w-4" />
        </button>
    );
};

export default SimulationTools;
