import { useGetAllElementsAsync } from "@/hooks/useGetElementAsync";
import { PencilIcon } from "lucide-react";
import React, { useCallback, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import ImageModal from "./modals/ImageModal";
import {
    useContentBlockState,
    useGetContentBlockWithChildren,
} from "../useContentBlockState";
import { useSaveContentBlock } from "../content-block-management/useSaveContentBlock";

export const ImageHandler = () => {
    const editableImages = useGetAllElementsAsync(
        '[data-image-content-block-id]',
    );

    if (!editableImages?.length) {
        return null;
    }

    return (
        <>
            {Array.from(editableImages).map((image) =>
                createPortal(<ImageEditor image={image} />, document.body),
            )}
        </>
    );
};

function getImageUrl(element: HTMLElement): string | null {
    if (element.tagName === "IMG" && element.getAttribute("src")) {
        return element.getAttribute("src");
    }

    for (let i = 0; i < element.children.length; i++) {
        const child = element.children[i] as HTMLElement;
        const src = getImageUrl(child);
        if (src) {
            return src;
        }
    }

    return null;
}

function ImageEditor({ image }: { image: HTMLElement }) {
    const contentBlock = useGetContentBlockWithChildren(
        image.getAttribute("data-image-content-block-id"),
    );

    const { setContentBlock } = useContentBlockState();

    const { mutateAsync, isPending } = useSaveContentBlock();

    const handleSave = useCallback(
        async (image_url: string) => {
            const { savedContentBlock } = await mutateAsync({
                ...contentBlock,
                image_url,
            });
            setContentBlock(savedContentBlock);
        },
        [contentBlock],
    );

    return (
        <ImageModal
            imageUrl={getImageUrl(image)}
            save={handleSave}
            saving={isPending}
            element={image}
        />
    );
}
