import { ContentBlockShape } from "@/models";
import { cn } from "@/util";
import React from "react";

type Props = {
    contentBlock: ContentBlockShape;
    children: React.ReactNode | React.ReactNode[];
    isInAdminContext: boolean;
};

export default function PageBlock({
    contentBlock,
    children,
    isInAdminContext,
}: Props) {
    const tailwindClasses = contentBlock.theme?.tailwindClasses || [];
    const gradientAngle = contentBlock.theme?.tailwindClasses?.find(
        (className) => className.includes("bg-gradient-to-"),
    );
    return (
        <main>
            {children}
        </main>
    );
}
