import React from "react";
import { ContentBlockShape } from "@/models";
import { cn } from "@/util";
import LessonRichText from "../../rich-text/RichTextBlock";
import { RefreshCcw } from "lucide-react";

type Props = {
    contentBlock: ContentBlockShape;
    isInAdminContext: boolean;
    flip: () => void;
};

export default function FlipCardSide({
    contentBlock,
    isInAdminContext,
    flip,
}: Props) {
    const variant: "text" | "image-full" | "image-top" =
        contentBlock.theme?.variant || "text";

    const styles =
        variant === "image-full"
            ? {
                  backgroundImage: `url(${contentBlock.image_url})`,
              }
            : {};

    return (
        <div
            className={`absolute flex h-full w-full flex-col items-center justify-center backface-hidden
            ${cn(contentBlock.theme.tailwindClasses)} group overflow-hidden rounded-lg
            bg-cover bg-center bg-no-repeat has-[.tippy-box]:overflow-visible`}
            style={styles}
            role="button"
            onClick={() => !isInAdminContext && flip()}
            data-is-in-admin-context={isInAdminContext}
        >
            <div
                className="relative flex h-full w-full items-center justify-center bg-cover bg-center
                    bg-no-repeat px-4 py-2 has-[.tippy-box]:overflow-visible"
            >
                <div
                    data-variant={variant}
                    className="absolute flex h-full w-full overflow-hidden rounded-md bg-cover bg-center
                        bg-no-repeat data-[variant=text]:hidden"
                    style={{
                        backgroundImage: `url(${contentBlock.theme?.backgroundImage})`,
                    }}
                ></div>
                <div className=" px-4 py-2">
                    <LessonRichText
                        contentBlock={contentBlock}
                        editable={isInAdminContext}
                        content={contentBlock.rich_text}
                    />
                </div>
            </div>
            <div
                role="button"
                className="absolute bottom-2 right-2 rounded-full bg-white p-1 text-sapien-blue shadow-sm
                    transition-all duration-500 group-hover:scale-125"
                onClick={() => isInAdminContext && flip()}
            >
                <RefreshCcw className="h-5 w-5" />
            </div>
        </div>
    );
}
