import { Tabs, TabsList, TabsTrigger, TabsContent } from "@/components/Tabs";
import {
    Popover,
    PopoverAnchor,
    PopoverContent,
    PopoverTrigger,
} from "@/components/popover/Popover";
import { ContentBlockShape } from "@/models";
import { BrushIcon } from "lucide-react";
import React, { useCallback, useEffect, useState } from "react";
import { DefaultSpacingMenu } from "../style-dropdowns/DefaultStyleMenu";
import ColorForm from "../style-dropdowns/ColorForm";
import ButtonGroup from "../ButtonGroup";
import { debounce } from "lodash";
import { useSaveContentBlock } from "../../content-block-management/useSaveContentBlock";
import { useContentBlockState } from "../../useContentBlockState";
import { OptionCardStyles } from "./OptionCardStyles";
import { createPortal } from "react-dom";

type Props = {
    contentBlock: ContentBlockShape;
    additionalUpdate?: (updatedBlock: ContentBlockShape) => void;
};

export function ChildBlockStylePopover(
    props: Props & { element: HTMLElement },
) {
    const renderInPortal = props.element.getBoundingClientRect().width < 900;

    // if (renderInPortal) {
    //     return (
    //         <ChildBlockStylePortal {...props}>
    //             <ChildBlockStylePopoverContents {...props} />
    //         </ChildBlockStylePortal>
    //     );
    // }
    return (
        <Popover>
            <PopoverTrigger className="flex items-center gap-2 rounded-full bg-white p-2 shadow hover:bg-slate-200">
                <BrushIcon className="h-3 w-3" />
            </PopoverTrigger>

            <PopoverContent
                data-render-in-top-right={true}
                className="e-learning !translae-x-0 left-5 top-5 z-[999999999] ml-4 mt-2 rounded-md
                    bg-white p-2 py-2 !translate-y-0"
            >
                <ChildBlockStylePopoverContents {...props} />
            </PopoverContent>
        </Popover>
    );
}

function ChildBlockStylePortal({
    children,
    element,
}: {
    children: React.ReactNode;
    element: HTMLElement;
}) {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const handleClick = (e: MouseEvent) => {
            if (!element.contains(e.target as Node)) {
                setIsOpen(() => false);
            }
        };
        document.addEventListener("click", handleClick);
        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, [element]);
    if (!element) return null;
    return (
        <>
            <button
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setIsOpen(true);
                }}
                className="flex items-center gap-2 rounded-full bg-white p-2 shadow hover:bg-slate-200"
            >
                <BrushIcon className="h-3 w-3" />
            </button>
            {createPortal(
                <>
                    <div
                        className="child-popover-fixed fixed left-2 top-2 z-[999999999999] w-[432px] rounded-lg
                            bg-white p-4 opacity-0 shadow-lg transition-all duration-300
                            data-[is-open=false]:pointer-events-none data-[is-open=true]:pointer-events-auto
                            data-[is-open=true]:opacity-100"
                        data-is-open={isOpen}
                    >
                        {children}
                    </div>
                </>,
                element,
            )}
        </>
    );
}

function ChildBlockStylePopoverContents({
    contentBlock,
    additionalUpdate,
}: Props) {
    const { mutateAsync } = useSaveContentBlock();
    const { setContentBlock } = useContentBlockState();

    const save = useCallback(debounce(mutateAsync, 2000), [contentBlock]);
    const update = useCallback(
        debounce((updatedBlock: ContentBlockShape) => {
            setContentBlock(updatedBlock);
            updatedBlock?.contentBlocks?.forEach((child) => {
                setContentBlock(child);
            });
            save(updatedBlock);
            if (additionalUpdate) additionalUpdate(updatedBlock);
        }, 100),
        [contentBlock],
    );
    return (
        <>
            <Tabs
                defaultValue="spacing"
                className="flex w-[400px] flex-col space-y-2"
            >
                <TabsList className="grid w-full grid-cols-2 bg-gray-100">
                    <TabsTrigger
                        value="spacing"
                        className="rounded-md data-[state='active']:bg-white"
                    >
                        Spacing
                    </TabsTrigger>
                    <TabsTrigger
                        value="colors"
                        className="rounded-md data-[state='active']:bg-white"
                    >
                        Colors
                    </TabsTrigger>
                </TabsList>
                <TabsContent
                    value="spacing"
                    className="flex flex-col gap-6 p-2"
                >
                    <DefaultSpacingMenu contentBlock={contentBlock} />
                    {contentBlock.content_block_type === "OPTION" && (
                        <OptionCardStyles
                            contentBlock={contentBlock}
                            update={update}
                        />
                    )}
                    {/* {!!contentBlock.contentBlocks?.length && (
            <Alignment
                contentBlock={contentBlock}
                update={update}
            />
        )} */}
                    <div className="flex flex-col gap-2 pb-2 shadow-sm">
                        <label htmlFor="border" className="text-xs">
                            Border Width
                        </label>
                        <ButtonGroup
                            selectedValue={(
                                contentBlock.theme?.tailwindClasses as string[]
                            )
                                ?.filter(
                                    (className) =>
                                        !/\bborder-\d+\b/.test(className),
                                )
                                .join(" ")}
                            values={[
                                { label: "None", value: "border-0" },
                                { label: "S", value: "border" },
                                { label: "M", value: "border-2" },
                                { label: "L", value: "border-3" },
                                { label: "XL", value: "border-6" },
                                { label: "2XL", value: "border-8" },
                            ]}
                            handleChange={(value) => {
                                update({
                                    ...contentBlock,
                                    theme: {
                                        ...contentBlock.theme,
                                        tailwindClasses: [
                                            ...(
                                                contentBlock.theme
                                                    ?.tailwindClasses || []
                                            ).filter(
                                                (className) =>
                                                    !className.includes(
                                                        "border",
                                                    ),
                                            ),
                                            value,
                                        ],
                                    },
                                });
                            }}
                        />
                    </div>
                    <div className="flex flex-col gap-2 pb-2 shadow-sm">
                        <label htmlFor="radius" className="text-xs">
                            Border Radius
                        </label>
                        <ButtonGroup
                            selectedValue={(
                                contentBlock.theme?.tailwindClasses as string[]
                            )
                                ?.filter((className) =>
                                    className.includes("rounded"),
                                )
                                .join(" ")}
                            values={[
                                { label: "None", value: "rounded-0" },
                                { label: "S", value: "rounded-sm" },
                                { label: "M", value: "rounded-md" },
                                { label: "L", value: "rounded-lg" },
                                { label: "XL", value: "rounded-xl" },
                                { label: "2XL", value: "rounded-2xl" },
                                { label: "3XL", value: "rounded-3xl" },
                            ]}
                            handleChange={(value) => {
                                update({
                                    ...contentBlock,
                                    theme: {
                                        ...contentBlock.theme,
                                        tailwindClasses: [
                                            ...(
                                                contentBlock.theme
                                                    ?.tailwindClasses || []
                                            ).filter(
                                                (className) =>
                                                    !className.includes(
                                                        "rounded",
                                                    ),
                                            ),
                                            value,
                                        ],
                                    },
                                });
                            }}
                        />
                    </div>
                </TabsContent>
                <TabsContent value="colors" className="flex flex-col gap-6 p-2">
                    <ColorForm contentBlock={contentBlock} />
                    <div className="flex flex-col gap-2 pb-2 shadow-sm">
                        <label htmlFor="shadow" className="text-xs">
                            Shadow
                        </label>
                        <ButtonGroup
                            selectedValue={contentBlock.theme?.tailwindClasses?.find(
                                (className) => className.includes("shadow"),
                            )}
                            values={[
                                { label: "None", value: "shadow-none" },
                                { label: "S", value: "shadow-sm" },
                                { label: "M", value: "shadow-md" },
                                { label: "L", value: "shadow-lg" },
                                { label: "XL", value: "shadow-xl" },
                            ]}
                            handleChange={(value) => {
                                update({
                                    ...contentBlock,
                                    theme: {
                                        ...contentBlock.theme,
                                        tailwindClasses: [
                                            ...(
                                                contentBlock.theme
                                                    ?.tailwindClasses || []
                                            ).filter(
                                                (className) =>
                                                    !className.includes(
                                                        "shadow",
                                                    ),
                                            ),
                                            value,
                                        ],
                                    },
                                });
                            }}
                        />
                    </div>
                </TabsContent>
            </Tabs>
        </>
    );
}
