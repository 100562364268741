import { ContentBlockShape } from "@/models";
import { QuestionTheme } from "./QuestionStyle";

function defaultTheme(contentBlock: ContentBlockShape): ContentBlockShape {
    return {
        ...contentBlock,
        theme: {
            ...contentBlock.theme,
            variant: "default",
        },
        contentBlocks: contentBlock.contentBlocks.map((block) => ({
            ...block,
            theme: {
                ...block.theme,
                variant: "default",
            },
        })),
    };
}
function cards(contentBlock: ContentBlockShape): ContentBlockShape {
    return {
        ...contentBlock,
        theme: {
            ...contentBlock.theme,
            variant: "cards",
            tailwindClasses: [
                "flex",
                "flex-row",
                "flex-wrap",
                "lg:gap-4",
                "gap-2",
                "w-full",
            ],
        },
        contentBlocks: contentBlock.contentBlocks.map((block) => ({
            ...block,
            image_url:
                block.image_url ||
                "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/images/phpY7RD5C.png",
            theme: {
                ...block.theme,
                variant: "cards",
                tailwindClasses: [
                    ...((block.theme?.tailwindClasses || []).filter(
                        (className) =>
                            className.includes("bg-") ||
                            className.includes("from-") ||
                            className.includes("to-") ||
                            className.includes("via-"),
                    ) || []),
                    "flex",
                    "flex-col",
                    "items-center",
                    "gap-2",
                    "rounded-2xl",
                    "p-0",
                    "shadow-[2px_4px_12px_#00000014]",
                    "transition-all",
                    "data-[selected=true]:border-sapien-blue",
                    "data-[selected=true]:shadow-[2px_4px_12px_#00000029]",
                    "hover:border-sapien-blue",
                    "hover:shadow-[2px_4px_12px_#00000029]",
                    "cursor-pointer",
                    "border-2",
                    "border-transparent",
                    "self-stretch",
                    "grow-0",
                    "shrink-1",
                    "basis-full",
                    "@2xl:basis-[calc(50%-1rem)]",
                    "@5xl:basis-[calc(33%-1rem)]",
                    "@7xl:basis-[calc(25%-1rem)]",
                    "w-full",
                ],
            },
        })),
    };
}

export function updateQuestionTheme(
    contentBlock: ContentBlockShape,
    theme: QuestionTheme,
): ContentBlockShape {
    switch (theme) {
        case "cards":
            return cards(contentBlock);
        case "default":
            return defaultTheme(contentBlock);
        default:
            return contentBlock;
    }
}
